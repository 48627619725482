import { useEffect } from 'react';

export const ErrorFocus = (props: { errors: Record<string, boolean> }) => {

  const { errors } = props;

  useEffect(() => {

    // Get all keys of the error messages from the "name" field in TextInput
    // Order of possible error fields matters. 
    const erroredField = Object.keys(errors).filter((key) => errors[key] === true);

    // Whenever there are errors and the form is submitting but finished validating.
    if (erroredField.length > 0) {
      // We grab the first input element that errors by its name.
      // If it doesn't scroll to the "right" field, check the ordering of properties
      // in the `errors` prop 
      const errorElement = document.querySelector(
        `input[name="${erroredField[0]}"]`,
      );

      if (errorElement) {
        // When there is an input, scroll this input into view.
        errorElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [errors]);

  // This component does not render anything by itself.
  return null;
};
