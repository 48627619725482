/*eslint-disable react-hooks/exhaustive-deps*/
import { DialogContent, DialogActions, Button } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { BaseDialog, TextInput, useDialogContext } from '../../Components';
import { useNewPasswordMutation } from '../../Redux/api';
import { trimRequest } from '../../Utils/trimRequest';
import { checkPasswordNontrivial, checkPasswordValid } from '../../Utils/passwordChecks';


interface UpdatePwdProps {
  open: boolean,
  curPwd: string,
  setErrorCode: Dispatch<SetStateAction<number>>,
  email: string,
  handleLogin: (e:  React.MouseEvent<HTMLButtonElement, MouseEvent> | any) => void, //eslint-disable-line
  onPasswordInput: (e: React.ChangeEvent<HTMLInputElement>) => void,
}
const UpdatePwdFormDialog = (props: UpdatePwdProps) => {
  const { open, curPwd, setErrorCode, email, handleLogin, onPasswordInput } = props;
  const { closeDialog } = useDialogContext();
  const [newPwd] = useNewPasswordMutation();
  const [currentPwd, setCurrentPwd] = useState(curPwd);
  const [newPwdErrMsg, setNewPwdErrMsg] = useState('');
  const [confirmErrMsg, setConfirmErrMsg] = useState('');
  const [error, setError] = useState<{
    currPass: boolean,
    newPass: boolean,
    confirmPass: boolean
  }>({
    currPass: false, 
    newPass: false, 
    confirmPass: false,
  });

  useEffect(() => {
    if (curPwd){
      setCurrentPwd(curPwd);
    }
  }, []);

  const handleInputErrors = (input: Record<string, unknown>) => {
    let currPassInput = false;
    let newPassInput = false;
    let newPassValid = false;

    if (!input.currentPassword || input.currentPassword !== currentPwd){
      currPassInput = true;
    }

    if (!input.newPassword){
      newPassInput = true;
      setNewPwdErrMsg('New password input required');
    } else if (input.newPassword === currentPwd){
      newPassValid = true;
      setNewPwdErrMsg('Password can not be current password');
    } else {
      if (input.newPassword && !checkPasswordValid(input.newPassword as string)){
        newPassValid = true;
        setNewPwdErrMsg('Password must be at least 8 characters, with at least one letter and one number and no more than three repeating characters. Letters, numbers, and the characters !@#$%^&* allowed.');
      }
    
      if (input.newPassword && !checkPasswordNontrivial(input.newPassword as string)){
        newPassValid = true;
        setNewPwdErrMsg('This password is not strong enough. Please choose another.');
      }
    }

    let confirmPassInput = false;
    let confirmPassValid = false;
    if (!input.confirmNewPassword){
      confirmPassInput = true;
      setConfirmErrMsg('Confirm new password required.');
    } else if (input.confirmNewPassword !== input.newPassword){
      confirmPassValid = true;
      setConfirmErrMsg('Input does not match new password.');
    }
    
    // setError({currPass: currPassInput, newPass: newPassInput, confirmPass: confirmPassInput})

    if (currPassInput || newPassInput || newPassValid || confirmPassInput || confirmPassValid){
      setError({ currPass: currPassInput, newPass: newPassInput || newPassValid, confirmPass: confirmPassInput || confirmPassValid });
      return false;
    } else {
      return true;
    }

  };
  
  return (
    <BaseDialog
      open={open}
      title={'Update Password'}
    >
      <form 
        noValidate 
        onSubmit={async (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const fieldValues = Object.fromEntries(formData.entries());
          console.log(fieldValues);
          const trimmedValues = trimRequest(fieldValues);
          const body = trimmedValues;

          try {
            const newPassWordInput =  handleInputErrors(body);
            if (newPassWordInput){
              await newPwd({ email: email, ...body }).unwrap();
              setErrorCode(0);
              closeDialog();
              handleLogin(event);
            }
          } catch (err: unknown){
            console.log(err);
            
          }
          
          // Get data from form, then add values from controlled multi-select field (roles)
          
        }}
      >
        <DialogContent>
          <TextInput 
            id='oldPwd' 
            label='Current Password'
            required 
            type='password'
            error={error.currPass}
            helperText='Input does not match current password'
            name='currentPassword' defaultValue={undefined}/>
          <TextInput 
            id='newPwd' 
            label='New Password' 
            name='newPassword' 
            type='password'
            required
            error={error.newPass}
            helperText={newPwdErrMsg}
            defaultValue={undefined}
            onChange={onPasswordInput}/>
          <TextInput 
            id='confirmNewPwd' 
            required
            type='password'
            error={error.confirmPass}
            helperText={confirmErrMsg}
            label='Confirm New Password' 
            name='confirmNewPassword' 
            defaultValue={undefined}
            />
        
        </DialogContent>
        <DialogActions>
          <Button type="submit">
            Submit
          </Button>
        </DialogActions>

      </form>
    </BaseDialog>
  );
};

export default UpdatePwdFormDialog;
