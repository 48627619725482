import { useEffect, useMemo } from 'react';
import { Routes, Route, Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { Toolbar, CssBaseline, Container } from '@mui/material';
import { 
  Dashboard, Login, Campuses, CampusStatuses, CampusAlerts, 
  Users, Settings, Notifications, Roles, Tags, Events, CapMessages,
} from './Containers';
import { Copyright, IdlePromptDialog, StatusChangeDialog, NavigationSide, NavigationTop, 
  Toast, useDialogContext, useToastContext } from './Components';
import { useAppDispatch, useAppSelector } from './Redux/hooks';
import { activeCapActions } from './Redux/Slices/ActiveCapSlice';
import { socketActions  } from './Redux/Slices/SocketSlice';
import { statusUpdateActions } from './Redux/Slices/StatusUpdateSlice';
import { authActions } from './Redux/Slices/AuthSlice';
import { useGetSettingQuery } from './Redux/api';
import { AUTO_LOGOUT } from './Utils/constants';
import DeviceMaintenance from './Containers/DeviceMaintenance/DeviceMaintenance';

const licenseType = process.env.REACT_APP_DISTRICT_LICENSE_TYPE ? process.env.REACT_APP_DISTRICT_LICENSE_TYPE : 'relay';

const Layout = () => {

  const { data: autoLogout } = useGetSettingQuery(AUTO_LOGOUT);
  const { token, id, roles } = useAppSelector((state) => state.auth); 
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { dialogType, openDialog, closeDialog } = useDialogContext();
  const { toast, clearToast } = useToastContext();

  const startOnMount = useMemo(() => {
    if (token && id && autoLogout && autoLogout.value !== '' && !isNaN(parseInt(autoLogout.value, 10))) {
      return true;
    }
    return false;
  }, [token, id, autoLogout]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const idleTimer = useIdleTimer({ 
    onIdle: () => {
      dispatch(authActions.logout());
      closeDialog();
      navigate('/');
    }, 
    onPrompt: () => {
      openDialog('Idle');
    },
    timeout: startOnMount && autoLogout ? parseInt(autoLogout.value, 10) * 60 * 1000 : undefined, 
    promptBeforeIdle: startOnMount ? 30 * 1000 : undefined,
    stopOnIdle: true,
    // only start timer if autoLogout.value is truthy and can be cast to number > 0
    startOnMount,
  });

  return (<>
    <NavigationTop />
    <NavigationSide />
    <Container component="main" maxWidth={false}>
      <Toolbar />
      <Outlet />
    </Container>
    <Copyright />
    <Toast
      state={toast}
      close={clearToast}
    />
    {dialogType === 'Status' && roles.includes('notify') ? 
      <StatusChangeDialog 
        open={dialogType === 'Status'}
      /> : null
    }
    {dialogType === 'Idle' ?
      <IdlePromptDialog
        open={dialogType === 'Idle'}
        reset={idleTimer.reset}
      /> : null}
  </>);
};

const RequireAuth = () => {
  
  const { token, id } = useAppSelector((state) => state.auth); 
  
  if (token === null || id === null) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

const App = ():JSX.Element => {
 
  const {
    error: socketError, alert: socketAlert, uploadStatus: csvUpload,
  } = useAppSelector((state) => state.socket);
  const { successMessage: capSendSuccessMessage } = useAppSelector((state) => state.activeCap);
  const { 
    pending: pendingUpdates, successful: successfulUpdates, statusNotifs: statusNotifUpdates,
  } = useAppSelector((state) => state.statusUpdate);
  const dispatch = useAppDispatch();
  const { setAlertToast, setErrorToast, setInfoToast, setSuccessToast } = useToastContext();
  const { openDialog } = useDialogContext();

  useEffect(() => {
    let timeoutID: number;
    if (pendingUpdates.length > 0 || successfulUpdates.length > 0) {
      timeoutID = setTimeout(() => {
        // updates sent from ardal
        if (pendingUpdates.length > 0) {
          setInfoToast(
            `${successfulUpdates.length} successful status updates sent. 
            ${pendingUpdates.length - successfulUpdates.length} updates failed`,
          );
        } else {
          // mqtt direct updates (possibly from 6025)
          setInfoToast(`${successfulUpdates.length} campus status update(s) received`);
        }
        dispatch(statusUpdateActions.clearAll());
      }, 1500) as unknown as number;
    }
    return () => {
      // cancel timeout if dependencies change
      if (timeoutID) clearTimeout(timeoutID);
    };
  }, [successfulUpdates, pendingUpdates, setInfoToast, dispatch]);

  useEffect(() => {
    if (statusNotifUpdates.length > 0) {
      openDialog('Status');
    }
  }, [openDialog, statusNotifUpdates]);

  useEffect(() => {
    if (capSendSuccessMessage.length) {
      setInfoToast(capSendSuccessMessage);
      dispatch(activeCapActions.setSuccessMessage(''));
    }
  }, [capSendSuccessMessage, setInfoToast, dispatch]);

  useEffect(() => {
    if (socketError.length) {
      setErrorToast(socketError);
      dispatch(socketActions.setSocketError(''));
    }
  }, [socketError, setErrorToast, dispatch]);

  useEffect(() => {
    if (socketAlert.length) {
      setAlertToast(socketAlert);
      dispatch(socketActions.setSocketAlert(''));
    }
  }, [socketAlert, setAlertToast, dispatch]);

  useEffect(() => {
    if (csvUpload.length) {
      setSuccessToast(csvUpload);
      dispatch(socketActions.setUploadStatus(''));
    }
  }, [csvUpload, setSuccessToast, dispatch]);

  return (
      <div id="App" className='App'>
        <CssBaseline />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<RequireAuth />}>
            <Route element={<Layout />}>
              {licenseType === 'relay' ? 
              <>
                <Route path="" element={<Campuses />} />
                <Route path="/">
                  <Route path="campuses" element={<Campuses />} />
                  <Route path="alerts" element={<CampusAlerts />} />
                  <Route path="events" element={<Events/>}/>
                  <Route path="messages" element={<CapMessages />} />
                </Route>
              </> :
              <>
                <Route path="" element={<Dashboard />} />
                <Route path="/">
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="notifications" element={<Notifications />} />
                  <Route path="campuses" element={<Campuses />} />
                  <Route path="alerts" element={<CampusAlerts />} />
                  <Route path="statuses" element={<CampusStatuses />} />
                  <Route path="messages" element={<CapMessages />} />
                  <Route path="settings" element={<Settings />} />
                  <Route path="users" element={<Users />} />
                  <Route path="roles" element={<Roles />} />
                  <Route path="tags" element={<Tags />} />
                  <Route path="events" element={<Events/>}/>
                  {licenseType === 'full' && <Route path="maintenance" element={<DeviceMaintenance/>}/>} 
                </Route>
              </>}
            </Route>
          </Route>
          <Route path="*" element={licenseType === 'relay' ? <Navigate to='/campuses'/> : <Navigate to='/dashboard'/>} />
        </Routes>
      </div>
  );
};

export default App;
