import { useCallback, useState } from 'react';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { Dropdown, useDialogContext, TextInput, BaseDialog, useToastContext, ErrorFocus } from '../../Components';
import { useGetDistrictsQuery, useGetCampusStatusesQuery, useGetTagsQuery, 
  useAddCampusMutation, useEditCampusMutation,
  useGetSettingQuery, useGetZipCodeCoordinatesQuery } from '../../Redux/api';
import { dashboardMapSliceActions } from '../../Redux/Slices/DashboardMapSlice';
import { useAppSelector, useAppDispatch } from '../../Redux/hooks';
import { DEFAULT_ZOOM } from '../../Utils/constants';
import { getErrorMessage } from '../../Utils/errorHandling';
import { trimRequest } from '../../Utils/trimRequest';
import { stateList } from './campusFormLists';
import { ve6025VersionList } from './campusFormLists';
import { CampusRequest, Campus, Tag } from '../../TypeScript/AppTypes';
import EditCampusMap from '../../Leaflet/EditCampusMap';
import styles from './CampusStyle.module.css';

export const blankCampusInput: Partial<CampusRequest> = {
  name: '',
  district: null,
  status: 'Normal',
  tags: [],
  address: '',
  city: '',
  state: null,
  postal: '',
  telephone: '',
  ip_six: '',
  fqdn: '',
  udp_port: 0,
  ve6025_version: null,
  ve6025_username: '',
  ve6025_password: '',
};

export const CampusFormDialog = (props: {
  open: boolean,
  campus?: Campus,
}): JSX.Element => {
  const { open, campus } = props;
  
  const { data: districts } = useGetDistrictsQuery();
  const { data: campusStatuses } = useGetCampusStatusesQuery();
  const { data: defaultCenterCoords } = useGetZipCodeCoordinatesQuery();
  const { data: defaultZoom } = useGetSettingQuery(DEFAULT_ZOOM);
  const { data: tags } = useGetTagsQuery();
  const { selectedCampuses } = useAppSelector((store) => store.dashboardMap);
  const dispatch = useAppDispatch();

  const [addCampus] = useAddCampusMutation();
  const [editCampus] = useEditCampusMutation();
  const { closeDialog } = useDialogContext();
  const { setSuccessToast, setErrorToast } = useToastContext();

  const [selectedTags, setSelectedTags] = useState<Tag[]>(campus ? campus.tags : []);
  const [coords, setCoords] = useState(campus && campus.geom ? campus.geom.coordinates : [] );
  const [confirmedCoords, setConfirmedCoords] = useState(campus && campus.geom ? campus.geom.coordinates : [] );
  const handleCoordsChange = useCallback((c: number[]) => setCoords(c), [setCoords]);

  const [errors, setErrors] = useState<{
    name: boolean,
    district: boolean,
    address: boolean,
    city: boolean,
    state: boolean,
    status: boolean,
    zipcode: boolean,
    telephone: boolean,
    fqdn: boolean,
    ve6025_version: boolean,
    ve6025_password: boolean,
    ve6025_username: boolean,
  }>({
    name: false,
    district: false,
    address: false,
    city: false,
    state: false,
    status: false,
    zipcode: false,
    telephone: false,
    fqdn: false,
    ve6025_version: false,
    ve6025_password: false,
    ve6025_username: false,
  });

  const validateCampusRequest = (input: CampusRequest) => {
    
    const postalRegEx = /(?:^|\D)(\d{5})(?!\d)/g; // matches exactly 5 digits

    // makes sure telephone is only digits
    const telephoneRegEx = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

    let isNameInvalid = false;
    let isAddressInvalid = false;
    let isCityInvalid = false;
    let isStateInvalid = false;
    let isDistrictInvalid = false;
    let isStatusInvalid = false;
    let isZipcodeInvalid = false;
    let isTelephoneInvalid = false;
    let isFqdnInvalid = false;
    let isVersionInvalid = false;
    let isPasswordInvalid = false;
    let isUsernameInvalid = false;

    if (!input.name?.length) isNameInvalid = true;
    if (!input.address?.length) isAddressInvalid = true;
    if (!input.city?.length) isCityInvalid = true;
    if (!input.state?.length) isStateInvalid = true;
    if (!input.district?.length) isDistrictInvalid = true;
    if (!input.status?.length) isStatusInvalid = true;
    if (input.ve6025_password?.length && !input.ve6025_username?.length) isUsernameInvalid = true;
    
    if (input.ve6025_password?.length && input.ve6025_password !== input.confirm_password) {
      isPasswordInvalid = true;
    }
    // zipcode validation
    if (!input.postal?.length || postalRegEx.test(input.postal) === false) {
      isZipcodeInvalid = true;
    } 

    // telephone validation
    if (input.telephone && telephoneRegEx.test(input.telephone) === false) {
      isTelephoneInvalid = true;
    }

    // version validation
    if (!input.ve6025_version === null && (ve6025VersionList.filter(version => input.ve6025_version)).length <= 0) {
      isVersionInvalid = true;
    }

    // fqdn IP address validation
    if (input.fqdn && !input.fqdn.includes('http')) {
      isFqdnInvalid = true;
    }

    if (input.telephone && telephoneRegEx.test(input.telephone) === false) {
      isTelephoneInvalid = true;
    }

    setErrors({
      name: isNameInvalid,
      district: isDistrictInvalid,
      address: isAddressInvalid,
      city: isCityInvalid,
      state: isStateInvalid,
      status: isStatusInvalid,
      zipcode: isZipcodeInvalid,
      telephone: isTelephoneInvalid,
      fqdn: isFqdnInvalid,
      ve6025_version: isVersionInvalid,
      ve6025_password: isPasswordInvalid,
      ve6025_username: isUsernameInvalid,
    });

    if (isNameInvalid || isAddressInvalid || isCityInvalid ||
      isStateInvalid || isDistrictInvalid || isStatusInvalid || 
      isZipcodeInvalid || isTelephoneInvalid || isPasswordInvalid ||
      isUsernameInvalid || isVersionInvalid || isFqdnInvalid) return false;
    return true;
  };

  return (districts && campusStatuses && tags ?
    <BaseDialog
      open={open}
      title={campus ? 'Update Campus' : 'Create New Campus'}
    >
      <form
        noValidate
        onSubmit={async (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const fieldValues = Object.fromEntries(formData.entries());

          // Get data from form, then add values from controlled multi-select field (tags) 
          // and match ids with value of uncontrolled dropdowns (status and district)
          const body: CampusRequest = trimRequest(fieldValues) as unknown as CampusRequest;
          body.tags = selectedTags.length > 0 ? selectedTags.map(t => t.id) : [];
          if (body.district)
            body.district = districts.filter(d => d.name === body.district)[0].id;
          if (body.status)
            body.status = campusStatuses.filter(s => s.label === body.status)[0].id;
          if (campus && campus.geom) {
            if (confirmedCoords !== campus.geom.coordinates) {
              body.geom = { type: 'Point', coordinates: confirmedCoords };
            }
          }
          const isValidRequest = validateCampusRequest(fieldValues as unknown as CampusRequest);
          delete body.confirm_password;
          if (!body.udp_port || body.udp_port < 0) body.udp_port = 0;
          if (!body.ve6025_password?.length || body.ve6025_password === campus?.ve6025_password) {
            delete body.ve6025_password;
          }
          if (body.fqdn && !body.fqdn.includes('http')) {
            delete body.ve6025_password;
          }

          if (isValidRequest) {
            try {
              if (campus) {
                const updatedCampus = await editCampus({ ...body, id: campus.id }).unwrap();
                const index = selectedCampuses.map(c => c.id).indexOf(campus.id);
                if (index !== -1) {
                  const updatedArray = selectedCampuses.map(c => c.id === updatedCampus.id ? updatedCampus : c);
                  dispatch(dashboardMapSliceActions.setSelectedCampuses(updatedArray));
                }
                setSuccessToast('Campus successfully updated');
              } else {
                await addCampus(body).unwrap();
                setSuccessToast('Campus successfully created');
              }
              closeDialog();
            } catch (err: unknown) {
              setErrorToast(getErrorMessage(err));
            }
          }
        }}
      >
        <DialogContent>
          <TextInput
            id="name" name="name" label="Name" required
            defaultValue={campus ? campus.name : blankCampusInput.name}
            error={errors.name} helperText="Name required"
          />
          <Dropdown
            id="district" name="district" label="District" required
            options={districts.map(d => d.name ? d.name : '').filter(n => n !== '')}
            defaultValue={campus && campus.district ? campus.district.name : blankCampusInput.district}
            error={errors.district} helperText="District required"
          />
          <Dropdown
            id="tags" name="tags" label="Tags"
            isMultiSelect={true}
            value={selectedTags.map(t => t.name ? t.name : '').filter(n => n !== '')}
            options={tags.map(t => t.name ? t.name : '').filter(n => n !== '')}
            onChange={(e, newValue) => {
              const newTagArray: Tag[] = tags.filter(t => t.name && newValue?.includes(t.name));
              setSelectedTags(newTagArray);
            }}
          />
          <Dropdown
            id="status" name="status" label="Status" required
            options={campusStatuses.map(s => s.label ? s.label : '').filter(l => l !== '')}
            defaultValue={campus && campus.status ? campus.status.label : blankCampusInput.status}
            error={errors.status} helperText="Status required"
          />
          <TextInput
            id="address" name="address" label="Address" required
            defaultValue={campus ? campus.address : blankCampusInput.address}
            error={errors.address} helperText="Address required"
          />
          <TextInput
            id="city" name="city" label="City" required
            defaultValue={campus ? campus.city : blankCampusInput.city}
            error={errors.city} helperText="City required"
          />
          <Dropdown
            id="state" label="State" required
            options={stateList}
            defaultValue={campus ? campus.state : blankCampusInput.state}
            error={errors.state} helperText="State required"
          />
          <TextInput
            id="postal" name="postal" label="ZIP Code" required
            defaultValue={campus ? campus.postal : blankCampusInput.postal}
            error={errors.zipcode} helperText={'Enter a valid 5-digit ZIP code'}
          />
          <TextInput
            id="telephone" name="telephone" label="Telephone"
            defaultValue={campus ? campus.telephone : blankCampusInput.telephone}
            error={errors.telephone} helperText={'Enter a valid 10-digit telephone number'}
          />
          <TextInput
            id="ip_six" name="ip_six" label="IP6000 Domain Name/IP Address"
            defaultValue={campus ? campus.ip_six : blankCampusInput.ip_six}
          />
          <TextInput
            id="fqdn" name="fqdn" label="VE6025 Domain Name/IP Address"
            defaultValue={campus ? campus.fqdn : blankCampusInput.fqdn}
            error={errors.fqdn} helperText='"http://" or "https://" required when Domain Name/IP Address is present'
          />
          <Dropdown
            id="ve6025_version" name="ve6025_version" label="VE6025 Version"
            options={ve6025VersionList}
            defaultValue={campus ? campus.ve6025_version : null}
            error={errors.ve6025_version} helperText="Invalid Version"
          />
          <TextInput
            id="ve6025_username" name="ve6025_username" label="VE6025 Username"
            defaultValue={campus ? campus.ve6025_username : blankCampusInput.ve6025_username}
            error={errors.ve6025_username} helperText='Username required when password is present'
          />
          <TextInput
            id="ve6025_password" name="ve6025_password" label="VE6025 Password"
            type='password'
            defaultValue=''
            error={errors.ve6025_password} helperText='Passwords do not match'
          />
          <TextInput
            id="confirm_password" name="confirm_password" label="Confirm Password"
            type='password'
            defaultValue=''
            error={errors.ve6025_password} helperText='Passwords do not match'
          />
          <TextInput
            id="udp_port" name="udp_port" label="TCP Port" type="number"
            defaultValue={campus ? campus.udp_port : blankCampusInput.udp_port}
          />
          {
            campus && defaultCenterCoords && defaultZoom ? 
            <div>
            <p className={styles.helpText}>Click and Drag Map Marker to select a different location for Campus</p>
            <EditCampusMap
            defaultCenterCoords={defaultCenterCoords}
            defaultZoom={defaultZoom.value}
            coords={coords}
            handleCoordsChange={handleCoordsChange}
            confirmedCoords={confirmedCoords}
            setConfirmedCoords={setConfirmedCoords}
            /> 
            <p className={styles.helpText}>Click Confirm Marker Location to confirm map location for the Campus</p>
            </div>
              : null
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeDialog()}>
            Cancel
          </Button>
          <Button type="submit">
            Submit
          </Button>
        </DialogActions>
        <ErrorFocus errors={errors} />
      </form>
    </BaseDialog> : <></>
  );
};