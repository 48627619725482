import { useState } from 'react';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { useDialogContext, useToastContext, BaseDialog, TextInput } from '../../Components';
import { Tag, TagRequest } from '../../TypeScript/AppTypes';
import { useAddTagsMutation, useEditTagsMutation } from '../../Redux/api';
import { trimRequest } from '../../Utils/trimRequest';
import { getErrorMessage } from '../../Utils/errorHandling';

export const blankTagInput: Partial<TagRequest> = {
  name: '',
};

export const TagFormDialog = (props: {
  open: boolean,
  tag?: Tag,
}): JSX.Element => {

  const { open, tag } = props;
  const [addTag] = useAddTagsMutation();
  const [editTag] = useEditTagsMutation();
  const { closeDialog } = useDialogContext();
  const { setSuccessToast, setErrorToast } = useToastContext();

  // errors
  const [error, setError] = useState(false);

  const validateTagRequest = (input: TagRequest) => {

    const isNameInvalid = !(input.name && input.name.length > 0) ? true : false;

    setError(isNameInvalid);
    if (!isNameInvalid)
      return true;
    else {
      return false;
    }
  };

  return (    
    <BaseDialog
      open={open}
      title={tag ? 'Update Tag' : 'Create New Tag'}
    >
      <form 
        noValidate 
        onSubmit={async (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const fieldValues = Object.fromEntries(formData.entries());

          // Get data from form
          const body: TagRequest = trimRequest(fieldValues) as unknown as TagRequest;
          const isValidRequest = validateTagRequest(body);

          if (isValidRequest) {
            try {
              if (tag) {
                await editTag({ ...body, id: tag.id }).unwrap();
                setSuccessToast('Tag successfully updated');
              } else {
                await addTag(body).unwrap();
                setSuccessToast('Tag successfully created');
              } 
              closeDialog();
            } catch (err: unknown) {
              setErrorToast(getErrorMessage(err));
            }
          }
        }}
      >
        <DialogContent>
          <TextInput 
            id="name" name="name" label="Name" required
            error={error} helperText="Please enter a name."
            defaultValue={tag ? tag.name : blankTagInput.name}
          /> 
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>
            Cancel
          </Button>
          <Button type="submit">
            Submit
          </Button>
        </DialogActions>
      </form>
    </BaseDialog>
  );
};

export default TagFormDialog;