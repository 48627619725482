import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { GridActionsCellItem, GridRowParams } from '@mui/x-data-grid';
import { useDeleteTagsMutation, useGetTagsQuery } from '../../Redux/api';
import { CrudTable, DeleteDialog, useDialogContext } from '../../Components';
import TagFormDialog from './TagFormDialog';
import { Tag } from '../../TypeScript/AppTypes';
import { useAppSelector } from '../../Redux/hooks';
import styles from './Tags.module.css';

const Tags = (): JSX.Element => {

  const { data: tags, error, isLoading } = useGetTagsQuery();
  const [deleteTag] = useDeleteTagsMutation();
  const { dialogType, openDialog } = useDialogContext();
  const { roles } = useAppSelector((store) => store.auth);
  const nonAdminSecurity = roles.includes('security') && !roles.includes('admin');
  const location = useLocation();
  const headerLocation = (((location.pathname).slice(1, 2)).toUpperCase() + (location.pathname).slice(2));

  const tableColumns: {
    field: string, headerName: string, width?: number, type?: string,
    getActions?: (x: GridRowParams) => JSX.Element[], hide?: boolean, flex?: number,
  }[] = [
    { field: 'id', headerName: 'ID', flex: 1, hide: true },
    { field: 'name', headerName: 'Name', flex: 1 },
  ];

  const editColumn = {
    field: 'actions',
    headerName: 'Actions',
    type: 'actions',
    width: 80,
    getActions: (params: GridRowParams) => [
      <GridActionsCellItem
        data-cy="edit"
        icon={<Edit />}
        onClick={(e) => {
          handleTagSelection(params.id as string);
          openDialog('Update');
        }}
        label="Edit" />,
      <GridActionsCellItem
        data-cy="delete"
        icon={<Delete />}
        onClick={(e) => {
          handleTagSelection(params.id as string);
          openDialog('Delete');
        }}
        label="Delete" />,
    ],
  };

  const [tag, setTag] = useState<Tag | undefined >(undefined);
  const handleTagSelection = useCallback((id?: string) => {
    if (id && tags) {
      const selectedTag = tags.filter(t => t.id === id)[0];
      setTag(selectedTag);
    } else 
      setTag(undefined);
  }, [tags]);

  return ( tags ? <>
    <h1 className={styles.headerName}>{headerLocation}</h1>
    <Tooltip title='Manage your tags' arrow>
      <InfoIcon />
    </Tooltip>
    <CrudTable
      dataLabel='Tag'
      handleRecordSelection={handleTagSelection}
      rowData={tags}
      error={error}
      isLoading={isLoading}
      columnNames={nonAdminSecurity ? tableColumns : [editColumn, ...tableColumns]}
      restrictedRole={nonAdminSecurity}
      createOrEditDialog={
        <TagFormDialog
          open={dialogType === 'Create' || dialogType === 'Update'}          
          tag={tag || undefined}
        /> 
      }
      deleteDialog={tag ?
        <DeleteDialog
          open={dialogType === 'Delete'}
          dataLabel='Tag'
          handleDelete={async () => {
            await deleteTag(tag.id).unwrap();
          }}
        /> :
        <></>
      }   
  /></> : <></>);
};

export default Tags;
