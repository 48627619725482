import { GridRenderEditCellParams } from '@mui/x-data-grid';
import { useState } from 'react';
import { useEditDeviceMutation } from '../../Redux/api';
import { useAppDispatch } from '../../Redux/hooks';
import { useToastContext } from '../Toast/ToastContext';
import { api } from '../../Redux/api';
import Dropdown from './Dropdown';

const statusOptions = [
  'Needs Attention',
  'In Progress',
  'Resolved',
];
const MaintStatusDropdown = (editSelectProps: GridRenderEditCellParams) => {
  const [editDevice] = useEditDeviceMutation();
  const dispatch = useAppDispatch();
  const [maintStatus, setMaintStatus] = useState(editSelectProps.formattedValue);
  const { setSuccessToast } = useToastContext();
  return (
    <Dropdown
      notFull
      id="maintStat" name="maintStatus" label=''
      options={statusOptions}
      defaultValue={maintStatus}
      value={maintStatus}
      onChange={async (e, newValue) => {
        setMaintStatus(newValue);
        const editRes = await editDevice({
          id: editSelectProps.id as number, maintenance_status: newValue as string,
        }).unwrap();
        if (editRes) {
          if (editRes.campus) dispatch(api.util.invalidateTags([{ type: 'Campus', id: editRes.campus.id }]));
          let msg = `Maintenance Item updated to ${editRes.maintenance_status}`;
          if (editRes.maintenance_status === 'Resolved') msg += ' and was moved to History';
          setSuccessToast(msg);
        }
      }}
    />
  );
};

export default MaintStatusDropdown;