import { useEffect, useRef } from 'react';
import { LatLngExpression, LeafletMouseEvent } from 'leaflet';
import { CircleMarker, Tooltip } from 'react-leaflet';
import { Campus } from '../TypeScript/AppTypes';

interface CampusMarkerProps {
  id: string, 
  campusName: string,
  coords: LatLngExpression, 
  isSelected: boolean, 
  isEnlarged: boolean,
  borderColor: string, 
  color: string,
  campuses: Campus[],
  menu: JSX.Element,
  handleLeftClick: () => void,
  handleRightClick: (e: LeafletMouseEvent) => void,
}

const CampusMarker = (props: CampusMarkerProps): JSX.Element => {

  const { id, campusName, coords, isSelected, isEnlarged, borderColor, color,  
    menu, handleLeftClick, handleRightClick } = props;
  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const circleRef = useRef<any>(null);
  
  useEffect(() => {
    circleRef.current.setStyle({ color: borderColor }); // borderColor => color
    circleRef.current.setStyle({ fillColor: color }); // color => fillColor
  }, [color, borderColor]);

  return (
    <CircleMarker
      key={id}
      pathOptions={isSelected  ?  { weight: 4 } : { weight: 2 }}
      color={borderColor}
      fillColor={color}
      center={coords}
      radius={isEnlarged ? 10 : 7} 
      fillOpacity={2}
      interactive={true}
      ref={(ref) => {
        circleRef.current = ref;
      }}
      bubblingMouseEvents={false}
      eventHandlers={{ 
        click: () => handleLeftClick(), 
        contextmenu: (e) => handleRightClick(e),
      }}
    >
        <Tooltip>{campusName}</Tooltip>
        {menu}
      </CircleMarker>
  );
};

export default CampusMarker;
