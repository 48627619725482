import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import { Edit, Delete } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { GridActionsCellItem, GridAlignment, GridRenderCellParams, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';
import { CrudTable, DeleteDialog, useDialogContext } from '../../Components';
import { useDeleteCampusAlertMutation, useGetCampusAlertsQuery } from '../../Redux/api';
import { CampusAlertFormDialog } from './CampusAlertFormDialog';
import { CampusAlert } from '../../TypeScript/AppTypes';
import styles from './CampusAlert.module.css';
import { useAppSelector } from '../../Redux/hooks';

const CampusAlerts = (): JSX.Element => {

  const { data: alerts, error, isLoading } = useGetCampusAlertsQuery();
  const [deleteCampusAlert] = useDeleteCampusAlertMutation();
  const { dialogType, openDialog } = useDialogContext();
  const { roles } = useAppSelector((store) => store.auth);
  const nonAdminSecurity = roles.includes('security') && !roles.includes('admin');
  const location = useLocation();
  const headerLocation = 'Campus ' + (((location.pathname).slice(1, 2)).toUpperCase() + (location.pathname).slice(2));

  const tableColumns: {
    field: string, headerName: string, width?: number, type?: string,
    getActions?: (x: GridRowParams) => JSX.Element[], minWidth?: number,
    valueGetter?: (x: GridValueGetterParams) => string, hide?: boolean, flex?: number,
    renderCell?: (x: GridRenderCellParams) => JSX.Element, align?: GridAlignment,
  }[] = [
    { field: 'id', headerName: 'ID', flex: 1, hide: true },
    { field: 'label', headerName: 'Label', flex: 2, minWidth: 150 },
    { field: 'color', headerName: 'Color',
      renderCell: (params: GridRenderCellParams) => (
        <span style={{ backgroundColor: params.value }} className={styles.colorCell}></span>
      ),
      flex: 1, minWidth: 75, align: 'center' },
    { field: 'borderColor', headerName: 'Border Color', 
      renderCell: (params: GridRenderCellParams) => (
        <span style={{ backgroundColor: params.value }} className={styles.colorCell}></span>
      ),
      flex: 1, minWidth: 75, align: 'center' },
    { field: 'textColor', headerName: 'Text Color',
      renderCell: (params: GridRenderCellParams) => (
        <span style={{ backgroundColor: params.value }} className={styles.colorCell}></span>
      ),
      flex: 1, minWidth: 75, align: 'center' },
    { field: 'description', headerName: 'Description', flex: 2, minWidth: 100 },
  ];

  const editColumn = {
    field: 'actions',
    headerName: 'Actions',
    type: 'actions',
    width: 80,
    getActions: (params: GridRowParams) => [
      <GridActionsCellItem
        data-cy="edit"        
        icon={<Edit />}
        onClick={(e) => {
          handleAlertSelection(params.id as string);
          openDialog('Update');
        }}
        label="Edit" />,
      <GridActionsCellItem
        data-cy="delete"
        icon={<Delete />}
        onClick={(e) => {
          handleAlertSelection(params.id as string);
          openDialog('Delete');
        }}
        label="Delete" />,
    ],
  };

  const [alert, setAlert] = useState<CampusAlert | undefined>();
  const handleAlertSelection = useCallback((id?: string) => {
    if (id && alerts) {
      const selectedAlert = alerts.filter(a => a.id === id)[0];
      setAlert(selectedAlert);
    } else {
      setAlert(undefined);
    }
  }, [alerts]);

  return (alerts ? <>
    <h1 className={styles.headerName}>{headerLocation}</h1>
    <Tooltip title='Manage campus alerts. Alerts are sent to other campuses via the Events page.' arrow>
      <InfoIcon />
    </Tooltip>
    <CrudTable
      dataLabel='Campus Alert'
      handleRecordSelection={handleAlertSelection}
      rowData={alerts}
      error={error}
      isLoading={isLoading}
      columnNames={nonAdminSecurity ? tableColumns : [editColumn, ...tableColumns]}
      restrictedRole={nonAdminSecurity}
      createOrEditDialog={
        <CampusAlertFormDialog
          open={dialogType === 'Create' || dialogType === 'Update'}
          alert={alert || undefined}
        />
      }
      deleteDialog={alert && alert !== null ?
        <DeleteDialog
          open={dialogType === 'Delete'}
          dataLabel='Campus Alert'
          handleDelete={async () => { 
            await deleteCampusAlert(alert.id).unwrap();
          }}
        /> :
        <></>
      }
    /></> : <></>);
};

export default CampusAlerts;
