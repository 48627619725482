import { DialogContent, DialogActions, Button } from '@mui/material';
import { useDialogContext, useToastContext } from '..';
import { AppDataLabel } from '../../TypeScript/AppTypes';
import { getErrorMessage } from '../../Utils/errorHandling';
import BaseDialog from './BaseDialog';

export interface DeleteDialogProps {
  open: boolean,
  dataLabel: AppDataLabel,
  handleDelete: () => Promise<void>;
}

const DeleteDialog = (props: DeleteDialogProps): JSX.Element => {

  const { open, dataLabel, handleDelete } = props;
  const { setSuccessToast, setErrorToast } = useToastContext();
  const { closeDialog } = useDialogContext();

  return (
    <BaseDialog
        open={open}
        title={`Delete ${dataLabel}`}
      >
        <>
          <DialogContent>
              Are you sure you want to delete this {dataLabel.toLowerCase()}?
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>
              No
            </Button>
            <Button 
              type="submit"
              onClick={async () => {
                try {
                  await handleDelete();
                  setSuccessToast(`${dataLabel} deleted`);
                  closeDialog();
                } catch (err: unknown) {
                  setErrorToast(getErrorMessage(err));
                }
              }}
            >
              Yes
          </Button>
        </DialogActions>
      </>
    </BaseDialog>
  );
};

export default DeleteDialog;