/*eslint-disable react-hooks/exhaustive-deps*/
import { useEffect, useState } from 'react';
import { LatLngTuple, LatLngExpression, FeatureGroup } from 'leaflet';
import { Button } from '@mui/material';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import { CampusMap } from '../../Leaflet';
import { useDialogContext } from '../../Components';
import DashboardMarker from './DashboardMarker';
import { Campus, MapState } from '../../TypeScript/AppTypes';
import styles from '../../Leaflet/CampusMap.module.css';

interface DashboardMapProps {
  defaultCenterCoords: LatLngTuple,
  defaultZoom: number,
  campuses: Campus[],
  matchedCampuses: Campus[] | undefined
  selectedCampuses: Campus[],
  handleCampusSelectionChange: (selection: Campus[]) => void,
  handleCampusEdit: (id: string) => void,
  mapState: MapState,
  handleMapLayerChange: (newLayer: FeatureGroup | null) => void,
  handleMapStateChange: (newMapState: MapState) => void,
  handleSenderTypeChange: (type?: 'Campus Alert' | 'Cap Message') => void,
}

const DashboardMap = (props: DashboardMapProps): JSX.Element => {

  const { defaultCenterCoords, defaultZoom, campuses, matchedCampuses, 
    selectedCampuses, handleCampusSelectionChange, handleCampusEdit,
    mapState, handleMapStateChange, handleMapLayerChange, handleSenderTypeChange } = props;
  const [senderButtonDisabled, setSenderButtonDisabled] = useState(true);
  const [clearAllBtnDisabled, setClearAllBtnDisabled] = useState(true);
  const { openDialog } = useDialogContext();

  useEffect(() => {
    if (selectedCampuses.length === 1 && selectedCampuses[0].fqdn){
      setSenderButtonDisabled(false);
      setClearAllBtnDisabled(false);
    } else if (selectedCampuses.length > 1){
      setSenderButtonDisabled(false);
      setClearAllBtnDisabled(false);
    } else if (selectedCampuses.length){
      setClearAllBtnDisabled(false);
    } else {
      setClearAllBtnDisabled(true);
      setSenderButtonDisabled(true);
    }
  }, [selectedCampuses]);

  const updateCampusStatusAndCapWithShapes = (type: 'Campus Alert' | 'Cap Message') => {
    handleSenderTypeChange(type);
    openDialog('Sender');
  };
  
  return (
    <CampusMap
      mapType='dashboard'
      fullsize
      defaultCenterCoords={defaultCenterCoords}
      defaultZoom={defaultZoom}
      campuses={campuses}
      selectedCampuses={selectedCampuses}
      handleCampusSelectionChange={handleCampusSelectionChange}
      mapState={mapState}
      handleMapStateChange={handleMapStateChange}
      handleMapLayerChange={handleMapLayerChange}
      clearAllBtnDisabled={clearAllBtnDisabled}
      markers={(matchedCampuses?.length ? matchedCampuses : [])
        .map((campus) => {
          const lat = campus.geom?.coordinates[1];
          const lng = campus.geom?.coordinates[0];
          let borderColor = 'black', color = 'white';
          if (campus.status && campus.status.borderColor && campus.status.color) {
            borderColor = campus.status.borderColor;
            color = campus.status.color;
          } 
          if (campus.alert && campus.alert.borderColor && campus.alert.color && parseInt(campus.status.id, 10) === 1) {
            borderColor = campus.alert.borderColor;
            color = campus.alert.color;
          }
          return (
            <DashboardMarker 
              key={campus.id} 
              id={campus.id as string} 
              campusName={campus.name ? campus.name : null}
              coords={[lat, lng] as LatLngExpression} 
              borderColor={borderColor}
              color={color}
              campuses={campuses}
              isSelected={selectedCampuses.find(c => c.id === campus.id) !== undefined}
              selectedCampuses={selectedCampuses}
              handleCampusSelectionChange={handleCampusSelectionChange}
              handleUpdateStateAndCap={updateCampusStatusAndCapWithShapes}
              handleCampusEdit={handleCampusEdit}
            />);
        })}
      leftButtons={
        <>

              {
                  campuses.find((c) => c.alert !== null) !== undefined ||
                  campuses.reduce((accumulator, c) =>  { return accumulator + (c.status !== null ? parseInt(c.status.id, 10) : 0); }, 0) !== campuses.length ?
                <Button
                className={ styles.resetAllCampusesButton }
                onClick={ () => openDialog('Reset Campus') }
                >
                  Reset All Campuses
                </Button> :
                    null
              }
        </>
      }
      rightButtons={<>
          <Button 
            className={senderButtonDisabled ? styles.statusButtonDisabled : styles.buttonStatus}
            variant='contained' 
            disabled={senderButtonDisabled}
            onClick={() => updateCampusStatusAndCapWithShapes('Campus Alert')}
            >
            <AutorenewOutlinedIcon className={styles.buttonIcon}/>
            Send Alert
          </Button>
          <Button 
            className={senderButtonDisabled ? styles.capButtonDisabled : styles.buttonCap}
            variant='contained' 
            disabled={senderButtonDisabled}
            onClick={() => updateCampusStatusAndCapWithShapes('Cap Message')}
            >
            <AddCommentOutlinedIcon className={styles.buttonIcon}/> 
            Cap Message
          </Button>
      </>}
    />
  );
};

export default DashboardMap;


