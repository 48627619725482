import { RemoveRedEyeTwoTone } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import { DataGrid, GridCellEditStopParams, GridCellParams, GridCellValue, GridRenderEditCellParams, GridValueFormatterParams, MuiEvent } from '@mui/x-data-grid';
import { useCallback, useState } from 'react';
import { useDialogContext } from '../../Components';
import MaintStatusDropdown from '../../Components/Dropdown/MaintStatusDropdown';
import { Device } from '../../TypeScript/AppTypes';
import { getDateString } from '../../Utils/getDateString';
import { getCampusName } from '../../Utils/valueGetters';
import { DeviceDetailsDialog } from './DeviceDetailsDialog';
import { useAppDispatch, useAppSelector } from '../../Redux/hooks';
import { api } from '../../Redux/api';
import { userPreferenceSliceActions } from '../../Redux/Slices/UserPreferences';

const DeviceTable = ({
  deviceInfo, tableType, error,
}: {
  deviceInfo: Device[], tableType: string, error: FetchBaseQueryError | SerializedError | undefined
}) => {
  const dispatch = useAppDispatch();
  const { dialogType, openDialog } = useDialogContext();
  const [device, setDevice] = useState<Device | undefined >(undefined);

  const { rowsPerPage } = useAppSelector((store) => store.userPreferences);

  const handleDeviceSelection = useCallback((id: number) => {
    if (id && deviceInfo) {
      const selectedDevice = deviceInfo.filter(d => d.id === id)[0];
      setDevice(selectedDevice);
    } else 
      setDevice(undefined);
  }, [deviceInfo]);  

  const handleChangeRowsPerPage = (e: number) => {
    dispatch(userPreferenceSliceActions.setRowsPerPage(e));
  };

  return (deviceInfo ? <>
    {device ? 
      <DeviceDetailsDialog
        isOpen={dialogType === 'Device Details'}
        device={device}
      /> : <></>}
      <div style={{ height: 500, width: '100%' }}>
        <DataGrid
          experimentalFeatures={{ newEditingApi: true }}
          error={error}
          rowsPerPageOptions={[10, 25, 50]}
          pageSize={rowsPerPage}
          onPageSizeChange={(newPageSize) => (handleChangeRowsPerPage(newPageSize))}
          rows={deviceInfo.map((d: Device) =>
            ({ viewReport: 
              <IconButton
                role='dialog-button' 
                size='large' 
                onClick={() => { 
                  handleDeviceSelection(d.id);
                  openDialog('Device Details');
                }}
              >
                <Tooltip title="View Report">
                  <RemoveRedEyeTwoTone />
                </Tooltip>
              </IconButton>, 
            ...d }),
          )} 
          columns={[
            { 
              field: 'viewReport',
              headerName: 'Report',
              flex: 1,
              renderCell: (params: GridCellParams): GridCellValue => { return params.value; },
              align: 'center',
            },
            { field: 'id', headerName: 'ID', flex: 1, hide: true },
            { field: 'reported_at', headerName: 'Date', flex: 2,
              valueFormatter: (params: GridValueFormatterParams) => getDateString(params.value) },
            { field: 'campus', headerName: 'Campus', valueGetter: getCampusName, flex: 3 },
            { field: 'endpoint_name', headerName: 'Endpoint Name', flex: 2 },
            { field: 'endpoint_details', headerName: 'Endpoint Details', flex: 2 },
            { field: 'endpoint_status', headerName: 'Endpoint Status', flex: 2 },
            {
              field: 'maintenance_status',
              headerName: 'Maintenance Status',
              editable: tableType === 'active',
              flex: 3,
              renderEditCell: (params: GridRenderEditCellParams) => (
                <MaintStatusDropdown {...params} />
              ),
            },      
          ]}
          onCellEditStop={(params: GridCellEditStopParams, event: MuiEvent) => {
            if (params.id) dispatch(api.util.invalidateTags([{ type: 'Device', id: params.id }]));
          }}
        />
      </div>
      </> :
    <></>
  );
};
export default DeviceTable;