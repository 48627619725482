import { SyntheticEvent } from 'react';
import { Alert, IconButton, Snackbar, SnackbarCloseReason } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ToastInterface } from './ToastProvider';

export interface ToastProps {
  state: ToastInterface,
  close: () => void,
}

export const Toast = (props: ToastProps): JSX.Element => {

  const { close, state } = props;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSnackbarClose = (event: Event | SyntheticEvent<any, Event>, 
    reason: SnackbarCloseReason) => {   
    close();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleAlertClose = (event: SyntheticEvent<any, Event>) => {
    close();
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleAlertClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={state.isOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={state.message}
        action={action}
      >
        <Alert 
          variant='filled'
          onClose={handleAlertClose} 
          severity={state.severity} 
          sx={{ width: '100%' }}
        >
          {state.message}
        </Alert> 
      </Snackbar>
    </div>
  );
};

export default Toast;
