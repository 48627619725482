import { combineReducers } from '@reduxjs/toolkit';
import { authSlice } from './Slices/AuthSlice';
import { socketSlice } from './Slices/SocketSlice';
import { inboxSlice } from './Slices/InboxSlice';
import { activeCapSlice } from './Slices/ActiveCapSlice';
import { statusUpdateSlice } from './Slices/StatusUpdateSlice';
import { dashboardMapSlice } from './Slices/DashboardMapSlice';
import { userPreferenceSlice } from './Slices/UserPreferences';
import { api } from './api';

const rootReducer = combineReducers({
  // The RTK Query reducerPath key defaults to "api" which is what we are using
  [api.reducerPath]: api.reducer, 
  auth: authSlice.reducer,
  socket: socketSlice.reducer,
  inbox: inboxSlice.reducer,
  activeCap: activeCapSlice.reducer,
  statusUpdate: statusUpdateSlice.reducer,
  dashboardMap: dashboardMapSlice.reducer,
  userPreferences: userPreferenceSlice.reducer,
});

export default rootReducer;