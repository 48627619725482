import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';
import { Edit, Delete, CrisisAlert } from '@mui/icons-material';
import { GridActionsCellItem, GridAlignment, GridRenderCellParams, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';
import { CrudTable, DeleteDialog, renderCellExpand, useDialogContext, useToastContext } from '../../Components';
import { getAlertLabel, getCampusArrayLength, getCapLabel, getStatusLabel, getUsers } from '../../Utils/valueGetters';
import { useDeleteCapMessageMutation, useDeleteEventMutation, useGetCampusesQuery, useGetEventsQuery, useGetSettingQuery, useGetZipCodeCoordinatesQuery } from '../../Redux/api';
import { EventFormDialog } from './EventFormDialog';
import { LatLngTuple } from 'leaflet';
import { DEFAULT_ZOOM } from '../../Utils/constants';
import { CampusEvent, CampusAlert } from '../../TypeScript/AppTypes';
import { useAppSelector } from '../../Redux/hooks';
import EventTriggerDialog from './EventTriggerDialog';
import styles from '../../Components/Table/Table.module.css';

const Events = (): JSX.Element => {
  const { data: campuses } = useGetCampusesQuery();
  const { data: events, error, isLoading } = useGetEventsQuery();
  const { data: defaultCenterCoords } = useGetZipCodeCoordinatesQuery();
  const { data: defaultZoom } = useGetSettingQuery(DEFAULT_ZOOM);
  const [deleteEvent] = useDeleteEventMutation();
  const [deleteCapMessage] = useDeleteCapMessageMutation();
  const { dialogType, openDialog, closeDialog } = useDialogContext();
  const { setErrorToast } = useToastContext();
  const { roles } = useAppSelector((store) => store.auth);
  const nonAdminSecurity = roles.includes('security') && !roles.includes('admin');
  const [event, setEvent] = useState<CampusEvent | null>(null);
  const location = useLocation();
  const headerLocation = (((location.pathname).slice(1, 2)).toUpperCase() + (location.pathname).slice(2));

  const handleEventSelection = useCallback((id?: string) => {
    if (campuses && campuses.length) {
      if (id && events) {
        const selectedEvent = events.filter(e => e.id === id)[0];
        setEvent(selectedEvent);
      } else {
        setEvent(null);
      }
    } else {
      setErrorToast('Campuses required to create an event');
    }
  }, [campuses, events, setErrorToast]);

  useEffect(() => {
    // returned function will be called on component unmount 
    return () => {
      if (!(campuses && campuses.length)) closeDialog();
    };
  }, [closeDialog, campuses]);

  // we want to show events if we have an outgoing alert or a cap message
  // and we only want to show the button if there is not a radius
  const canTriggerEvents = (params: GridRowParams) => {
    const oc = params.row.outgoingCampuses;
    const oa = params.row.outgoingAlert;
    const rad = params.row.radius;
    const isDynamic = params.row.cap?.dynamic;
    const actionItems = [
      <GridActionsCellItem
        data-cy="edit"
        icon={<Edit />}
        onClick={(e) => {
          handleEventSelection(params.id as string);
          openDialog('Update');
        }}
        label="Edit"
      />,
      <GridActionsCellItem
        data-cy="delete"
        icon={<Delete />}
        onClick={(e) => {
          handleEventSelection(params.id as string);
          openDialog('Delete');
        }}
        label="Delete"
      />,
    ];
    if (oc && oa && !rad && !isDynamic) actionItems.unshift(
      <GridActionsCellItem
        icon={
          <Tooltip title='Trigger Event'>
            <CrisisAlert />
          </Tooltip>
        }
        onClick={(e) => {
          handleEventSelection(params.id as string);
          openDialog('Trigger');
        }}
        label="Trigger Event"
      />,
    );
    return actionItems;
  };

  const editColumn = {
    field: 'actions',
    headerName: 'Actions',
    type: 'actions',
    width: 100,
    getActions: (params: GridRowParams) => canTriggerEvents(params as GridRowParams),
  };

  const tableColumns: {
    field: string, headerName: string, width?: number, type?: string,
    getActions?: (x: GridRowParams) => JSX.Element[], minWidth?: number,
    valueGetter?: (x: GridValueGetterParams) => string | number, hide?: boolean, flex?: number,
    renderCell?: (x: GridRenderCellParams) => JSX.Element, align?: GridAlignment,
  }[] = [
    { field: 'label', headerName: 'Event Name', flex: 3, renderCell: renderCellExpand },
    { field: 'description', headerName: 'Description', flex: 4, renderCell: renderCellExpand },
    { field: 'radius', headerName: 'Radius', flex: 1, align: 'center' },
    {
      field: 'incomingStatus', headerName: 'Incoming Status',
      valueGetter: getStatusLabel, flex: 3, renderCell: renderCellExpand,
    },
    {
      field: 'outgoingAlert', headerName: 'Outgoing Alert',
      valueGetter: getAlertLabel, flex: 3, renderCell: renderCellExpand,
    },
    {
      field: 'incomingCampuses', headerName: 'Incoming Campuses',
      valueGetter: getCampusArrayLength, flex: 2.5, align: 'center',
    },
    {
      field: 'outgoingCampuses', headerName: 'Outgoing Campuses',
      valueGetter: getCampusArrayLength, flex: 2.5, align: 'center',
    },
    {
      field: 'users', headerName: 'Users',
      valueGetter: getUsers, flex: 2, renderCell: renderCellExpand,
    },
    {
      field: 'cap', headerName: 'CAP',
      valueGetter: getCapLabel, flex: 2, minWidth: 150,
    },
  ];

  return (events ?
    <div className={styles.uniformTable}>
      <h1 className={styles.headerName}>{headerLocation}</h1>
      <Tooltip title='Manage the events that will affect campuses.' arrow>
        <InfoIcon />
      </Tooltip>
      <CrudTable
        dataLabel='Campus Event'
        handleRecordSelection={handleEventSelection}
        rowData={events}
        error={error}
        isLoading={isLoading}
        columnNames={
          nonAdminSecurity ? tableColumns : [
            editColumn,
            ...tableColumns,
          ]
        }
        restrictedRole={nonAdminSecurity}
        createOrEditDialog={campuses && campuses.length && defaultZoom ? 
          <EventFormDialog
            open={dialogType === 'Create' || dialogType === 'Update'}
            campuses={campuses} 
            defaultCenterCoords={defaultCenterCoords as LatLngTuple} 
            defaultZoom={parseInt(defaultZoom?.value)} 
            event={event as CampusEvent}
          /> : <></>}
        deleteDialog={event && event !== null ?
          <DeleteDialog
            open={dialogType === 'Delete'}
            dataLabel='Campus Event'
            handleDelete={async () => {
              const res = await deleteEvent(event.id).unwrap();
              if (res.message.includes('deleted') && event.label === 'Maintenance'){
                event.cap && await deleteCapMessage(event.cap.id);
              }
            }}
          /> :
          <></>
        }
        additionalDialogs={event && event !== null ?
          <EventTriggerDialog
            open={dialogType === 'Trigger'}
            eventId={event.id as unknown as number}
            eventName={event.label}
            outCampus={event.outgoingCampuses}
            eventAlert={event.outgoingAlert as CampusAlert}
          /> : <></>}
      /></div> : <></>);
};

export default Events;