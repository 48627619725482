import { Button, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { BaseDialog, useDialogContext } from '../../Components';
import { getDateString } from '../../Utils/getDateString';
import { Notification } from '../../TypeScript/AppTypes';
import styles from './Notifications.module.css';

export const NotificationsDialog = (props: {
  notification: Notification,
  isOpen: boolean,
}): JSX.Element => {

  const { isOpen, notification } = props;
  const { closeDialog } = useDialogContext();

  return (    
    <BaseDialog 
        open={isOpen} 
        title={'Notification Details'}
        fullWidth={true}
    >
    <>
      <DialogContent className={styles.content}>
        <DialogContentText className={styles.dialogText}>
            <span className={styles.span}>Date: </span> 
            {getDateString(notification.date)}
        </DialogContentText>
        <DialogContentText className={styles.dialogText}>
            <span className={styles.span}>Type: </span>
            {notification.type}
        </DialogContentText>
        <DialogContentText className={styles.dialogText}>
            <span className={styles.span}>Campus: </span> 
            {notification.campus.name}
        </DialogContentText>
        <DialogContentText className={styles.dialogText}>
            <span className={styles.span}>Content: </span> 
            {notification.content}
        </DialogContentText>
       {
        notification.device ? (
          <DialogContentText className={styles.dialogText}>
            <span className={styles.span}>Maintenance Details:</span><br/>
            <span className={styles.details}>Endpoint Name: </span>{notification.device.endpoint_name}<br/>
            <span className={styles.details}>Endpoint IP: </span>{notification.device.endpoint_ip}<br/>
            <span className={styles.details}>Endpoint Status: </span>{notification.device.endpoint_status}<br/> 
          </DialogContentText>
        ) : <></>
       }
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>
            Close
        </Button>
      </DialogActions>
      </>
    </BaseDialog>
  );
};

export default NotificationsDialog;