import { useRef, useState, useMemo } from 'react';
import { LatLngExpression, Icon } from 'leaflet';
import { Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';

interface DraggableMarkerPropsType {
  coords: number[],
  handleCoordsChange: (coords: number[]) => void,
}

const DraggableMarker = ( { coords, handleCoordsChange }: DraggableMarkerPropsType ) => {
  const [ lng, lat ] = coords;
  const [ position, setPosition ] = useState<LatLngExpression>({
    lat,
    lng,
  });
  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker: any = markerRef.current; // eslint-disable-line @typescript-eslint/no-explicit-any
        if (marker != null) {
          const newPostition = marker.getLatLng(); 
          setPosition(newPostition);
          handleCoordsChange([newPostition.lng, newPostition.lat]);
        }
      },
    }),
    [handleCoordsChange],
  );

  return (
    <Marker
    position={position}
    draggable
    icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}
    eventHandlers={eventHandlers}
    ref={markerRef}
/>
  );
};
export default DraggableMarker;