import { DialogContent, DialogActions, Button } from '@mui/material';
import { useDialogContext } from '..';
import { useAppDispatch } from '../../Redux/hooks';
import { dashboardMapSliceActions } from '../../Redux/Slices/DashboardMapSlice';
import { DEFAULT_ZOOM, ZIP_CODE } from '../../Utils/constants';
import BaseDialog from './BaseDialog';

export interface ResetDashboardDialogProps {
  open: boolean,
  settingKey: string | undefined,
  settingLabel: string,
}

const ResetDashboardDialog = (props: ResetDashboardDialogProps): JSX.Element => {

  const { open, settingKey, settingLabel } = props;
  const { closeDialog } = useDialogContext();
  const dispatch = useAppDispatch();

  return (
    <BaseDialog
        open={open}
        title={`Reset ${settingLabel}`}
      >
        <>
          <DialogContent>
            {`You have updated the ${settingLabel} setting. Would you like to clear the current ${settingLabel} on the Dashboard?`}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => closeDialog()}>
              No
            </Button>
            <Button 
              type="submit"
              onClick={() => {
                if (settingKey === ZIP_CODE) {
                  dispatch(dashboardMapSliceActions.setDashboardMapCenter(undefined));
                } if (settingKey === DEFAULT_ZOOM) { 
                  dispatch(dashboardMapSliceActions.setZoomLevel(undefined));
                }
                closeDialog();
              }}
            >
              Yes
          </Button>
        </DialogActions>
      </>
    </BaseDialog>
  );
};

export default ResetDashboardDialog;