import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Campus, CampusRequest, District, CampusEvent, Tag, TagRequest, LoginResponse, LoginRequest, CampusStatus, CampusStatusRequest,
  CapMessage, CapMessageRequest, User, Role, UserRequest, Setting, SettingRequest, Notification, CampusAlert, CampusAlertRequest, 
  CampusEventRequest, Device, DeviceUpdateRequest, LogResponse  } from '../TypeScript/AppTypes';
import { RootState } from './store';
import { LatLngTuple } from 'leaflet';
import { DISTRICT_IDS, ZIP_CODE } from '../Utils/constants';

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: (headers, { getState }) => {
      // If token in the store, use authenticated requests
      const { token, id } = (getState() as RootState).auth;
      if (token && id) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Campus', 'CampusStatus', 'CampusAlert', 'CapMessage', 'User', 'Tag',
    'District', 'Setting', 'Coordinates', 'CampusEvent', 'Device'],
  // Mutation and query endpoints are in typed <ResultType, QueryArg>
  endpoints: (build) => ({
    login: build.mutation<LoginResponse, LoginRequest>({
      query: (credentials) => ({ // query argument `credentials` is of type LoginRequest
        url: 'users/login',
        method: 'POST',
        body: credentials,
      }),
    }),
    newPassword: build.mutation<void, Partial<UserRequest>>({
      query: (updatedUser) => ({
        url: 'users/newPassword',
        method: 'PUT',
        body: updatedUser,
      }),
      // invalidatesTags: (result, error, arg) => [{ type: 'User', id: arg.id }],
    
    }),
    getDevices: build.query<Device[], void>({
      query: () => 'device-info',
      providesTags: (result) => 
        result
          ? [
            ...result.map(({ id, campus }) => ({ type: 'Device' as const, id, campusId: campus !== null ? campus.id : null })), // mapping results
            { type: 'Device', id: 'ALL' }, // abstract tag id for invalidating entire query cache
          ]
          : [{ type: 'Device', id: 'ALL' }], // on no results, create abstract tag id 
    }),
    editDevice: build.mutation<Device, Partial<DeviceUpdateRequest>>({
      query: ({ id, ...patch }) => ({
        url: `device-info/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Device', id: arg.id }],
    }),
    markDevicesAsResolved: build.mutation<{ message: string }, string>({
      query: (id) => ({
        url: `device-info/resolve/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Device'],
    }),
    getCampuses: build.query<Campus[], void>({
      query: () => 'campus',
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: 'Campus' as const, id })), // mapping results
            { type: 'Campus', id: 'ALL' }, // abstract tag id for invalidating entire query cache
          ]
          : [{ type: 'Campus', id: 'ALL' }], // on no results, create abstract tag id 
    }),
    addCampus: build.mutation<Campus, Partial<CampusRequest>>({
      query(body) {
        return {
          url: 'campus',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: 'Campus', id: 'ALL' }], // invalidate abstract tag id, refetch all
    }),
    editCampus: build.mutation<Campus, Partial<CampusRequest>>({
      query: ({ id, ...patch }) => ({
        url: `campus/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Campus', id: arg.id }, { type: 'Device', campusId: arg.id },
      ], // invalidate only one
    }),
    deleteCampus: build.mutation<{ success: boolean; id: string }, string>({
      query: (id) => ({
        url: `campus/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Campus', id }],
    }),
    getActivationLogs: build.query<LogResponse, void>({
      query: () => ({
        url: 'campus/activationlogs',
        method: 'GET',
      }),
    }),
    resetCampuses: build.mutation<Campus[], void>({
      query: () => ({
        url: 'campus/reset',
        method: 'POST',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Campus', id: 'ALL' }],
    }),
    resetSingleCampus: build.mutation<{ id: string }, string>({
      query: (id) => ({
        url: `campus/reset/${id}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Campus', id }],
    }),
    getCapMessages: build.query<CapMessage[], void>({
      query: () => 'cap',
      providesTags: (result, error, arg) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: 'CapMessage' as const, id })),
            { type: 'CapMessage', id: 'ALL' },
          ]
          : [{ type: 'CapMessage', id: 'ALL' }],
    }),
    getEvents: build.query<CampusEvent[], void>({
      query: () => 'events',
      providesTags: (result, error, arg) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: 'CampusEvent' as const, id })),
            { type: 'CampusEvent', id: 'ALL' },
          ]
          : [{ type: 'CampusEvent', id: 'ALL' }],
    }),
    addEvent: build.mutation<CampusEvent, CampusEventRequest>({
      query: (body) => ({
        url: 'events',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'CampusEvent', id: 'ALL' }],
    }),
    editEvent: build.mutation<CampusEvent, CampusEventRequest>({
      query: ({ id, ...patch }) => ({
        url: `events/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'CampusEvent', id: arg.id }],
    }),
    deleteEvent: build.mutation<{ message: string }, string>({
      query: (id) => ({
        url: `events/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'CampusEvent', id }],
    }),
    addCapMessage: build.mutation<CapMessage, Partial<CapMessage>>({
      query: (body) => ({
        url: 'cap',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'CapMessage', id: 'ALL' }],
    }),
    editCapMessage: build.mutation<CapMessage, Partial<CapMessageRequest>>({
      query: ({ id, ...patch }) => ({
        url: `cap/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'CapMessage', id: arg.id }, { type: 'CampusEvent', id: 'ALL' }],
    }),
    deleteCapMessage: build.mutation<CapMessage, number>({
      query: (id) => ({
        url: `cap/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'CapMessage', id }],
    }),
    getCampusAlerts: build.query<CampusAlert[], void>({
      query: () => 'alerts',
      providesTags: (result, error, arg) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: 'CampusAlert' as const, id })),
            { type: 'CampusAlert', id: 'ALL' },
          ]
          : [{ type: 'CampusAlert', id: 'ALL' }],
    }),
    addCampusAlert: build.mutation<CampusAlert, Partial<CampusAlert>>({
      query: (newCampusAlert) => ({
        url: 'alerts',
        method: 'POST',
        body: newCampusAlert,
      }),
      invalidatesTags: [{ type: 'CampusAlert', id: 'ALL' }],
    }),
    editCampusAlert: build.mutation<CampusAlert, Partial<CampusAlertRequest>>({
      query: ({ id, ...patch }) => ({
        url: `alerts/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'CampusAlert', id: arg.id }, { type: 'CampusEvent', id: 'ALL' }],
    }),
    deleteCampusAlert: build.mutation<CampusAlert, string>({
      query: (id) => ({
        url: `alerts/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'CampusAlert', id }],
    }),
    getCampusStatuses: build.query<CampusStatus[], void>({
      query: () => 'status',
      providesTags: (result, error, arg) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: 'CampusStatus' as const, id })),
            { type: 'CampusStatus', id: 'ALL' },
          ]
          : [{ type: 'CampusStatus', id: 'ALL' }],
    }),
    addCampusStatus: build.mutation<CampusStatus, Partial<CampusStatus>>({
      query: (newCampusStatus) => ({
        url: 'status',
        method: 'POST',
        body: newCampusStatus,
      }),
      invalidatesTags: [{ type: 'CampusStatus', id: 'ALL' }],
    }),
    editCampusStatus: build.mutation<CampusStatus, Partial<CampusStatusRequest>>({
      query: ({ id, ...patch }) => ({
        url: `status/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'CampusStatus', id: arg.id }, { type: 'Campus', id: 'ALL' }, { type: 'CampusEvent', id: 'ALL' },
      ],
    }),
    deleteCampusStatus: build.mutation<CampusStatus, string>({
      query: (id) => ({
        url: `status/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'CampusStatus', id }, { type: 'Campus', id: 'ALL' }, { type: 'CampusEvent', id: 'ALL' },
      ],
    }),
    getUsers: build.query<User[], void>({
      query: () => 'users', // no query arg (void)
      providesTags: (result, error, arg) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: 'User' as const, id })),
            { type: 'User', id: 'ALL' },
          ]
          : [{ type: 'User', id: 'ALL' }],
    }),
    addUser: build.mutation<User, Partial<UserRequest>>({
      query: (newUser) => ({
        url: 'users',
        method: 'POST',
        body: newUser,
      }),
      invalidatesTags: [{ type: 'User', id: 'ALL' }],
    }),
    editUser: build.mutation<User, Partial<UserRequest>>({
      query: ({ id, ...updatedUser }) => ({
        url: `users/${id}`,
        method: 'PUT',
        body: updatedUser,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'User', id: arg.id }, { type: 'CampusEvent', id: 'ALL' }],
    }),
    deleteUser: build.mutation<User, string>({
      query: (id) => ({
        url: `users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'User', id }],
    }),
    getTags: build.query<Tag[], void>({
      query: () => 'tags',
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: 'Tag' as const, id })), 
            { type: 'Tag', id: 'ALL' },
          ]
          : [{ type: 'Tag', id: 'ALL' }],
    }),
    addTags: build.mutation<Tag, Partial<TagRequest>>({
      query: (body) => ({
        url: 'tags',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Tag', id: 'ALL' }],
    }),
    editTags: build.mutation<Tag, Partial<TagRequest>>({
      query: ({ id, ...patch }) => ({
        url: `tags/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Tag', id: arg.id }, { type: 'Campus', id: 'ALL' }, 
      ],
    }),
    deleteTags: build.mutation<Tag, string>({
      query: (id) => ({
        url: `tags/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'Tag', id }, { type: 'Campus', id: 'ALL' },
      ],
    }),
    getSettings: build.query<Setting[], void>({
      query: () => 'settings',
      providesTags: (result, error, arg) =>
        result
          ? [
            ...result.map(({ key }) => ({ type: 'Setting' as const, id: key })),
            { type: 'Setting', id: 'ALL' },
          ]
          : [{ type: 'Setting', id: 'ALL' }],
    }),
    getSetting: build.query<Setting, string>({
      query: (key) => `settings?key=${key}`,
      providesTags: (result, error, key) => [{ type: 'Setting', id: key }],
    }),
    editSetting: build.mutation<Setting, SettingRequest>({
      // we use setting.key as our immutable identifier with server calls
      query: ({ id, key, ...updatedSetting }) => ({
        url: `settings/${id}`,
        method: 'PUT',
        body: updatedSetting,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Setting', id: arg.key }, { type: 'Coordinates', id: arg.key },
      ],
    }),
    getZipCodeCoordinates: build.query<LatLngTuple, void>({
      query: () => 'settings/mapcenter',
      providesTags: (result, error) => [{ type: 'Coordinates', id: ZIP_CODE }],
      transformResponse: (response: { coords: number[] }): LatLngTuple => {
        return response.coords.reverse() as LatLngTuple;
      },
    }),
    getDistrictIds: build.query<string[], void>({
      query: () => `settings?key=${DISTRICT_IDS}`,
      providesTags: (result, error) => [{ type: 'Setting', id: DISTRICT_IDS }],
      transformResponse: (response: Setting): string[] => {
        return response.value.split(',').filter((id) => id !== '').map((id) => id.trim());
      },
    }),
    getDistricts: build.query<District[], void>({
      query: () => 'districts',
      providesTags: ['District'],
    }),
    getNotifications: build.query<Notification[], void>({
      query: () => 'notifications',
    }),
    getNotificationLogs: build.query<LogResponse, void>({
      query: () => ({
        url: 'notifications/notificationLogs',
        method: 'GET',
      }),
    }),
    getRoles: build.query<Role[], void>({
      query: () => 'roles',
    }),
    addCampusCsv: build.mutation<string, FormData>({
      query(body) {
        return {
          url: 'campus/csv',
          method: 'POST',
          body,
        };
      },
      transformResponse: (response: { message: string }): string => response.message,
      invalidatesTags: [{ type: 'Campus', id: 'ALL' }], // invalidate abstract tag id, refetch all
    }),
    addDistrictsCsv: build.mutation<string, FormData>({
      query(body) {
        return {
          url: 'districts/csv',
          method: 'POST',
          body,
        };
      },
      transformResponse: (response: { message: string }): string => response.message,
      invalidatesTags: ['District'], // invalidate general tag, refetch all
    }),
  }),
});

export const {
  useLoginMutation,
  useNewPasswordMutation,
  useGetCampusesQuery,
  useAddCampusMutation,
  useEditCampusMutation,
  useDeleteCampusMutation,
  useResetCampusesMutation,
  useResetSingleCampusMutation,
  useGetCapMessagesQuery,
  useAddCapMessageMutation,
  useEditCapMessageMutation,
  useDeleteCapMessageMutation,
  useGetActivationLogsQuery,
  useGetCampusAlertsQuery,
  useAddCampusAlertMutation,
  useEditCampusAlertMutation,
  useDeleteCampusAlertMutation,
  useGetCampusStatusesQuery,
  useAddCampusStatusMutation,
  useEditCampusStatusMutation,
  useDeleteCampusStatusMutation,
  useGetUsersQuery,
  useAddUserMutation,
  useEditUserMutation,
  useDeleteUserMutation,
  useGetTagsQuery,
  useAddTagsMutation,
  useEditTagsMutation,
  useDeleteTagsMutation,
  useGetSettingsQuery,
  useGetSettingQuery,
  useEditSettingMutation,
  useGetZipCodeCoordinatesQuery,
  useGetDistrictIdsQuery,
  useGetDistrictsQuery,
  useGetNotificationsQuery,
  useGetNotificationLogsQuery,
  useGetRolesQuery,
  useAddCampusCsvMutation,
  useAddDistrictsCsvMutation,
  useGetEventsQuery,
  useAddEventMutation,
  useEditEventMutation,
  useDeleteEventMutation,
  useGetDevicesQuery,
  useEditDeviceMutation,
  useMarkDevicesAsResolvedMutation,
} = api;

