/*eslint-disable react-hooks/exhaustive-deps*/
import { Button, Stack } from '@mui/material';
import { LatLngExpression, LatLngTuple, FeatureGroup } from 'leaflet';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Circle  } from 'react-leaflet';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Campus, CampusAlert, CampusStatus, MapState } from '../../TypeScript/AppTypes';
import { EventDialogMarker } from './EventDialogMarker';
import { CampusMap } from '../../Leaflet';
import styles from './EventDialogMap.module.css';

interface EventDialogMapProps {
  campuses: Campus[],
  defaultCenterCoords: LatLngTuple,
  defaultZoom: number,
  outgoingCampuses: Campus[],
  incomingCampuses: Campus[],
  setOutgoingCampuses: Dispatch<SetStateAction<any>> //eslint-disable-line
  setIncomingCampuses: Dispatch<SetStateAction<any>> //eslint-disable-line
  newEventStatus: CampusStatus,
  newEventAlert: CampusAlert,
  exampleRadius: number,
  showExample: boolean,
  exampleCampus: Campus,
}

export const EventDialogMap = (props: EventDialogMapProps) => {
  const { campuses, defaultCenterCoords, defaultZoom, outgoingCampuses, incomingCampuses, showExample,
    setOutgoingCampuses, setIncomingCampuses, newEventStatus, newEventAlert, exampleRadius, exampleCampus } = props;
  const [mapState, setMapState] = useState<MapState>({
    bounds: null,
    centerPnt: null,
    radius: 0,
    polyPnts: [],
  });
  const [selectedEventCampuses, setSelectedEventCampuses] = useState<Campus[]>([]);
  const [incomingButtonDisabled, setIncomingButtonDisabled] = useState<boolean>(true);
  const [outgoingButtonDisabled, setOutgoingButtonDisabled] = useState<boolean>(true);
  const [clearAllBtnDisabled, setClearAllBtnDisabled] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [mapLayer, setMapLayer] = useState<FeatureGroup | null>(null);
  
  const handleEventCampusSelectionChange = (selection: Campus[]) => setSelectedEventCampuses(selection);
  const handleMapStateChange = (newMapState: MapState) => setMapState(newMapState);
  const handleMapLayerChange = (newLayer: FeatureGroup | null) => setMapLayer(newLayer);

  useEffect(() => {
    if (selectedEventCampuses.length >= 1){
      setIncomingButtonDisabled(false);
      setOutgoingButtonDisabled(false);
      setClearAllBtnDisabled(false);
    } else {
      setClearAllBtnDisabled(true);
      setIncomingButtonDisabled(true);
      setOutgoingButtonDisabled(true);
    }
  }, [selectedEventCampuses]);

  return (
    <CampusMap
      mapType='event'
      fullsize={false}
      defaultCenterCoords={defaultCenterCoords}
      defaultZoom={defaultZoom}
      campuses={campuses}
      selectedCampuses={selectedEventCampuses}
      handleCampusSelectionChange={handleEventCampusSelectionChange}
      mapState={mapState}
      handleMapStateChange={handleMapStateChange}
      handleMapLayerChange={handleMapLayerChange}
      clearAllBtnDisabled={clearAllBtnDisabled}
      markers={campuses.map(c => {
        const lat = c.geom?.coordinates[1];
        const lng = c.geom?.coordinates[0];
        let color = 'white', borderColor = 'black';
        const isOutgoingCampuses = outgoingCampuses.find(campus => campus.id === c.id) !== undefined;
        const isIncomingCampuses = incomingCampuses.find(campus => campus.id === c.id) !== undefined; 
        if (isIncomingCampuses && isOutgoingCampuses ) {
          color = 'black';
        } else if (isIncomingCampuses && newEventStatus && newEventStatus.color && newEventStatus.borderColor) {
          color = newEventStatus.color;
          borderColor = newEventStatus.borderColor;
        } else if (isOutgoingCampuses && newEventAlert && newEventAlert.color && newEventAlert.borderColor) {
          color = newEventAlert.color;
          borderColor = newEventAlert.borderColor;
        }
        return (
          <EventDialogMarker 
            key={c.id}
            id={c.id as string} 
            coords={[lat, lng] as LatLngExpression} 
            isSelected={selectedEventCampuses.includes(c)} 
            borderColor={borderColor} 
            color={color}
            campuses={campuses} 
            selectedCampuses={selectedEventCampuses} 
            handleCampusSelectionChange={handleEventCampusSelectionChange} 
          />);
      })}
      leftButtons={<>
      <Button
          size='small'
          className={styles.selectAllCampusButton}
          variant='contained' 
          disabled={ selectedEventCampuses.length === campuses.length }
          onClick={() => {
            setSelectedEventCampuses([...campuses]);
          }}
          >
          <AddCircleOutlineIcon className={styles.buttonIcon}/>
          Select All 
        </Button>
      </>}
      rightButtons={<>
      <Stack direction='row' spacing={1}>
          <Button
          size='small'
          className={incomingButtonDisabled ? styles.incomingButtonDisabled : styles.buttonIncoming}
          variant='contained' 
          disabled={incomingButtonDisabled}
          onClick={() => {
            const addIncomingArray = Array.from(new Set([...incomingCampuses, ...selectedEventCampuses]));
            setIncomingCampuses([...addIncomingArray]);
          }}
          >
          <AddIcon className={styles.buttonIcon}/>
          Incoming 
        </Button>
        <Button 
          size='small'
          className={outgoingButtonDisabled ? styles.outgoingButtonDisabled : styles.buttonOutgoing}
          variant='contained' 
          disabled={outgoingButtonDisabled}
          onClick={() => {
            const outgoingArray = Array.from(new Set([...outgoingCampuses, ...selectedEventCampuses]));
            setOutgoingCampuses([...outgoingArray]);
          }}
          >
          <AddIcon className={styles.buttonIcon}/> 
          Outgoing
        </Button>
      </Stack>
      <Stack direction='row' spacing={1}>
          <Button 
          size='small'
          className={incomingButtonDisabled ? styles.incomingButtonDisabled : styles.buttonIncoming}
          variant='contained' 
          disabled={incomingButtonDisabled}
          onClick={() => {
            const selectedIds = selectedEventCampuses.map((campus) => campus.id);
            const reducedIncomingArray = incomingCampuses.filter(campus => !selectedIds.includes(campus.id));
            setIncomingCampuses([...reducedIncomingArray]);
          }}
          >
          <RemoveIcon className={styles.buttonIcon}/>
          Incoming 
        </Button>
        <Button 
          size='small'
          className={outgoingButtonDisabled ? styles.outgoingButtonDisabled : styles.buttonOutgoing}
          variant='contained' 
          disabled={outgoingButtonDisabled}
          onClick={() => {
            const selectedIds = selectedEventCampuses.map((campus) => campus.id);
            const reducedOutgoingArray = outgoingCampuses.filter(campus => !selectedIds.includes(campus.id));
            setOutgoingCampuses([...reducedOutgoingArray]);
          }}
          >
          <RemoveIcon className={styles.buttonIcon}/> 
          Outgoing 
        </Button>
      </Stack>
      </>
      }
      additionalShapes={showExample && exampleRadius !== 0 && campuses[0] && campuses[0].geom ?
      <Circle
        center={exampleCampus && exampleCampus.geom ? [exampleCampus.geom.coordinates[1], exampleCampus.geom.coordinates[0]] : [campuses[0].geom.coordinates[1], campuses[0].geom.coordinates[0]]}
        radius={exampleRadius * 1609.34}
        color='black'
      /> : null}
    />
  );
};
