/**
 * Settings Labels (immutable match with database)
 */
export const ZIP_CODE = 'ZipCode';
export const DEFAULT_ZOOM = 'DefaultZoom';
export const NETWORK_TIMEOUT = 'NetworkTimeout';
export const DISTRICT_IDS = 'DistrictIds';
export const REPORTER_TIMEOUT = 'ReporterTimeout';
export const AUTO_LOGOUT = 'AutoLogout';

/**
 * URLs 
 */

export const ve6025Link = (link: string | null, username: string | null, password: string) => 
  `${link}/legacy/server.php?module=login&callback=1&userkey=1&remote=1&user=${username}&pass=${password}`;