/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box, Button, Paper, Stack } from '@mui/material';
import { useState } from 'react';
import { TextInput, Dropdown, DropdownPicker, useToastContext } from '../../Components';
import {
  useAddCapMessageMutation,
  useAddEventMutation,
  useEditCapMessageMutation,
  useEditEventMutation,
  useGetCampusAlertsQuery,
  useGetCampusesQuery,
  useGetCampusStatusesQuery,
} from '../../Redux/api';
import { 
  CampusEvent, CampusEventRequest, CapMessage, MaintEventRequest,
} from '../../TypeScript/AppTypes';
import { dynamicMaintenanceValueList } from '../../Utils/dynamicCapVariables';
import { getErrorMessage } from '../../Utils/errorHandling';
import { trimRequest } from '../../Utils/trimRequest';
import { blankCapMessageInput } from '../CapMessages/CapMessageFormDialog';
import styles from './DeviceMaintenance.module.css';

interface MaintenanceFormProps {
  maintenanceEvent?: CampusEvent | undefined;
  maintenanceCap?: CapMessage
  events: CampusEvent[],
  caps: CapMessage[],
}

const MaintenanceForm = (props: MaintenanceFormProps) => {
  const { maintenanceEvent, maintenanceCap } = props;
  const { data: campusAlerts } = useGetCampusAlertsQuery();
  const { data: campuses } = useGetCampusesQuery();
  const { data: statuses } = useGetCampusStatusesQuery(); 
  const [addCapMessage] = useAddCapMessageMutation();
  const [editCapMessage] = useEditCapMessageMutation();
  const [addEvent] = useAddEventMutation();
  const [editEvent] = useEditEventMutation();
  const { setSuccessToast, setErrorToast } = useToastContext();

  const [reportRecipient, setReportRecipient] = useState<string | null>(
    maintenanceEvent && maintenanceEvent.outgoingCampuses.length > 0 ? 
      maintenanceEvent.outgoingCampuses[0].name 
      : null);
  const [reportingAlert, setReportingAlert] = useState<string | null>(
    maintenanceEvent && maintenanceEvent.outgoingAlert ? 
      maintenanceEvent.outgoingAlert.label : 
      null);
  const [description, setDescription] = useState(maintenanceCap && maintenanceCap.description ? maintenanceCap.description : '');
  const [maintErrors, setMaintErrors] = useState<{
    headline: boolean,
    instruction: boolean,
    description: boolean,
    reportRecipient: boolean
  }>({
    headline: false,
    instruction: false,
    description: false,
    reportRecipient: false,
  });

  const validateRequest = (input: MaintEventRequest) => {
    let requestType = 'event';
    let isHeadlineInvalid = false;
    let isInstructionInvalid = false;
    let isDescriptionInvalid = false;
    let isReportRecipientInvalid = false;
    
    if (!input.reportRecipient) isReportRecipientInvalid = true;
    
    if (input.headline?.length || input.instruction?.length || input.description?.length || input.expires! > 0){
      requestType = 'capAndEvent';
      if (!input.headline?.length) isHeadlineInvalid = true;
      if (!input.instruction?.length) isInstructionInvalid = true;
      if (!input.description?.length) isDescriptionInvalid = true;
    }

    setMaintErrors({
      headline: isHeadlineInvalid,
      instruction: isInstructionInvalid,
      description: isDescriptionInvalid,
      reportRecipient: isReportRecipientInvalid,
    });

    if (requestType === 'event' && isReportRecipientInvalid) {
      return 'invalid';
    } else if (requestType === 'capAndEvent' && (isHeadlineInvalid || isInstructionInvalid || isDescriptionInvalid || isReportRecipientInvalid)) {
      return 'invalid';
    }
    return requestType;
  };

  return (campuses && campusAlerts && statuses ?
    <Box className={styles.formWrapper}>
      <Paper className={styles.formContainer}>
        <form
          onSubmit={async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const fieldValues = Object.fromEntries(formData.entries());
  
            // Get data from form, then add values from controlled multi-select field (tags) 
            // and match ids with value of uncontrolled dropdowns (status and district)
            const maintBody: MaintEventRequest = trimRequest(fieldValues) as unknown as MaintEventRequest;
            const maintenanceStatus = statuses && parseInt(statuses.find(s => s.label === 'Maintenance')?.id as string);
            const isValidRequest = validateRequest(maintBody);

            // Maintenance Event
            if (reportRecipient) {
              maintBody.reportRecipient = [campuses.filter(
                c => c.name === reportRecipient,
              )[0].id as unknown as number];
            }
            if (reportingAlert) {
              maintBody.reportingAlert = campusAlerts.filter(
                a => a.label === reportingAlert,
              )[0].id as unknown as number;
            } else maintBody.reportingAlert = null;

            // Maintenance Cap
            if (!maintBody.expires || maintBody.expires < 0) maintBody.expires = 0;
            const reporterName = /REPORTER_NAME/g;
            const reporterType = /REPORTER_TYPE/g;
            const endpoingName = /ENDPOINT_NAME/g;
            const endpointStatus = /ENDPOINT_STATUS/g;
            maintBody.description = maintBody.description?.replace(reporterName, '{REPORTER_NAME}');
            maintBody.description = maintBody.description?.replace(reporterType, '{REPORTER_TYPE}');
            maintBody.description = maintBody.description?.replace(endpoingName, '{ENDPOINT_NAME}');
            maintBody.description = maintBody.description?.replace(endpointStatus, '{ENDPOINT_STATUS}');

            let capRes: CapMessage = { id: undefined } as unknown as CapMessage;
            
            try {
              if (isValidRequest !== 'invalid') {
                const eventData: CampusEventRequest = {
                  label: 'Maintenance',
                  incomingCampuses: [],
                  incomingStatus: maintenanceStatus,
                  outgoingAlert: maintBody.reportingAlert,
                  radius: 0,
                };

                if (maintenanceCap) {
                  capRes = await editCapMessage({ 
                    id: maintenanceCap.id, 
                    headline: maintBody.headline,
                    instruction: maintBody.instruction,
                    description: maintBody.description,
                    expires: maintBody.expires,
                  }).unwrap();                     
                } else if (isValidRequest === 'capAndEvent') {
                  capRes = await addCapMessage({  
                    label: 'Maintenance',
                    headline: maintBody.headline,
                    instruction: maintBody.instruction,
                    description: maintBody.description,
                    expires: maintBody.expires,
                    dynamic: true,
                  }).unwrap();
                }
              
                if (reportRecipient) eventData.outgoingCampuses = maintBody.reportRecipient;

                if (capRes.id) eventData.cap = capRes.id;

                if (maintenanceEvent){
                  await editEvent({ id: maintenanceEvent.id, ...eventData }).unwrap();
                  setSuccessToast('Maintenance event successfully updated');
                } else {
                  await addEvent(eventData).unwrap();
                  setSuccessToast('Maintenance event successfully created');
                }
              } 
            } catch (err: unknown) {
              setErrorToast(getErrorMessage(err));
            }
          }}>
            <h3>Maintenance Event</h3>
          <Stack direction="column">
            <TextInput
              id="maintenance"
              name="name"
              label='Maintenance Event Name'
              required
              defaultValue={'Maintenance'}
              disabled
              subtext="Maintenance event label locked"
            />
            <Dropdown
              id="reportRecipient"
              name="reportRecipient"
              label="Report Recipient"
              required
              options={campuses.map(c => c.name ? c.name : '').filter(n => n !== '')}
              value={reportRecipient}
              onChange={(e, newValue) => {
                const foundCampus = campuses && campuses.filter((c) => c.name === newValue)[0];
                foundCampus ? setReportRecipient(foundCampus.name) : setReportRecipient(null);
              }}
              error={maintErrors.reportRecipient}
              subtext="Sets receiving campus"
            />
            <Dropdown
              id="reportingAlert"
              name="reportingAlert"
              label="Reporting Alert"
              options={campusAlerts.map(a => a.label ? a.label : '').filter(l => l !== '')}
              value={reportingAlert}
              onChange={(e, newValue) => {
                const foundAlert = campusAlerts && campusAlerts.filter((a) => a.label === newValue)[0];
                foundAlert ? setReportingAlert(foundAlert.label) : setReportingAlert(null);
              }}
              subtext="Alert that will send when event is triggered"
            />
            <h3>Maintenance CAP Message</h3>
            <TextInput
              id="label"
              name="label"
              label="Label"
              defaultValue={'Maintenance'}
              disabled
              subtext="CAP message label"
            />
            <TextInput
              id="headline"
              name="headline"
              label="Headline"
              error={maintErrors.headline}
              defaultValue={maintenanceCap ? maintenanceCap.headline : blankCapMessageInput.headline}
              subtext="CAP message headline"
              helperText='Headling required'
            />
            <TextInput
              id="instruction"
              name="instruction"
              label="Instruction"
              error={maintErrors.instruction}
              defaultValue={maintenanceCap ? maintenanceCap.instruction : blankCapMessageInput.instruction}
              subtext="CAP message instruction"
              helperText='Instruction Required'
            />
            <TextInput
              id="description"
              name="description"
              label="Description"
              value={description}
              defaultValue={undefined}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              helperText="Description required"
              subtext="CAP message description"
              error={maintErrors.description}
              inputProps={{
                endAdornment: (
                  <DropdownPicker
                    label="Add Dynamic Value"
                    options={dynamicMaintenanceValueList.map(
                      (val) => val.showValue,
                    )}
                    onChange={(e) => {
                      let newPlaceholder = e.target.value;
                      if (!description.endsWith(' ') && description.length)
                        newPlaceholder = ' ' + newPlaceholder;
                      setDescription(description + newPlaceholder);
                    }}
                  />
                ),
              }}
            />
            <TextInput
              id="expires"
              name="expires"
              label="Expires"
              type="number"
              defaultValue={maintenanceCap ? maintenanceCap.expires : blankCapMessageInput.expires}
              subtext="CAP message duration in seconds"
            />
          </Stack>
          <Button className={styles.submit} variant="contained" type='submit'>
            Submit
          </Button>
        </form>
      </Paper>
    </Box>
    : <></>);
};

export default MaintenanceForm;