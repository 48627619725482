import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { LatLngExpression, LatLngTuple } from 'leaflet';
import { Box, Button, Divider, IconButton, InputBase, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {
  useGetZipCodeCoordinatesQuery, useGetSettingQuery, useGetCampusesQuery, useGetDevicesQuery,
  useGetCapMessagesQuery, useGetEventsQuery, useGetCampusStatusesQuery, useMarkDevicesAsResolvedMutation, api, useGetTagsQuery,
} from '../../Redux/api';
import { useAppDispatch, useAppSelector } from '../../Redux/hooks';
import { DEFAULT_ZOOM, ve6025Link } from '../../Utils/constants';
import { DeviceMaintenanceMarkers } from './DeviceMaintenanceMarkers';
import { Campus, Device, Tag } from '../../TypeScript/AppTypes';
import { Dropdown, useToastContext } from '../../Components';
import { getErrorMessage } from '../../Utils/errorHandling';
import Map from '../../Leaflet/Map';
import DeviceTable from './DeviceTable';
import DeviceTabPanel from './DeviceTabPanel';
import MaintenanceForm from './MaintenanceForm';
import styles from './DeviceMaintenance.module.css';
import tableStyles from '../Dashboard/DashboardTable.module.css';

const maintenanceTitles = [
  'Maintenance Items',
  'Maintenance History',
  'Outgoing Maintenance Event',
];

const DeviceMaintenance = () => {
  const { data: campuses } = useGetCampusesQuery();
  const { data: mapCenter } = useGetZipCodeCoordinatesQuery();
  const { data: defaultZoom } = useGetSettingQuery(DEFAULT_ZOOM);
  const { data: events } = useGetEventsQuery();
  const { data: caps } = useGetCapMessagesQuery();
  const { data: devices, error } = useGetDevicesQuery();
  const { data: statuses } = useGetCampusStatusesQuery();
  const { data: tags } = useGetTagsQuery();
  const { setInfoToast, setErrorToast } = useToastContext();
  const { token, ve6025Username, ve6025Password } = useAppSelector((store) => store.auth);
  const [tabLocation, setTabLocation] = useState(0);
  const [selectedCampus, setSelectedCampus] = useState<Campus | undefined>();
  const [filteredDevices, setFilteredDevices] = useState<Device[]>([]);
  const [campusSearchTerm, setCampusSearchTerm] = useState('');
  const [mapCampuses, setMapCampuses] = useState<Campus[]>([]);
  const [tagSearch, setTagSearch] = useState<Tag[]>([]);
  const [tagIds, setTagIds] = useState<string[]>([]);
  const [markDevicesAsResolved] = useMarkDevicesAsResolvedMutation();
  const dispatch = useAppDispatch();
  const maintenanceCap = caps && caps.find(cap => cap.label === 'Maintenance');
  const maintenanceEvent = events && events.find(e => e.label === 'Maintenance');
  const maintStatus = statuses && statuses.filter(s => s.label === 'Maintenance')[0];

  const compareTagArrays = useCallback((tagsArr: Tag[]) => {
    const campusHasTags = [];
    tagsArr.forEach((tag) => {
      if (tagIds?.includes(tag.id)) {
        campusHasTags.push(tag.id);
      }
    });
    return campusHasTags.length === tagSearch.length;
  }, [tagIds, tagSearch.length]);

  const selectedCampusDisplayFields = [
    { field: 'name', headerName: 'Campus Name', width: 275 },
    { field: 'address', headerName: 'Address', width: 100 },
    { field: 'city', headerName: 'City', width: 120 },
    { field: 'state', headerName: 'State', width: 75 },
    { field: 'telephone', headerName: 'Phone Number', width: 150 },
    { field: 'district', headerName: 'Campus District', width: 275 },
  ];

  useEffect(() => {
    if (devices) {
      const devCopy = [...devices].reverse();
      let filtered: Device[] = [];
      if (selectedCampus) {
        filtered = devCopy.filter((d) => {
          if (d.campus) return d.campus.id === selectedCampus.id;
          return false;
        }) as Device[];
      } else {
        filtered = devCopy.filter((dev) => {
          if (dev.campus) return dev.campus.name?.toLowerCase().includes(
            campusSearchTerm.toLowerCase()) || dev.campus.address?.toLowerCase().includes(campusSearchTerm.toLowerCase(),
          );
          return false;
        });
      }
      if (tabLocation === 0) filtered = filtered.filter((device) => device.maintenance_status !== 'Resolved');
      if (tagSearch && tagIds.length) filtered = filtered.filter(d => d.campus ? compareTagArrays(d.campus.tags) : null);
      setFilteredDevices(filtered);
    }

  }, [devices, selectedCampus, tabLocation, campusSearchTerm, campuses, tagIds, tagSearch, compareTagArrays]);


  useEffect(() => {
    if (tags && tagSearch) {
      setTagIds(tagSearch.map(t => t.id));
    }
    if (campuses) {
      const filteredCampuses = campuses.filter(
        (campus) => campus.name?.toLowerCase().includes(
          campusSearchTerm.toLowerCase()) || campus.address?.toLowerCase().includes(campusSearchTerm.toLowerCase(),
        ),
      );
      if (selectedCampus && !filteredCampuses.includes(selectedCampus)) filteredCampuses.push(selectedCampus);
      setMapCampuses(filteredCampuses);
    }

  }, [selectedCampus, campuses, campusSearchTerm, tags, tagSearch]);


  const handleCampusSelectionChange = (selection: Campus) => {
    if (selectedCampus === selection) {
      setSelectedCampus(undefined);
    } else {
      console.log(selection);
      setSelectedCampus(selection);
    }

  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabLocation(newValue);
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const downloadFile = (): void => {
    let query = '?active=';
    if (tabLocation === 0) query += 'true';
    if (selectedCampus) query += `&campus=${selectedCampus.id}`;
    axios({
      url: `/api/device-info/csv/${query}`,
      method: 'GET',
      responseType: 'blob',
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      if (res.status !== 200) return setInfoToast('No maintenance items to export');
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'export.csv');
      document.body.appendChild(link);
      return link.click();
    }).catch((err) => {
      return setInfoToast('No maintenance items to export');
    });
  };

  const handle6025Link = async (link: string) => {
    let username, attempt;
    try {
      if (selectedCampus && selectedCampus.ve6025_username && selectedCampus.ve6025_password) {
        attempt = selectedCampus.ve6025_password;
        username = selectedCampus.ve6025_username;
      } else {
        attempt = ve6025Password;
        username = ve6025Username;
      }
      const { data } = await axios.post('/api/campus/ve6025', { attempt }, { headers: { Authorization: `Bearer ${token}` } });
      window.open(ve6025Link(link, username, data.result));
    } catch (e: unknown) {
      setErrorToast(getErrorMessage(e));
    }
  };

  return (campuses && campuses.length && defaultZoom && mapCenter ?
    <>
      <Map
        mapCenter={mapCenter as LatLngTuple}
        mapZoom={parseInt(defaultZoom.value, 10)}
        fullsize={false}>
        <>
          {mapCampuses.filter(c => c.tags ? compareTagArrays(c.tags) : null).map(c => {
            const lat = c.geom?.coordinates[1];
            const lng = c.geom?.coordinates[0];
            return (
              <DeviceMaintenanceMarkers
                key={c.id}
                id={c.id}
                coords={[lat, lng] as LatLngExpression}
                isSelected={c.id === selectedCampus?.id}
                borderColor={c.devices.length ?
                  maintStatus?.borderColor as string : 'black'}
                color={c.devices.length ?
                  maintStatus?.color as string : 'white'}
                campuses={campuses}
                selectedCampus={selectedCampus as Campus}
                handleCampusSelectionChange={handleCampusSelectionChange} />
            );
          },
          )}</>
      </Map>
      <div className={styles.headingAndButtons}>
        <div className={styles.maintenanceCampusSelect}>
          <h1 className={styles.headerName}>{maintenanceTitles[tabLocation]}</h1>
          {
            tabLocation !== 2 ? (
              <>
                <Paper
                  component="form"
                  sx={{ p: '5px 5px', display: 'flex', alignItems: 'center', height: 65, width: 700 }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search Campuses"
                    inputProps={{ 'aria-label': 'search campuses' }}
                    onChange={(e) => { setCampusSearchTerm(e.target.value); }}
                    value={campusSearchTerm}
                  />
                  <Divider />
                  <div style={{ width: 350 }}>
                    {tags && <Dropdown
                      id="tags" name="tags" label="Search by tag"
                      isMultiSelect={true}
                      value={tagSearch.map(t => t.name ? t.name : '').filter(n => n !== '')}
                      options={tags.map(t => t.name ? t.name : '').filter(n => n !== '')}
                      onChange={(e, newValue) => {
                        const newTagArray: Tag[] = tags.filter(t => t.name && newValue?.includes(t.name));
                        setTagSearch(newTagArray);
                      }}
                    />}</div>
                  <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </>
            ) : <></>
          }
        </div>
        {
          selectedCampus &&
          <TableContainer>
            <Table className={tableStyles.dashboardTable} size='small'>
              <TableHead>
                <TableRow>
                  {
                    selectedCampusDisplayFields.map((column) => {
                      return (
                        <TableCell
                          variant='head'
                          key={column.headerName}
                          className={tableStyles.headerStyle}
                        >
                          {column.headerName}
                        </TableCell>
                      );
                    })
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={selectedCampus.id}>
                  <TableCell className={tableStyles.nameCell}>{selectedCampus.name}</TableCell>
                  <TableCell className={tableStyles.nameCell}>{selectedCampus.address}</TableCell>
                  <TableCell className={tableStyles.cityCell}>{selectedCampus.city}</TableCell>
                  <TableCell>{selectedCampus.state}</TableCell>
                  <TableCell>{selectedCampus.telephone}</TableCell>
                  <TableCell>{selectedCampus.district.name}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        }
        {tabLocation !== 2 ? (
          <div className={styles.buttonContainer}>
            <Button
              className={styles.loginButtons}
              variant='outlined'
              type='button'
              sx={{ marginLeft: '2rem' }}
              onClick={() => downloadFile()}
            >
              Download {tabLocation === 0 ? 'Active Maintenance Items' : 'Maintenance History'}
            </Button>
            {
              tabLocation === 0 && filteredDevices.length ? <Button
                className={styles.loginButtons}
                variant='outlined'
                type='button'
                onClick={async () => {
                  const campusId = selectedCampus ? selectedCampus.id : 'all';
                  const resolvedRes = await markDevicesAsResolved(campusId).unwrap();
                  if (resolvedRes) dispatch(api.util.invalidateTags([{ type: 'Campus', id: 'ALL' }]));
                  if (selectedCampus) setSelectedCampus(undefined);
                }}
              >
                Mark active items as Resolved
              </Button> : <></>
            }
            {
              selectedCampus && selectedCampus.fqdn && (
                (selectedCampus.ve6025_password && selectedCampus.ve6025_username) ||
                (ve6025Password && ve6025Username)
              ) ? <Button
                variant='outlined'
                className={styles.loginButtons}
                onClick={() => { 
                  if (selectedCampus.fqdn) handle6025Link(selectedCampus.fqdn);
                }}
              >ve6025 Login</Button> : <></>
            }
            {
              selectedCampus && selectedCampus.ip_six
                ? <Button
                  variant='outlined'
                  className={styles.loginButtons}
                  onClick={() => window.open(selectedCampus.ip_six)}
                >ip6000 Login</Button> : <></>
            }
          </div>
        ) : <></>
        }
      </div>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabLocation} onChange={handleTabChange} aria-label='Maintenance Tabs'>
            <Tab label='Active Items' {...a11yProps(0)} />
            <Tab label='History' {...a11yProps(1)} />
            {caps && events && <Tab label='Outgoing Maintenance Event' {...a11yProps(2)} />}
          </Tabs>
        </Box>
        <DeviceTabPanel index={0} value={tabLocation}>
          <DeviceTable
            error={error}
            tableType='active'
            deviceInfo={filteredDevices || []}
          />
        </DeviceTabPanel>
        <DeviceTabPanel index={1} value={tabLocation}>
          <DeviceTable
            error={error}
            tableType='history'
            deviceInfo={filteredDevices || []}
          />
        </DeviceTabPanel>
        {caps && events && <DeviceTabPanel index={2} value={tabLocation}>
          <MaintenanceForm
            maintenanceCap={maintenanceCap}
            maintenanceEvent={maintenanceEvent}
            events={events}
            caps={caps}
          />
        </DeviceTabPanel>}
      </Box>
    </>
    : <></>);
};
export default DeviceMaintenance;