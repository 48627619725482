import { Middleware } from '@reduxjs/toolkit';
import { io, Socket } from 'socket.io-client';
import { api } from '../Redux/api';
import { authActions } from '../Redux/Slices/AuthSlice';
import { socketActions } from '../Redux/Slices/SocketSlice';
import { inboxActions } from '../Redux/Slices/InboxSlice';
import { activeCapActions } from '../Redux/Slices/ActiveCapSlice';
import { statusUpdateActions } from '../Redux/Slices/StatusUpdateSlice';
import { Campus, Notification, ActiveCapMessage, User } from '../TypeScript/AppTypes';

const server = process.env.NODE_ENV === 'test' ? '' : `${process.env.REACT_APP_SOCKET_SERVER}`;
const path = `${process.env.REACT_APP_SOCKET_PATH}`;

const socketMiddleware: Middleware = store => {
  const socket: Socket = io(server, {
    transports: ['websocket', 'polling'],
    path: path,
    autoConnect: true,
  });

  socket.on('connect', () => {
    console.log('connected');
    store.dispatch(socketActions.confirmConnection(socket.id));
  });

  socket.on('disconnect', () => {
    console.log('disconnected');
    store.dispatch(socketActions.confirmConnection(null));
  });
  
  socket.on('connect_error', (error: any) => {//eslint-disable-line
    if (process.env.NODE_ENV !== 'test') {
      console.log(error);
    }
  });
  
  socket.on('socketError', (err: { type: string, error: string }) => {
    store.dispatch(socketActions.setSocketError(err.error));
  });  

      
  socket.on('alert', (alert: string) => {
    store.dispatch(socketActions.setSocketAlert(alert));
  });

  socket.on('uploadSuccess', (alert: string) => {
    store.dispatch(socketActions.setUploadStatus(alert));
    const uploader = store.getState().socket.isCsvUploader;
    if (!uploader) store.dispatch(api.util.invalidateTags([{ type: 'Campus', id: 'ALL' }]));
  });

  socket.on('uploadError', (errMsg: string) => {
    const uploader = store.getState().socket.isCsvUploader;
    if (uploader) {
      store.dispatch(socketActions.setSocketError(errMsg));
    }
    store.dispatch(socketActions.setIsCsvUploader(false));
  });
  
  socket.on('updatedUser', (updatedUser: User) => {
    // adds unread notifications to user's store
    store.dispatch(inboxActions.setUnreadNotifications(updatedUser.unread_notifications));
    // filters undread notifications for status updates and adds them to the user's store.
    store.dispatch(statusUpdateActions.setStatusNotifs(updatedUser.unread_notifications.filter((u) => {
      if (u.content) {
        return u.content.includes('Status Update:') || u.content.includes('Maintenance Update:');
      }
      return false;
    })));
    // get active cap messages
    store.dispatch(socketActions.emitFetchAllActiveCaps());
  });
  
  socket.on('updateCampus', (updatedCampus: Campus) => {   
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    store.dispatch<any>(
      // update campus in cache manually
      api.util.updateQueryData('getCampuses', undefined, (draftCampuses) => {
        const index = draftCampuses.findIndex(campus => campus.id === updatedCampus.id);
        if (index !== -1) draftCampuses[index] = updatedCampus;
      }),
    );
  });

  socket.on('incomingMaintenance', (message: string) => {
    // invalidates tags to refetch device infos
    store.dispatch(api.util.invalidateTags([{ type: 'Device', id: 'ALL' }]));
  });
    
  socket.on('newNotification', (notification: Notification) => {

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    store.dispatch<any>(
      // add new notification to cache manually
      api.util.updateQueryData('getNotifications', undefined, (draftNotifications) => {
        draftNotifications.unshift(notification);
      }),
    );

    // if campus status update, removes updated campus id from pending
    if (notification.content && notification.content.includes('Campus Alert:'))
      store.dispatch(statusUpdateActions.addSuccess(notification.campus.id));

    if (notification.content && (notification.content.includes('Status Update:') || notification.content.includes('Maintenance Update:'))) {
      store.dispatch(statusUpdateActions.addStatusNotifs(notification));
      store.dispatch(api.util.invalidateTags([{ type: 'Campus', id: notification.campus.id }]));
    }
      

    // get user update with any unread notifications
    socket.emit('getUser');
  });
  
  socket.on('activeCapMessages', (activeCaps: ActiveCapMessage[]) => {
    store.dispatch(activeCapActions.setActiveCaps(activeCaps));
  });
  
  socket.on('successfulCapSend', (data: { activeCaps: ActiveCapMessage[], message: string }) => {
    store.dispatch(activeCapActions.setSuccessMessage(data.message));
    store.dispatch(socketActions.emitFetchAllActiveCaps());
  });
  
  socket.on('successfulCapCancel', (data: { message: string }) => {
    store.dispatch(activeCapActions.setSuccessMessage(data.message));
    store.dispatch(socketActions.emitFetchAllActiveCaps());
  });

  return next => action => {
    
    /**
   * Upon successful login, dispatch confirmLogin and get notifications
   * @param action.payload: LoginResponse
   */
    if (api.endpoints.login.matchFulfilled(action)) {
      store.dispatch(socketActions.confirmLogin({ id: action.payload.id, token: action.payload.token }));
    }
    
    /**
     * Upon confirmLogin, report logged-in user's ID to associate socket client with a User
     * @param action.payload: string
     */
    if (socketActions.confirmLogin.match(action)) {
      store.dispatch(api.util.invalidateTags([{ type: 'Campus', id: 'ALL' }]));
      socket.emit('authenticate', { id: action.payload.id, token: action.payload.token });
    }

    /**
   * Upon confirmConnection, if payload is not null (i.e. if not 'disconnect')
   * re-report logged-in user's ID and token to associate socket client with user 
   */
    if (socketActions.confirmConnection.match(action) && 
      store.getState().auth.id !== null && 
      store.getState().auth.token !== null &&
      action.payload !== null) {
      const id = store.getState().auth.id;
      const token = store.getState().auth.token;
      store.dispatch(socketActions.confirmLogin({ id, token }));
    }

    /**
   * Upon logout, dispatch confirmLogout to clear out socket auth from store
   * @param action.payload: string
   */
    if (authActions.logout.match(action)) {
      const id = store.getState().auth.id;
      store.dispatch(socketActions.confirmLogout(id));
    }

    /**
   * Upon confirmLogout, report logged-out user's ID and disassociate socket client from user
   * @param action.payload: string
   */
    if (socketActions.confirmLogout.match(action)) {
      socket.emit('logoutUser', { userId: action.payload, socketId: socket.id });
      store.dispatch(authActions.deleteCredentials());
    }

    if (socketActions.emitSendCampusAlert.match(action)) {
      socket.emit('sendCampusAlert', action.payload);
      store.dispatch(statusUpdateActions.addPending(action.payload.campusId as unknown as string));
    }

    if (socketActions.emitSendCapMsg.match(action)) {
      const { campusIds, capId } = action.payload;
      const userId = store.getState().auth.id;
      socket.emit('sendCapMsg', { campusIds, capId, userId } );
    }

    if (socketActions.emitTriggerEvent.match(action)) {
      const { eventId } = action.payload;
      socket.emit('triggerEvent', { eventId });
    }

    if (socketActions.emitFetchAllActiveCaps.match(action)) {
      socket.emit('fetchAllActiveCaps');
    }

    if (socketActions.emitCancelCapMsg.match(action)) {
      socket.emit('cancelCapMsg', { id: action.payload });
    }

    if (socketActions.emitRemoveDeviceInfo.match(action)) {
      socket.emit('removeDeviceInfo', { id: action.payload.id, campusId: action.payload.campusId });
    }

    if (inboxActions.emitRemoveSingleUnreadNoti.match(action)) {
      socket.emit('removeSingleUnreadNotificaton', { userId: store.getState().auth.id, notiId: action.payload.notiId });
    }

    if (inboxActions.clearUnreadNotifications.match(action)) {
      socket.emit('removeUnreadNotifications', { userId: store.getState().auth.id });
    }

    next(action);
  };
};

export default socketMiddleware;
