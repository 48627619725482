import { useState } from 'react';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { FilePondFile, registerPlugin } from 'filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { FilePond } from 'react-filepond';
import { AppDataLabel } from '../../TypeScript/AppTypes';
import BaseDialog from './BaseDialog';
import { useDialogContext, useToastContext } from '..';
import { getErrorMessage } from '../../Utils/errorHandling';
import styles from './Dialog.module.css';
import 'filepond/dist/filepond.min.css';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { socketActions } from '../../Redux/Slices/SocketSlice';
import { useAppDispatch } from '../../Redux/hooks';

registerPlugin(FilePondPluginFileValidateType);

export interface CsvDialogProps {
  open: boolean,
  dataLabel: AppDataLabel,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uploadCsvData: MutationTrigger<any>,
}

const CsvDialog = (props: CsvDialogProps) => {

  const { open, dataLabel, uploadCsvData } = props;
  const [files, setFiles] = useState<FilePondFile[]>([]);
  const handleFilesChange = (f: FilePondFile[]) => setFiles(f);
  
  const { setErrorToast, setSuccessToast } = useToastContext();
  const { closeDialog } = useDialogContext();
  const dispatch = useAppDispatch();

  return (
    <BaseDialog
      open={open} 
      maxWidth='xs'
      fullWidth 
      title={`${dataLabel} Upload`} 
    >
      <>
        <DialogContent>
          <div className={styles.csvDialogBody}>
            <FilePond
              allowFileTypeValidation={true}
              acceptedFileTypes={['text/csv']}
              onupdatefiles={handleFilesChange}
              allowMultiple={false}
              name='files'
              dropOnElement
              labelIdle={'Drag & Drop your files or <span className="filepond--label-action">Browse</span>'}
            />
          </div>          
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>
            Cancel
          </Button>
          <Button 
            type="submit"
            onClick={async () => {
              if (!files.length) {
                setErrorToast('No file attached');
                return;
              }
              const filePayload = new FormData();
              filePayload.append('file', files[0].file);
              if (dataLabel === 'Campus') {
                dispatch(socketActions.setUploadStatus('Campus upload started'));
                dispatch(socketActions.setIsCsvUploader(true));
                uploadCsvData(filePayload);
              } else {
                try {
                  const message = await uploadCsvData(filePayload).unwrap();
                  setSuccessToast(`${message}`);
                } catch (err: unknown) {
                  setErrorToast(getErrorMessage(err));
                }
              }
              setFiles([]);
              closeDialog();
            }}
          >
            Upload
          </Button>
        </DialogActions>
      </>
    </BaseDialog>
  );
};

export default CsvDialog;