import { useCallback, useEffect, useState } from 'react';
import { FeatureGroup } from 'leaflet';
import { Paper, InputBase, IconButton, Divider } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useAppDispatch, useAppSelector } from '../../Redux/hooks';
import socketSlice from '../../Redux/Slices/SocketSlice';
import { dashboardMapSliceActions } from '../../Redux/Slices/DashboardMapSlice';
import {
  useGetCampusAlertsQuery,
  useGetCampusesQuery, useGetCapMessagesQuery,
  useGetDistrictIdsQuery, useGetSettingQuery, useGetTagsQuery, useGetZipCodeCoordinatesQuery } from '../../Redux/api';
import { Dropdown, SenderDialog, useDialogContext } from '../../Components';
import { CampusFormDialog } from '../Campuses/CampusFormDialog';
import ResetCampusDialog from '../../Components/Dialogs/ResetCampusDialog';
import DashboardMap from './DashboardMap';
import DashboardTable from './DashboardTable';
import { Campus, MapState, Tag } from '../../TypeScript/AppTypes';
import { DEFAULT_ZOOM } from '../../Utils/constants';

const Dashboard = (): JSX.Element => {
  const { data: campuses } = useGetCampusesQuery();
  const { data: districtIds } = useGetDistrictIdsQuery();
  const { data: campusAlerts } = useGetCampusAlertsQuery();
  const { data: caps } = useGetCapMessagesQuery();
  const { data: tags } = useGetTagsQuery();
  const [tagIds, setTagIds] = useState<string[]>([]);
  const { data: defaultCenterCoords } = useGetZipCodeCoordinatesQuery();
  const { data: defaultZoom } = useGetSettingQuery(DEFAULT_ZOOM);
  const { zoomLevel, selectedCampuses, dashboardMapCenter, campusSearchTerm, campusTagSearch } = useAppSelector((store) => store.dashboardMap);
  const { dialogType } = useDialogContext();
  const dispatch = useAppDispatch();

  const [mapState, setMapState] = useState<MapState>({
    bounds: null,
    centerPnt: null,
    radius: 0,
    polyPnts: [],
  });
  const [senderType, setSenderType] = useState<'Cap Message' | 'Campus Alert' | undefined>();
  const [editingCampus, setEditingCampus] = useState<Campus | null>();
  const [mapLayer, setMapLayer] = useState<FeatureGroup | null>(null);
  const [districtCampuses, setDistrictCampuses] = useState<Campus[]>([]);

  useEffect(() => {
    if (defaultZoom && zoomLevel === undefined){
      dispatch(dashboardMapSliceActions.setZoomLevel(parseInt(defaultZoom.value, 10)));
    }
    if (defaultCenterCoords && dashboardMapCenter === undefined) {
      dispatch(dashboardMapSliceActions.setDashboardMapCenter(defaultCenterCoords));
    }
  }, [defaultZoom, zoomLevel, defaultCenterCoords, dashboardMapCenter, dispatch]);

  useEffect(() => {
    if (campuses){
      if (districtIds && districtIds.length > 0){
        setDistrictCampuses(campuses.filter(campus => {
          if (campus.district.nces_district) {
            return districtIds.includes(campus.district.nces_district);
          }
          return false;
        }));
      } else {
        setDistrictCampuses(campuses);
      }
    }
  }, [districtIds, campuses]);

  useEffect(() => {
    if (campusTagSearch){
      setTagIds(campusTagSearch.map(t => t.id));
    }
  }, [campusTagSearch]);
  
  const compareTagArrays = (tagsArr: Tag[]) => {
    const campusHasTags = [];
    tagsArr.forEach((tag) => {
      if (tagIds?.includes(tag.id)) {
        campusHasTags.push(tag.id);
      }
    });
    return campusHasTags.length === campusTagSearch.length;
  };

  const matchedCampuses = districtCampuses?.filter((campus) => campus.name?.toLowerCase().includes(campusSearchTerm.toLowerCase()) || campus.address?.toLowerCase().includes(campusSearchTerm.toLowerCase()));
  const matchedCampusTags = matchedCampuses.filter(c => c.tags ? compareTagArrays(c.tags) : null);
  const handleSenderTypeChange = (type?: 'Cap Message' | 'Campus Alert') => type ? setSenderType(type) : setSenderType(undefined);
  const handleMapLayerChange = (newLayer: FeatureGroup | null) => setMapLayer(newLayer);
  const handleMapStateChange = (newMapState: MapState) => setMapState(newMapState);

  const handleCampusSelectionChange = (selection: Campus[]) => {
    const filteredSelections = selection.reduce((acc: Campus[], current) => {
      const x = acc.find(campus => campus.id === current.id);
      if (!x) return acc.concat([current]);
      return acc;
    }, []);
    dispatch(dashboardMapSliceActions.setSelectedCampuses(filteredSelections));
  };
  const handleCampusEdit = useCallback((id?: string) => {
    if (id && campuses) {
      const campus = campuses.filter(c => c.id === id)[0];
      setEditingCampus(campus);
    } else {
      setEditingCampus(null);
    }
  }, [campuses]);

  const sendCampusAlert = (campusAlertId: number) => {
    selectedCampuses.forEach((campus) => {
      dispatch(socketSlice.actions.emitSendCampusAlert({ 
        campusId: campus.id as unknown as number, campusAlertId }));
    });
    dispatch(dashboardMapSliceActions.setSelectedCampuses([]));
    handleMapStateChange({ bounds: null, polyPnts: [], centerPnt: null, radius: 0 });
    mapLayer?.clearLayers();
  };

  const sendCapMessage = (capSendId: number) => {
    const campusIds = selectedCampuses.map((campus) => campus.id);
    dispatch(socketSlice.actions.emitSendCapMsg({
      campusIds, capId: capSendId, 
    }));
    dispatch(dashboardMapSliceActions.setSelectedCampuses([]));
    handleMapStateChange({ bounds: null, polyPnts: [], centerPnt: null, radius: 0 });
    mapLayer?.clearLayers();
  };

  return (campuses && dashboardMapCenter && zoomLevel && districtIds && campusAlerts && caps && tags ? <>
      <DashboardMap
        defaultCenterCoords={dashboardMapCenter}
        defaultZoom={zoomLevel}
        campuses={districtCampuses}
        mapState={mapState}
        selectedCampuses={selectedCampuses}
        handleCampusSelectionChange={handleCampusSelectionChange}
        handleSenderTypeChange={handleSenderTypeChange}
        handleMapStateChange={handleMapStateChange}
        handleCampusEdit={handleCampusEdit}
        handleMapLayerChange={handleMapLayerChange}
        matchedCampuses={campusTagSearch && campusTagSearch.length ? matchedCampusTags : matchedCampuses}
      />
      <Paper
        component="form"
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 700, marginTop: 3 }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          data-cy="Search Campuses"
          placeholder="Search Campuses"
          inputProps={{ 'aria-label': 'search campuses' }}
          onChange={(e) => dispatch(dashboardMapSliceActions.setCampusSearchTerm(e.target.value))}
          value={campusSearchTerm}
        /> 
        <Divider/>
        <div style={{ width: 350 }}>
          <Dropdown
            id="tags" name="tags" label="Search by tag"
            isMultiSelect={true}
            value={campusTagSearch.map(t => t.name ? t.name : '').filter(n => n !== '')}
            options={tags.map(t => t.name ? t.name : '').filter(n => n !== '')}
            onChange={(e, newValue) => {
              const newTagArray: Tag[] = tags.filter(t => t.name && newValue?.includes(t.name));
              dispatch(dashboardMapSliceActions.setCampusTagSearch(newTagArray));
            }}
          />
        </div>
        <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
      <DashboardTable
        handleCampusSelectionChange={handleCampusSelectionChange}
        selectedCampuses={selectedCampuses.filter((campus) => campus.name?.toLowerCase().includes(campusSearchTerm.toLowerCase()) || campus.address?.toLowerCase().includes(campusSearchTerm.toLowerCase()))}
        matchedCampuses={campusTagSearch && campusTagSearch.length ? matchedCampusTags.filter((campus) => !selectedCampuses.includes(campus)) as unknown as Campus[] : matchedCampuses.filter((campus) => !selectedCampuses.includes(campus)) as unknown as Campus[]}
        handleCampusEdit={handleCampusEdit}
      />
      <SenderDialog 
        open={senderType === 'Cap Message' && dialogType === 'Sender'}
        dataLabel='Cap Message'
        options={caps.filter((singleCap) => !singleCap.dynamic)}
        sendUpdate={sendCapMessage}
        selectedCampuses={selectedCampuses.filter((campus) => campus.name?.toLowerCase().includes(campusSearchTerm.toLowerCase()) || campus.address?.toLowerCase().includes(campusSearchTerm.toLowerCase()))}
      />
      <SenderDialog 
        open={senderType === 'Campus Alert' && dialogType === 'Sender'}
        dataLabel='Campus Alert'
        options={campusAlerts}
        sendUpdate={sendCampusAlert}
        selectedCampuses={selectedCampuses.filter((campus) => campus.name?.toLowerCase().includes(campusSearchTerm.toLowerCase()) || campus.address?.toLowerCase().includes(campusSearchTerm.toLowerCase()))}
      />
      {dialogType === 'Update' ? 
      <CampusFormDialog 
        open={dialogType === 'Update'}
        campus={editingCampus || undefined} /> : 
      <></>}
      {dialogType === 'Reset Campus' ? 
      <ResetCampusDialog 
        open={dialogType === 'Reset Campus'} 
        campus={editingCampus?.id || undefined} 
        setEditingCampus={setEditingCampus} /> : 
      <></>}
    </> : <></>);
};

export default Dashboard;
