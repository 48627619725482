import { useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import { Edit, Delete } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { GridActionsCellItem, GridRenderCellParams, GridRowParams, GridValueGetterParams, GridAlignment } from '@mui/x-data-grid';
import { 
  useGetCampusStatusesQuery, useDeleteCampusStatusMutation, 
} from '../../Redux/api';
import { CrudTable, DeleteDialog, useDialogContext } from '../../Components';
import { CampusStatusFormDialog } from './CampusStatusFormDialog';
import { CampusStatus } from '../../TypeScript/AppTypes';
import styles from './CampusStatus.module.css';
import { useAppSelector } from '../../Redux/hooks';

const CampusStatuses = (): JSX.Element => {
  
  const { data: statuses, error, isLoading } = useGetCampusStatusesQuery();
  const [deleteCampusStatus] = useDeleteCampusStatusMutation();
  const { dialogType, openDialog } = useDialogContext();
  const [status, setStatus] = useState<CampusStatus | undefined>();
  const { roles } = useAppSelector((store) => store.auth);
  const nonAdminSecurity = roles.includes('security') && !roles.includes('admin');
  const location = useLocation();
  const headerLocation = 'Campus ' + (((location.pathname).slice(1, 2)).toUpperCase() + (location.pathname).slice(2));
  
  const tableColumns: { field: string, headerName: string, width?: number, type?: string,
    getActions?: (x: GridRowParams) => JSX.Element[], minWidth?: number,
    valueGetter?: (x: GridValueGetterParams) => string, hide?: boolean, flex?: number,
    renderCell?: (x: GridRenderCellParams) => JSX.Element, align?: GridAlignment,
  }[] = [
    { field: 'id', headerName: 'ID', flex: 1, hide: true },
    { field: 'label', headerName: 'Label', flex: 2, minWidth: 150 },
    { field: 'description', headerName: 'Description', flex: 2, minWidth: 150 },
    { field: 'color', headerName: 'Color', 
      renderCell: (params: GridRenderCellParams) => (
        <span style={{ backgroundColor: params.value }} className={styles.colorCell}></span>
      ),
      flex: 1, minWidth: 75, align: 'center' },
    { field: 'borderColor', headerName: 'Border Color', 
      renderCell: (params: GridRenderCellParams) => (
        <span style={{ backgroundColor: params.value }} className={styles.colorCell}></span>
      ),
      flex: 1, minWidth: 75, align: 'center' },
  ] ;

  const editColumn = {
    field: 'actions',
    headerName: 'Actions',
    type: 'actions',
    width: 80,
    getActions: (params: GridRowParams) => [
      <GridActionsCellItem
        data-cy="edit"        
        icon={<Edit />}
        onClick={(e) => {
          handleStatusSelection(params.id as string);
          openDialog('Update');
        }}
        label="Edit" />,
      <GridActionsCellItem
        data-cy="delete"
        icon={<Delete />}
        onClick={(e) => {
          handleStatusSelection(params.id as string);
          openDialog('Delete');
        }}
        label="Delete" />,
    ],
  };


  const handleStatusSelection = useCallback((id?: string) => {
    if (id && statuses) {
      const selectedStatus = statuses.filter(s => s.id === id)[0];
      setStatus(selectedStatus);
    } else {
      setStatus(undefined);
    }
  }, [statuses]);

  return (statuses ? <>
    <h1 className={styles.headerName}>{headerLocation}</h1>
    <Tooltip title='Manage campus statuses. Setting statuses can trigger events and alerts.' arrow>
      <InfoIcon />
    </Tooltip>
    <CrudTable
      dataLabel='Campus Status'
      handleRecordSelection={handleStatusSelection}
      rowData={statuses}
      error={error}
      isLoading={isLoading}
      columnNames={nonAdminSecurity ? tableColumns : [editColumn, ...tableColumns]}
      restrictedRole={nonAdminSecurity}
      createOrEditDialog={
        <CampusStatusFormDialog
          open={dialogType === 'Create' || dialogType === 'Update'}
          status={status || undefined}
        />
      }
      deleteDialog={status && status !== null ?
        <DeleteDialog
          open={dialogType === 'Delete'}
          dataLabel='Campus Status'
          handleDelete={async () => { 
            await deleteCampusStatus(status.id).unwrap();
          }}
        /> :
        <></>
      }
    /></> : <></>);
};

export default CampusStatuses;
