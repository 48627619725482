import { Menu, MenuItem } from '@mui/material';
import axios from 'axios';
import { LatLngExpression } from 'leaflet';
import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { CircleMarker } from 'react-leaflet';
import { useToastContext } from '../../Components';
import { useAppSelector } from '../../Redux/hooks';
import { Campus } from '../../TypeScript/AppTypes';
import { ve6025Link } from '../../Utils/constants';
import { getErrorMessage } from '../../Utils/errorHandling';

interface DeviceMaintenanceMarkerProps {
  id: string;
  coords: LatLngExpression;
  isSelected: boolean;
  borderColor: string;
  color: string;
  campuses: Campus[];
  handleCampusSelectionChange: (selection: Campus) => void;
  selectedCampus: Campus,
}

export const DeviceMaintenanceMarkers = (
  props: DeviceMaintenanceMarkerProps,
) => {
  const {
    id,
    coords,
    isSelected,
    borderColor,
    color,
    campuses,
    selectedCampus,
    handleCampusSelectionChange,
  } = props;
  const circleRef = useRef<any>(null);//eslint-disable-line
  const { setErrorToast } = useToastContext();
  const { token, ve6025Username, ve6025Password } = useAppSelector((state) => state.auth); 
  const campus = campuses.filter((c) => c.id === id)[0];
  const [contextMenu, setContextMenu] = useState<{
    campus: Campus,
    top: number,
    left: number,
  } | null>(null);


  useEffect(() => {
    circleRef.current.setStyle({ color: borderColor }); // borderColor => color
    circleRef.current.setStyle({ fillColor: color }); // color => fillColor
  }, [color, borderColor]);

  const markerClick = () => {
    if (isSelected === false){
      const newCampusSelected = campuses.filter(c => c.id === id); 
      newCampusSelected && handleCampusSelectionChange(newCampusSelected[0]);
    } else {
      handleCampusSelectionChange(selectedCampus);
    }
  };

  const handleContextMenu = (e: any) => {//eslint-disable-line
    setContextMenu(contextMenu === null ? 
      { 
        campus,
        left: e.originalEvent.clientX,
        top: e.originalEvent.clientY,
      } : 
      null);  
  };

  const handleContextMenuClose = () => setContextMenu(null);

  const handle6025Link = async (link: string) => {
    let username, attempt;
    try {
      if (contextMenu && contextMenu.campus.ve6025_username && contextMenu.campus.ve6025_password){
        attempt = contextMenu?.campus.ve6025_password;
        username = contextMenu.campus.ve6025_username;
      } else {
        attempt = ve6025Password;
        username = ve6025Username;
      }
      const { data } = await axios.post('/api/campus/ve6025', { attempt }, { headers: { Authorization: `Bearer ${token}` } });
      window.open(ve6025Link(link, username, data.result));
      handleContextMenuClose();
    } catch (e: unknown) {
      setErrorToast(getErrorMessage(e));
    }
  };
  
  const handleIP6KLink = (link: string) => {
    window.open(link);
    handleContextMenuClose();
  };

  return (
          <>
            <CircleMarker
              key={id}
              pathOptions={isSelected ? { weight: 4 } : { weight: 2 }}
              color={borderColor}
              fillColor={color}
              center={coords}
              radius={7}
              fillOpacity={2}
              interactive={true}
              ref={(ref) => {
                circleRef.current = ref;
              }}
              bubblingMouseEvents={false}
              eventHandlers={{
                click: () => {markerClick();},
                contextmenu: (e) => handleContextMenu(e as unknown as React.MouseEvent), 
              }}>
                {(contextMenu && contextMenu.campus.fqdn) || (contextMenu && contextMenu.campus.ip_six !== '')
                  ? <Menu
                  open={contextMenu !== null }
                  onClose={handleContextMenuClose}
                  className="menu"
                  anchorReference="anchorPosition"
                  anchorPosition={contextMenu !== null ? { top: contextMenu.top, left: contextMenu.left } : undefined}
                >
                {(ve6025Username && ve6025Password) || 
                  (contextMenu.campus.ve6025_username && contextMenu.campus.ve6025_password) ? 
                  <MenuItem 
                    key='VE6025 Link'
                    className="menuItem"
                    divider={true} 
                    onClick={() => { 
                      if (contextMenu.campus.fqdn) handle6025Link(contextMenu.campus.fqdn);
                    }}                  >
                    VE6025 Link
                  </MenuItem> : []}
                {contextMenu.campus.ip_six !== '' ?
                  <MenuItem 
                    key='IP6000 Link'
                    className="menuItem" 
                    divider={true} 
                    onClick={() => handleIP6KLink(contextMenu.campus.ip_six)}
                  >
                    IP6000 Link
                  </MenuItem> : []}
                </Menu> : <></>}
              </CircleMarker>
            </>
  );
};
