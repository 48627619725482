import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { GridActionsCellItem, GridRenderCellParams, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useDeleteCapMessageMutation, useGetCapMessagesQuery } from '../../Redux/api';
import { CrudTable, DeleteDialog, useDialogContext } from '../../Components';
import { CapMessageFormDialog } from './CapMessageFormDialog';
import { CapMessage } from '../../TypeScript/AppTypes';
import { useAppSelector } from '../../Redux/hooks';
import { Chip } from '@mui/material';
import { dynamicValueList } from '../../Utils/dynamicCapVariables';
import { GridCellExpand } from '../../Components/GridCellPopover/GridCellPopover';
import { generateUniqueKey } from '../../Utils/createUniqueKey';
import styles from './CapMessages.module.css';

const CapMessages = (): JSX.Element => {
  const { roles } = useAppSelector((store) => store.auth);
  const { data: caps, error, isLoading } = useGetCapMessagesQuery();
  const [deleteCapMessage] = useDeleteCapMessageMutation();
  const { dialogType, openDialog } = useDialogContext();
  const location = useLocation();
  const headerLocation = 'CAP ' + (((location.pathname).slice(1, 2)).toUpperCase() + (location.pathname).slice(2));

  const [cap, setCap] = useState<CapMessage | undefined>();
  const nonAdminSecurity = roles.includes('security') && !roles.includes('admin');

  const tableColumns: {
    field: string, headerName: string, width?: number, type?: string,
    getActions?: (x: GridRowParams) => JSX.Element[], minWidth?: number,
    valueGetter?: (x: GridValueGetterParams) => string, hide?: boolean, flex?: number,
    renderCell?: (x: GridRenderCellParams) => JSX.Element,
  }[] = [
    { field: 'id', headerName: 'id', flex: 1, hide: true },
    { field: 'label', headerName: 'Label', flex: 1, minWidth: 100 },
    { field: 'headline', headerName: 'Headline', flex: 1, minWidth: 100 },
    { field: 'instruction', headerName: 'Instruction', flex: 2, minWidth: 100 },
    { field: 'description', headerName: 'Description', flex: 2, minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        const desc = params.value.split(' ');
        const items = desc.map((descItem: string) => {
          if (descItem === '{NAME}' || descItem === '{ADDR}') {
            const match = dynamicValueList.find((val) => val.sendValue === descItem);
            return <Chip style={{ marginRight: '4px' }} key={generateUniqueKey()} label={match?.showValue} />;
          }
          return <span key={generateUniqueKey()} style={{ marginRight: '4px' }}>{descItem}</span>;
        });
        return <GridCellExpand value={<>{items}</>} width={params.colDef.computedWidth} />;
      },
    },
    { field: 'dynamic', headerName: 'Dynamic', flex: 1, minWidth: 100 },
    { field: 'expires', headerName: 'Expires', width: 100, minWidth: 75 },
  ];

  const editColumn = {
    field: 'actions',
    headerName: 'Actions',
    type: 'actions',
    width: 80,
    getActions: (params: GridRowParams) => [
      <GridActionsCellItem
        data-cy="edit"
        icon={<Edit />}
        onClick={(e) => {
          handleCapSelection(params.id as string);
          openDialog('Update');
        }}
        label="Edit" />,
      <GridActionsCellItem
        data-cy="delete"
        icon={<Delete />}
        onClick={(e) => {
          handleCapSelection(params.id as string);
          openDialog('Delete');
        }}
        label="Delete" />,
    ],
  };

  const handleCapSelection = useCallback((id?: string) => {
    if (id && caps) {
      const selectedTag = caps.filter(c => c.id === parseInt(id))[0];
      setCap(selectedTag);
    } else {
      setCap(undefined);
    }
  }, [caps]);

  return ( caps ? <>
    <h1 className={styles.headerName}>{headerLocation}</h1>
    <Tooltip title='Manage CAP messages to be sent via the Dashboard' arrow>
      <InfoIcon />
    </Tooltip>
    <CrudTable
      dataLabel='Cap Message'
      handleRecordSelection={handleCapSelection}
      rowData={caps.filter(c => c.label !== 'Maintenance')}
      error={error}
      isLoading={isLoading}
      columnNames={nonAdminSecurity ? tableColumns : [editColumn, ...tableColumns]} 
      restrictedRole={nonAdminSecurity}
      createOrEditDialog={
        <CapMessageFormDialog
          open={dialogType === 'Create' || dialogType === 'Update'}
          cap={cap || undefined}
        /> 
      }
      deleteDialog={cap && cap !== null ?
        <DeleteDialog
          open={dialogType === 'Delete'}
          dataLabel='Cap Message'
          handleDelete={async () => { 
            await deleteCapMessage(cap.id).unwrap();
          }}
        /> :
        <></>
      }   
    /></> : <></>);
};

export default CapMessages;
