import { createContext, useContext } from 'react';
import { UseToastHookReturn } from './ToastProvider';

export const ToastContext = createContext<UseToastHookReturn | undefined>(undefined);

export const useToastContext = (): UseToastHookReturn => {
  const context = useContext(ToastContext);
    
  // Error 
  if (context === undefined) {
    throw Error(
      'This component must be wrapped with <ToastProvider />, '
          + 'otherwise it will not function correctly.',
    );
  }
  
  return context;
};

export default ToastContext;
