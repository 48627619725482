import { Button, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { BaseDialog, useDialogContext } from '../../Components';
import { getDateString } from '../../Utils/getDateString';
import { Device } from '../../TypeScript/AppTypes';
import styles from './DeviceMaintenance.module.css';


export const DeviceDetailsDialog = (props: { device: Device, isOpen: boolean }): JSX.Element => {
  const { isOpen, device } = props;
  const { closeDialog } = useDialogContext();

  return (    
    <BaseDialog 
        open={isOpen} 
        title={'Device Details'}
        fullWidth={true}
    >
    <>
      <DialogContent className={styles.content}>
        <DialogContentText className={styles.dialogText}>
          <span className={styles.span}>Date: </span> 
            {getDateString(device.reported_at)}
        </DialogContentText><br/>
        <DialogContentText className={styles.dialogText}>
            <span className={styles.span}>Campus Information: </span><br/>
            <span className={styles.details}>Name: </span>{device.campus ? device.campus.name : 'Name unavailable'}<br/>
            <span className={styles.details}>Address: </span>{device.campus ? device.campus.address : 'Address unavailable'}
        </DialogContentText> <br/>
        <DialogContentText className={styles.dialogText}>
            <span className={styles.span}>Device Information: </span><br/>
            <span className={styles.details}>Reporter Area ID: </span> 
            {device.reporter_areaid}<br/>
            <span className={styles.details}>Reporter Unique ID: </span> 
            {device.reporter_uniqid}<br/>
            <span className={styles.details}>Reporter Device Type: </span> 
            {device.reporter_devtype}<br/>
            <span className={styles.details}>Reporter Name: </span> 
            {device.reporter_name}<br/>
            <span className={styles.details}>Reporter IP: </span> 
            {device.reporter_ip}<br/>
            <span className={styles.details}>Endpoint IP: </span> 
            {device.endpoint_ip}<br/>
            <span className={styles.details}>Endpoint Name: </span> 
            {device.endpoint_name}<br/>
            <span className={styles.details}>Endpoint Details: </span> 
            {device.endpoint_details}<br/>
            <span className={styles.details}>Endpoint Status: </span> 
            {device.endpoint_status}
        </DialogContentText>        
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>
            Close
        </Button>
      </DialogActions>
      </>
    </BaseDialog>
  );
};

export default DeviceDetailsDialog;