import {
  Dialog, DialogTitle, Breakpoint,
} from '@mui/material';
import styles from './Dialog.module.css';
import { useDialogContext } from '..';

export interface DialogProps {
  open: boolean;
  title: string;
  children: JSX.Element,
  maxWidth?: false | Breakpoint | undefined,
  fullWidth?: boolean,
  onClose?: () => void,
}

const BaseDialog = (props: DialogProps): JSX.Element => {

  const { open, title, children, maxWidth, fullWidth, onClose } = props;
  const { closeDialog } = useDialogContext();

  return (<>
        <Dialog
          open={open}
          onClose={() => {
            if (onClose) onClose();
            closeDialog();
          }}
          maxWidth={maxWidth} 
          fullWidth={fullWidth}
        >
          <DialogTitle className={styles.dialogTitle}>
            {title}
          </DialogTitle>
            {children}
        </Dialog>
    </>);
};

export default BaseDialog;