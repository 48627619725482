import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import { IconButton, Tooltip, Select, SelectChangeEvent, InputLabel, MenuItem, FormControl, Box } from '@mui/material';
import { RemoveRedEyeTwoTone } from '@mui/icons-material';
import { GridCellParams } from '@mui/x-data-grid';
import { useGetNotificationsQuery } from '../../Redux/api';
import { useAppDispatch, useAppSelector } from '../../Redux/hooks';
import inboxSlice from '../../Redux/Slices/InboxSlice';
import NotificationsDialog from './NotificationsDialog';
import { Table, useDialogContext } from '../../Components';
import { getCampusName, getNotificationDate } from '../../Utils/valueGetters';
import { Notification } from '../../TypeScript/AppTypes';
import styles from './Notifications.module.css';

const Notifications = (): JSX.Element => {
  const { data: notifications, error, isLoading } = useGetNotificationsQuery();
  const { unreadNotifications } = useAppSelector(state => state.inbox);
  const { dialogType, openDialog } = useDialogContext();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [filterValue, setFilterValue] = useState('');
  const headerLocation = (((location.pathname).slice(1, 2)).toUpperCase() + (location.pathname).slice(2));
  const [notification, setNotification] = useState<Notification | undefined >(undefined);

  const handleNotificationSelection = useCallback((id?: string) => {
    if (id && notifications) {
      const selectedNotification = notifications.filter(n => n.id === id)[0];
      setNotification(selectedNotification);
    } else 
      setNotification(undefined);
  }, [notifications]);  

  useEffect(() => {
    if (unreadNotifications.length > 0) {
      dispatch(inboxSlice.actions.clearUnreadNotifications());
    }
  }, [unreadNotifications.length, dispatch]);

  const handleFilter = (event: SelectChangeEvent) => {
    event.preventDefault();
    setFilterValue(event.target.value);
  };

  const handleRows = (): Notification[] => {
    if (notifications) {
      switch (filterValue) {
        case 'maintenanceEvents':
          return notifications.filter(n => {
            if (n.content) return n.content.toLowerCase().includes('maintenance update:');
            return false;
          });
        case 'statusUpdates':
          return notifications.filter(n => {
            if (n.content) return n.content.toLowerCase().includes('status update:');
            return false;
          });
        case 'sentAlerts':
          return notifications.filter(n => {
            if (n.content) return n.content.toLowerCase().includes('campus alert:');
            return false;
          });
        case 'sentCAP':
          return notifications.filter(n => {
            if (n.content) return n.content.toLowerCase().includes('cap message:');
            return false;
          });
        default: 
          return notifications;
      }
    } else return [];
  };

  return (notifications ? <>
  <h1 className={styles.headerName}>{headerLocation}</h1>
    <Tooltip title='Notification history' arrow>
      <InfoIcon />
    </Tooltip>
    {notification ? 
    <NotificationsDialog
      isOpen={dialogType === 'Notification'}
      notification={notification}
    /> : <></>}
    <Box sx={{ paddingBottom: 1 }}>
      <FormControl fullWidth>
        <InputLabel id='notifications-filter'>Filter Notifications</InputLabel>
        <Select
          labelId='notifications-filter'
          value={filterValue}
          label='Filter Notifications'
          onChange={handleFilter}
          role={'listbox'}
          sx={{ width: 210 }}
          defaultValue={'showAll'}
        >
          <MenuItem data-testid={'select-all'} value={'showAll'}>Show All Notifications</MenuItem>
          <MenuItem data-testid={'select-maintenance'} value={'maintenanceEvents'}>Maintenance Events</MenuItem>
          <MenuItem data-testid={'select-status'} value={'statusUpdates'}>Status Updates</MenuItem>
          <MenuItem data-testid={'select-alerts'} value={'sentAlerts'}>Sent Alerts</MenuItem>
          <MenuItem data-testid={'select-cap'} value={'sentCAP'}>Sent Cap Messages</MenuItem>
        </Select>
      </FormControl>
    </Box>
    <Table 
      isLoading={isLoading} 
      error={error} 
      rowData={handleRows().map((n: Notification) =>
        ({ viewReport: n.content ?
            <IconButton
              role='dialog-button' 
              size='large' 
              data-testid={'detail-view-button'}
              onClick={() => { 
                handleNotificationSelection(n.id);
                openDialog('Notification');
              }}
            >
              <Tooltip title="View Report">
                <RemoveRedEyeTwoTone />
              </Tooltip>
            </IconButton> : <></>,
        ...n }),
      )} 
      columnNames={[
        { 
          field: 'viewReport', 
          headerName: 'View Report',  
          flex: 2,
          renderCell: (params: GridCellParams) => { return params.value; }, 
          align: 'center',
        },
        { field: 'date', headerName: 'Date', flex: 8, valueGetter: getNotificationDate,
        },
        { field: 'campus', headerName: 'Campus Name', valueGetter: getCampusName, flex: 10 },
        { field: 'content', headerName: 'Content', flex: 10 },
      ]} 
    />
  </> : <></>);
};

export default Notifications;