export const getDateString = (d: string | Date) => {
  return new Date(d).toLocaleDateString(
    undefined,
    // dates are stored as timestamp w/ no timezone, native methods auto-localize
    {
      weekday: 'short',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    },
  );  
};