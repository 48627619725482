import { useState } from 'react';
import { Dropdown, useDialogContext, TextInput, BaseDialog, useToastContext, ErrorFocus } from '../../Components';
import { User, Role, UserRequest } from '../../TypeScript/AppTypes';
import { useAddUserMutation, useEditUserMutation, useGetRolesQuery } from '../../Redux/api';
import { Button, DialogActions, DialogContent, Checkbox, FormControlLabel } from '@mui/material';
import { getErrorMessage } from '../../Utils/errorHandling';
import { trimRequest } from '../../Utils/trimRequest';
import { checkPasswordNontrivial, checkPasswordValid } from '../../Utils/passwordChecks';

export const blankUserInput: Partial<UserRequest> = {
  active: true,
  username: '',
  password: '',
  email: '',
  ve6025_username: '',
  ve6025_password: '',
  roles: [],
};

const useUserFormDialog = (user?: User) => {
  
  const [addUser] = useAddUserMutation();
  const [editUser] = useEditUserMutation();
  const { setSuccessToast, setErrorToast } = useToastContext();
  const { closeDialog } = useDialogContext();
  
  // controlled multi-select
  const [selectedRoles, setSelectedRoles] = useState<Role[]>(user ? user.roles : []);
  const handleSetSelectedRoles = (roles: Role[]) => setSelectedRoles(roles); 

  // errors
  const [errors, setErrors] = useState<{
    username: boolean,
    email: boolean,
    passwordInvalid: boolean,
    passwordTrivial: boolean,
    confirmPassword: boolean,
    confirmVe6025Password: boolean,
    roles: boolean,
  }>({
    username: false,
    email: false,
    passwordInvalid: false,
    passwordTrivial: false,
    confirmPassword: false,
    confirmVe6025Password: false,
    roles: false,
  });

  const validateUserRequest = (
    input: UserRequest, 
    confirmPassword: string, 
    confirmVe6025Password: string,
    requestType: 'create' | 'update',
  ) => {

    const regExEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    let isUsernameInvalid;
    if (input.username.length) isUsernameInvalid = false;
    else isUsernameInvalid = true;

    let isEmailInvalid;
    if (input.email.length && regExEmail.test(input.email)) isEmailInvalid = false;
    else isEmailInvalid = true;

    let isMinimumRoleNotSelected;
    if (input.roles.length >= 1) isMinimumRoleNotSelected = false;
    else isMinimumRoleNotSelected = true;

    // password validation
    let isPasswordInvalid;
    let isPasswordTrivial;
    let isPasswordNotMatching;
    if (requestType === 'create' && !input.password) {
      // create request missing password, set errors and skip all other validation
      isPasswordInvalid = true;
      isPasswordTrivial = true;
      isPasswordNotMatching = true;
    } else {
      if (!input.password && confirmPassword.length === 0) {
        // edit request contains no password update, skip validation
        isPasswordInvalid = false;
        isPasswordTrivial = false;
        isPasswordNotMatching = false;
      } else {
        if (input.password && checkPasswordValid(input.password)) isPasswordInvalid = false;
        else isPasswordInvalid = true;
        if (input.password && checkPasswordNontrivial(input.password)) isPasswordTrivial = false;
        else isPasswordTrivial = true;
        if (confirmPassword && input.password === confirmPassword) isPasswordNotMatching = false;
        else isPasswordNotMatching = true;
      }
    }

    // ve6025 password validation
    let is6025PasswordNotMatching = false;
    if (input.ve6025_password && input.ve6025_password !== confirmVe6025Password)
      is6025PasswordNotMatching = true;

    setErrors({ 
      username: isUsernameInvalid, 
      passwordInvalid: isPasswordInvalid,
      passwordTrivial: isPasswordTrivial,
      confirmPassword: isPasswordNotMatching,
      confirmVe6025Password: is6025PasswordNotMatching,
      email: isEmailInvalid, 
      roles: isMinimumRoleNotSelected,
    });

    // if no errors, return true
    if (
      isUsernameInvalid || 
      isEmailInvalid || 
      isPasswordInvalid ||
      isPasswordTrivial ||
      isPasswordNotMatching || 
      is6025PasswordNotMatching || 
      isMinimumRoleNotSelected
    )
      return false;
    else {
      return true;
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const fieldValues = Object.fromEntries(formData.entries());

    // Get data from form, then add values from controlled multi-select field (roles)
    const trimmedFieldValues = trimRequest(fieldValues) as unknown as Record<string, unknown>;
    const { confirmPassword, confirmVe6025Password, ...remaining } = trimmedFieldValues;
    const body = remaining as unknown as UserRequest;
    body.roles = selectedRoles.length > 0 ? selectedRoles.map(r => r.id) : [];
    if (body.password === '' && confirmPassword === '') delete body.password;
    if (body.ve6025_password === '' && confirmVe6025Password === '') delete body.ve6025_password; 
    body.active ? body.active = true : body.active = false;

    try {
      if (user) {
        const isValidUserUpdate = validateUserRequest(
          body, confirmPassword as string, confirmVe6025Password as string, 'update',
        );
        if (isValidUserUpdate) {
          await editUser({ ...body, id: user.id }).unwrap();
          setSuccessToast('User successfully updated');
          closeDialog();
        }
      } else {
        const isValidUserCreate = validateUserRequest(
          body, confirmPassword as string, confirmVe6025Password as string, 'create',
        );
        if (isValidUserCreate) {
          await addUser(body).unwrap();
          setSuccessToast('User successfully created');
          closeDialog();
        }
      }
    } catch (err: unknown) {
      setErrorToast(getErrorMessage(err));
    }
  };
  
  return {
    errors,
    selectedRoles,
    handleSetSelectedRoles,
    handleSubmit,
  };

};

export const UserFormDialog = (props: {
  open: boolean,
  user?: User,
}): JSX.Element => {
  
  const { open, user } = props;
  const { errors, selectedRoles, handleSetSelectedRoles, handleSubmit } = useUserFormDialog(user);
  const { data: roles } = useGetRolesQuery();
  const { closeDialog } = useDialogContext();
  const [activeUser, setActiveUser] = useState(user ? user.active : blankUserInput.active);

  return (roles ?
    <BaseDialog
      open={open}
      title={user ? 'Update User' : 'Create New User'}
    >
      <form 
        noValidate 
        onSubmit={handleSubmit}
      >
        <DialogContent>
        <FormControlLabel
            control={<Checkbox checked={activeUser || false}
            onChange={() => setActiveUser(!activeUser)} />}
            name='active'
            label='Active User'
          />
        <TextInput
          id="username" name="username" label="Username" 
          error={errors.username} helperText="Please enter a username."
          defaultValue={user ? user.username : blankUserInput.username}
          required
        />
        <TextInput
          id="password" name="password" type="password" label="Password" placeholder="********"
          error={errors.passwordInvalid || errors.passwordTrivial} 
          helperText={errors.passwordInvalid ?
            'Password must be at least 8 characters, with at least one letter and one number and no more than three repeating characters. Letters, numbers, and the characters !@#$%^&* allowed.' :
            errors.passwordTrivial ?
              'This password is not strong enough. Please choose another.' :
              undefined
          }
          defaultValue={blankUserInput.password}
          required={!user}
        />
        <TextInput
          id="confirmPassword" name="confirmPassword" type="password" label="Confirm Password" placeholder="********"
          error={errors.confirmPassword} helperText="Please re-enter the password."
          defaultValue={blankUserInput.password}
          required={!user}
        />
        <TextInput
          id="email" name="email" label="Email" 
          error={errors.email} helperText="Please enter a valid email."
          defaultValue={user ? user.email : blankUserInput.email}
          required
        />
        <TextInput
          id="ve6025_username" name="ve6025_username" label="VE6025 Username"
          defaultValue={user ? user.ve6025_username : blankUserInput.ve6025_username}
        />
        <TextInput
          id="ve6025_password" name="ve6025_password" type="password" label="VE6025 Password" placeholder="********"
          defaultValue={blankUserInput.ve6025_password}
        />
        <TextInput
          id="confirmVe6025Password" name="confirmVe6025Password" type="password" label="Confirm VE6025 Password" placeholder="********"
          error={errors.confirmVe6025Password} helperText="Please re-enter the password."
          defaultValue={blankUserInput.ve6025_password} 
        />
        <Dropdown 
          id="roles" name="roles" label="Roles"
          isMultiSelect={true}
          error={errors.roles} helperText="Please select at least one role."
          value={selectedRoles.map(r => r.name)}
          options={roles.map(r => r.name)}
          onChange={(e, newValue) => {
            const newRoleArray: Role[] = roles.filter(r => newValue?.includes(r.name));
            handleSetSelectedRoles(newRoleArray);
          }}
          required
        />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>
            Cancel
          </Button>
          <Button type="submit">
            Submit
          </Button>
        </DialogActions>
        <ErrorFocus errors={errors} />
      </form>
    </BaseDialog> : <></>);
};