import { OutlinedInputProps, TextField } from '@mui/material';


const TextInput = (props: {
  id: string,
  label: string,
  name: string,
  type?: string,
  required?: boolean,
  placeholder?: string,
  error?: boolean,
  helperText?: string,
  defaultValue: string | number | null | undefined,
  subtext?: string,
  onChange?: (e: any) => void, //eslint-disable-line
  inputProps?: Partial<OutlinedInputProps>,
  value?: string,
  disabled?: boolean,
}) => {

  const { id, name, label, type, required, placeholder,
    error, helperText, defaultValue, subtext, onChange, inputProps, value, disabled } = props;
    
  return (
    <TextField
      disabled={disabled}
      variant="outlined" margin="dense" fullWidth 
      key={`${id}-input`}
      id={`${id}-input`}
      label={label}
      type={type}
      name={name ? name : id}
      required={required}
      placeholder={value ? '' : placeholder}
      error={error}
      helperText={error ? helperText : (subtext ? subtext : undefined) }
      defaultValue={defaultValue}
      onClick={(e) => { 
        e.stopPropagation();
      }}
      onChange={onChange}
      InputProps={inputProps}
      value={value !== undefined ? value : undefined}
    />
  );
};

export default TextInput;