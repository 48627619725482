import { useNavigate } from 'react-router-dom';
import { Box, AppBar, Toolbar, Button, ButtonGroup, Badge } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../Redux/hooks';
import { authActions } from '../../Redux/Slices/AuthSlice';
import ButtonLink from './ButtonLink';
import CancelCapDialog from '../Dialogs/CancelCapDialog';
import { useDialogContext } from '..';

const NavigationTop = (): JSX.Element => {

  const { unreadNotifications } = useAppSelector((state) => state.inbox);
  const { activeCaps } = useAppSelector((state) => state.activeCap);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { dialogType, openDialog } = useDialogContext();

  return (
      <Box component="nav" sx={{ flexGrow: 1 }}>
      <AppBar position="absolute" 
        // 200px corresponds to Drawer's width prop in NavigationSide.tsx
        sx={{ width: 'calc(100% - 200px)', ml: '200px' }}
        >
        <Toolbar>
        <ButtonGroup sx={{ flex: 1 }}>
          <Badge 
            badgeContent={unreadNotifications.length} 
            color='secondary'
            showZero>
            <ButtonLink
              key={'/notifications'} 
              title={'Notifications'}
              to={'/notifications'}
              />
          </Badge>
          {
            activeCaps.length ? (
              <Badge
              badgeContent={activeCaps.length} 
              color='secondary'
              showZero>
                <Button
                  color='inherit'
                  onClick={() => openDialog('ActiveCap')}
                  >Active CAP</Button>
              </Badge>
            ) : null
          }
        </ButtonGroup>
        <Button 
          color="inherit" 
          onClick={() => {
            dispatch(authActions.logout());
            navigate('/');
          }}
          >
          Logout
        </Button>
        </Toolbar>
      </AppBar>
      <CancelCapDialog
        open={dialogType === 'ActiveCap'}
        activeCapMessages={activeCaps}
      />
    </Box>
  );
};

export default NavigationTop;