import { useState } from 'react';
import { Box, Button, DialogActions, DialogContent, TextField } from '@mui/material';
import { HexColorPicker } from 'react-colorful';
import { CampusStatus, CampusStatusRequest } from '../../TypeScript/AppTypes';
import { useDialogContext, TextInput, BaseDialog, useToastContext } from '../../Components';
import { useAddCampusStatusMutation, useEditCampusStatusMutation } from '../../Redux/api';
import { getErrorMessage } from '../../Utils/errorHandling';
import { trimRequest } from '../../Utils/trimRequest';
import styles from './CampusStatus.module.css';

export const blankCampusStatusInput: Partial<CampusStatus> = {
  label: '',
  description: '',
  color: '#aabbcc',
  borderColor: '#aabbcc',
};

export const CampusStatusFormDialog = (props: {
  open: boolean,
  status?: CampusStatus,
}): JSX.Element => {

  const { open, status } = props;
  
  const [addCampusStatus] = useAddCampusStatusMutation();
  const [editCampusStatus] = useEditCampusStatusMutation();

  const [color, setColor] = 
    useState<string>(status && status.color ? status.color : '#aabbcc');
  const [borderColor, setBorderColor] =   
    useState<string>(status && status.borderColor ? status.borderColor : '#aabbcc');
  const [requiredField, setRequiredField] = useState(false);

  const { dialogType, closeDialog } = useDialogContext();
  const { setSuccessToast, setErrorToast } = useToastContext();
  const [colorErrorMsg, setColorErrorMsg] = useState('');
  const [borderColorErrorMsg, setBorderColorErrorMsg] = useState('');
  const [colorRequired, setColorRequired] = useState(false);
  const [borderColorRequired, setBorderColorRequired] = useState(false);
  const hexCharLength = 7;
  const colorRegex = /^#([A-Fa-f0-9]{6})$/;

  const handleColorChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setColor(e.target.value);
  };

  const handleBorderColorChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setBorderColor(e.target.value);
  };

  const validateCampusStatusRequest = async (input: CampusStatusRequest) => {
    let isValid = true;
    setBorderColorRequired(false);
    setColorRequired(false);
    input.color = color;
    input.borderColor = borderColor;
    if (status?.label === 'Maintenance') input.label = status.label; 

    if (!input.color.match(/^#/)) input.color = '#' + input.color;
    if (!input.borderColor.match(/^#/)) input.borderColor = '#' + input.borderColor;

    if (!input.label.length){
      setRequiredField(true);
      isValid = false;
    }

    if (!input?.color?.match(colorRegex)) {
      setColorErrorMsg('7 characters only. #123456');
      setColorRequired(true);
      isValid = false;
    } 

    if (!input?.borderColor?.match(colorRegex)) {
      setBorderColorErrorMsg('7 characters only. #123456');
      setBorderColorRequired(true);
      isValid = false;
    } 

    if (input?.color?.length !== hexCharLength) {
      setColorErrorMsg('Color Required');
      setColorRequired(true);
      isValid = false;
    } 

    if (input?.borderColor?.length !== hexCharLength) {
      setBorderColorErrorMsg('Border Color Required');
      setBorderColorRequired(true);
      isValid = false;
    } 

    if (isValid) {
      try {
        if (status)  {
          await editCampusStatus({ ...input, id: status?.id }).unwrap();
          setSuccessToast('Status successfully updated');
        } else {
          await addCampusStatus(input).unwrap();
          setSuccessToast('Status successfully created');
        } 
        closeDialog();
      } catch (err: unknown) {
        setErrorToast(getErrorMessage(err));
      }
    }
    return true;
  };
  
  return (
      <BaseDialog
        open={open}
        title={status ? 'Update Campus Status' : 'Create New Campus Status'}
      >
        <form 
          noValidate 
          onSubmit={async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const fieldValues = Object.fromEntries(formData.entries());

            // Get data from form, then add values from controlled fields (color and borderColor)
            const body: CampusStatusRequest = trimRequest(fieldValues) as unknown as CampusStatusRequest;
            validateCampusStatusRequest(body);
          }}
        >
      <DialogContent>
        <TextInput
          id="label" name="label" label="Label*"
          error={requiredField}
          defaultValue={status ? status.label : blankCampusStatusInput.label}
          helperText='Label Required'
          subtext={status && parseInt(status.id, 10) === 1 ? 'Default normal status - Should reflect normal or all clear status' : ''}
          disabled={dialogType === 'Update' && (status && status.label === 'Maintenance')}
          />
        <TextInput 
          id="description" name="description" label="Description" 
          defaultValue={status ? status.description : blankCampusStatusInput.description}
          />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <HexColorPicker 
              className={colorRequired ? styles.errorBorder : ''}
              aria-label="color" 
              color={color} 
              onChange={(value) => {
                setColor(value);
              }} 
            />
            <TextField
              id="color-input" name="color" label="Color"
              value={color} onChange={(e) => handleColorChange(e)} sx={{ marginTop: '10px' }}
            />
            {colorRequired ? <p className={styles.error}>{colorErrorMsg}</p> : null}
          </Box>
          <Box>
            <HexColorPicker 
              className={borderColorRequired ? styles.errorBorder : ''}
              aria-label="borderColor" 
              color={borderColor} 
              onChange={(value) => {
                setBorderColor(value);
              }} 
            />
            <TextField
              id="borderColor-input" name="borderColor" label="Border Color"
              value={borderColor} onChange={(e) => handleBorderColorChange(e)} sx={{ marginTop: '10px' }}
            />
            {borderColorRequired ? <p className={styles.error}>{borderColorErrorMsg}</p> : null}
          </Box>
        </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>
            Cancel
          </Button>
          <Button type="submit">
            Submit
          </Button>
        </DialogActions>
      </form>
    </BaseDialog>
  );
};
