import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserPreferenceState {
  rowsPerPage: number,
}

const initialState: UserPreferenceState = {
  rowsPerPage: 10,
};

export const userPreferenceSlice = createSlice({
  name: 'userPreference',
  initialState,
  reducers: {
    setRowsPerPage: (state, action: PayloadAction<number>) => {
      state.rowsPerPage = action.payload;
    },
  },
});

export const userPreferenceSliceActions = userPreferenceSlice.actions;

export default userPreferenceSlice;