import { configureStore, PreloadedState } from '@reduxjs/toolkit';
import { persistReducer, 
  PURGE, REGISTER, REHYDRATE, FLUSH, PAUSE, PERSIST,
} from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import socketMiddleware from '../Socket/SocketMiddleware';
import { api } from './api';
import rootReducer from './reducers';

// Using redux-persist to save state to sessionStorage
const persistConfig = {
  key: 'root', 
  storage: storageSession,
  whitelist: ['auth', 'dashboardMap', 'userPreferences'], // ONLY auth, dashboardMap, and userPreferences will be persisted
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: persistReducer(persistConfig, rootReducer),
    middleware: 
      (getDefaultMiddleware) => getDefaultMiddleware({ 
        serializableCheck: {
          // Ignore all the action types redux-persist dispatches:
          // https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      // api.middleware enables caching, invalidation, polling, and other features of RTK Query
      }).concat(api.middleware).concat(socketMiddleware),
    preloadedState: preloadedState,
  });
};

const store = setupStore();

export default store;

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
