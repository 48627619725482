import { DialogContent, DialogActions, Button } from '@mui/material';
import { useDialogContext, useToastContext } from '..';
import { getErrorMessage } from '../../Utils/errorHandling';
import BaseDialog from './BaseDialog';
import { Campus } from '../../TypeScript/AppTypes';
import { useResetSingleCampusMutation, useResetCampusesMutation } from '../../Redux/api';

export interface ResetCampusDialogProps {
  open: boolean,
  campus: string | undefined,
  setEditingCampus: (campus: Campus | null) => void;
}

const ResetCampusDialog = (props: ResetCampusDialogProps): JSX.Element => {

  const { open, campus, setEditingCampus } = props;
  const { setSuccessToast, setErrorToast } = useToastContext();
  const { closeDialog } = useDialogContext();
  const [resetSingleCampus] = useResetSingleCampusMutation();
  const [resetCampuses] = useResetCampusesMutation();

  const handleReset = async () => {
    if (campus) {
      await resetSingleCampus(campus).unwrap();
      setEditingCampus(null);
    } else {
      await resetCampuses().unwrap();
    }
  };

  return (
    <BaseDialog
        open={open}
        title={'Reset Campus'}
      >
        <>
          <DialogContent>
              {
                campus ? 
                  'Are you sure you want to reset status to normal and clear alert?' :
                  'Are you sure you want to reset status to normal and clear alert for all campuses?'
              }
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              if (campus) setEditingCampus(null);
              closeDialog();
            }}>
              No
            </Button>
            <Button 
              type="submit"
              onClick={async () => {
                try {
                  await handleReset();
                  setSuccessToast(campus ? 'Campus Reset' : 'All Campuses Reset');
                  closeDialog();
                } catch (err: unknown) {
                  setErrorToast(getErrorMessage(err));
                }
              }}
            >
              Yes
          </Button>
        </DialogActions>
      </>
    </BaseDialog>
  );
};

export default ResetCampusDialog;