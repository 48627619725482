import React from 'react';
import { ActiveCapMessage } from '../../TypeScript/AppTypes';
import styles from './Cards.module.css';
import { Button } from '@mui/material';
import socketSlice from '../../Redux/Slices/SocketSlice';
import { useAppDispatch } from '../../Redux/hooks';
import { getDateString } from '../../Utils/getDateString';

const ActiveCapCard = (props: { activeCaps: ActiveCapMessage[], closeDialog: () => void }): JSX.Element => {
  const dispatch = useAppDispatch();
  const cancelCapMsg = (id: number) => {
    dispatch(socketSlice.actions.emitCancelCapMsg(id));
    props.closeDialog();
  };

  return (<>
    {
      props.activeCaps.map((msg) => {
        return (
          <div key={msg.id} className={styles.activeCapCard}>
            <p>Message: <strong>{msg.capMsg.headline}</strong></p>
            <p>Recipient: <strong>{msg.campus.name}</strong></p>
            <p>
              Expires: <strong>{getDateString(msg.expire_time)}</strong>
              <Button className={styles.cancelBtn} onClick={() => cancelCapMsg(msg.id)}>
                Cancel
              </Button>
            </p>
          </div>
        );
      })
    }
  </>);
};

export default ActiveCapCard;
