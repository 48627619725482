import BaseDialog from './BaseDialog';
import { useDialogContext } from '..';
import { ActiveCapMessage } from '../../TypeScript/AppTypes';
import ActiveCapCard from '../Cards/ActiveCapCard';
import { Button, DialogActions, DialogContent } from '@mui/material';

export interface CancelCapDialogProps {
  open: boolean,
  activeCapMessages: ActiveCapMessage[],
}

const CancelCapDialog = (props: CancelCapDialogProps): JSX.Element => {
 
  const { open, activeCapMessages } = props;
  const { closeDialog } = useDialogContext();

  return (
    <BaseDialog 
        open={open}
        title={'Active CAP Messages'}
        fullWidth
        maxWidth={'sm'}
    >
      <>
        <DialogContent>
          <ActiveCapCard 
            closeDialog={closeDialog} 
            activeCaps={activeCapMessages} 
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>
            Cancel
          </Button>
        </DialogActions>
      </>
    </BaseDialog>
  );
};

export default CancelCapDialog;