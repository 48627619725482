// eslint-disable-next-line @typescript-eslint/no-var-requires
const stringEntropy = require('fast-password-entropy');

export const checkPasswordValid = (p: string): boolean => {
  const alphaNumericSpecial = /^[a-zA-Z0-9!@#$%^&*]+$/; // only numbers, letters, and !@#$%^&* 
  const atLeastOneNumber = /[0-9]/; 
  const atLeastOneLetter = /[a-zA-Z]/;
  const fourPlusSeqIdentical = /(.)\1{3}/; // no more than three sequential identical chars
  if (p.length > 7 && 
    alphaNumericSpecial.test(p) &&
    atLeastOneLetter.test(p) &&
    atLeastOneNumber.test(p) &&
    !fourPlusSeqIdentical.test(p)) return true;
  else return false;
};

export const checkPasswordNontrivial = (p: string): boolean => {
  if (stringEntropy(p) > 59) return true; // we define nontrivial as +60 bits of entropy
  else return false;
};