import { useEffect, useState } from 'react';
import { LatLngTuple } from 'leaflet';
import { Button, Stack } from '@mui/material';
import DraggableMarker from './DraggableMarker';
import Map from './Map';
import 'leaflet/dist/leaflet.css';
import styles from './CampusMap.module.css';

interface EditCampusMapProps {
  defaultCenterCoords: LatLngTuple,
  defaultZoom: string,
  coords: number[],
  handleCoordsChange: (coords: number[]) => void,
  confirmedCoords: number[],
  setConfirmedCoords: (coords: number[]) => void,
}

const EditCampusMap = (props: EditCampusMapProps): JSX.Element => {

  const { defaultCenterCoords, defaultZoom, coords, handleCoordsChange, confirmedCoords, setConfirmedCoords } = props;
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(true);
  
  useEffect(() => {
    setConfirmButtonDisabled(coords === confirmedCoords);
  }, [coords, confirmedCoords]);

  return (
      <Map
        mapCenter={defaultCenterCoords}
        mapZoom={parseInt(defaultZoom, 10)}
        fullsize
      >
        <>
          <DraggableMarker
            coords={coords}
            handleCoordsChange={handleCoordsChange}
          />
          <Stack direction='row' spacing={1} className={styles.buttonGroup}>
            <Button 
              className={confirmButtonDisabled ? styles.capButtonDisabled : styles.buttonCap}
              variant='contained'
              disabled={confirmButtonDisabled}
              onClick={() => setConfirmedCoords(coords)}
              >
              Confirm Marker Location
            </Button>
          </Stack>
        </>
      </Map>
  );
};

export default EditCampusMap;
