import { DialogContent, DialogActions, Button, DialogContentText } from '@mui/material';
import { useAppDispatch } from '../../Redux/hooks';
import { useDialogContext, useToastContext } from '../../Components';
import { Campus, CampusAlert } from '../../TypeScript/AppTypes';
import { getErrorMessage } from '../../Utils/errorHandling';
import { BaseDialog } from '../../Components';
import styles from '../Notifications/Notifications.module.css';
import socketSlice from '../../Redux/Slices/SocketSlice';

export interface EventTriggerDialogProps {
  open: boolean,
  eventId: number,
  eventName: string | null,
  outCampus: Campus[],
  eventAlert?: CampusAlert
}

const EventTriggerDialog = (props: EventTriggerDialogProps): JSX.Element => {

  const { open, eventId, eventName, outCampus, eventAlert } = props;
  const { closeDialog } = useDialogContext();
  const { setSuccessToast, setErrorToast } = useToastContext();
  const dispatch = useAppDispatch();

  const triggerEvent = (eventTriggerId: number) => {
    try {
      dispatch(socketSlice.actions.emitTriggerEvent({
        eventId: eventTriggerId,
      }));
      setSuccessToast('Event triggered');
      closeDialog();
    } catch (err: unknown) {
      setErrorToast(getErrorMessage(err));
    }
  };

  return (
    <BaseDialog
      open={open}
      title={'Trigger Event'}
    >
      <>
        <DialogContent className={styles.content}>
          Trigger {eventName ? eventName.toLowerCase() : ''}
          { eventAlert &&
            <>
              <DialogContentText className={styles.dialogText}>    
                <span className={styles.span}>Label: </span>
                {eventAlert.label}
              </DialogContentText>
              { 
                eventAlert.description && 
                  <DialogContentText className={styles.dialogText}>
                    <span className={styles.span}>Description: </span>
                    {eventAlert.description}
                  </DialogContentText>
              }
              <DialogContentText className={styles.dialogText}>
                <span className={styles.span}>Campuses: </span>
                {outCampus.map((oc, index) => [
                  index > 0 && ', ', // separates different elements by commas
                  <span key={index}>{oc.name}</span>,
                ])}   
              </DialogContentText>
            </>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={() => { triggerEvent(eventId); }}
          >
            Trigger
          </Button>
        </DialogActions>
      </>
    </BaseDialog>
  );
};

export default EventTriggerDialog;