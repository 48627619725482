import { Button } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Table, useDialogContext } from '..';
import { AppDataLabel } from '../../TypeScript/AppTypes';

export interface CrudTableInterface {
  columnNames: GridColumns,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rowData: { [key: string]: any }[] | undefined,
  error: FetchBaseQueryError | SerializedError | undefined,
  isLoading: boolean,
  dataLabel: AppDataLabel,
  handleRecordSelection: (id?: string) => void,
  createOrEditDialog: JSX.Element, 
  deleteDialog: JSX.Element,
  additionalDialogs?: JSX.Element,
  additionalButtons?: JSX.Element,
  restrictedRole?: boolean,
}

const CrudTable = (props: CrudTableInterface): JSX.Element => {

  const { 
    columnNames, rowData, error, isLoading, 
    dataLabel, handleRecordSelection,
    createOrEditDialog, deleteDialog, additionalDialogs,
    additionalButtons, restrictedRole,
  } = props;

  const { openDialog, dialogType } = useDialogContext();

  const showCreateOrUpdateDialog = (id?: string): void => {
    if (id) {
      handleRecordSelection(id);
      openDialog('Update');
    } else {
      handleRecordSelection(undefined);
      openDialog('Create');
    }
  };

  return (<>
    {dialogType === 'Delete' ?
      <>{deleteDialog}</> : (<></>)}
    {dialogType === 'Create' || dialogType === 'Update' ? 
      <>{createOrEditDialog}</> : (<></>)}
    {additionalDialogs}
    <Table
      buttonField={restrictedRole ? <></> : (<>
        <Button
        aria-label="Create New"
        variant="outlined"
        onClick={() => showCreateOrUpdateDialog()}>
          {`Create New ${dataLabel}`}
        </Button>
        {additionalButtons}
      </>)}
      rowData={rowData}
      error={error}
      isLoading={isLoading}
      columnNames={columnNames}
    />
  </>);
};

export default CrudTable;

