import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Campus, Tag } from '../../TypeScript/AppTypes';
import { LatLngTuple } from 'leaflet';
import { authActions } from './AuthSlice';

export interface DashboardMapState {
  selectedCampuses: Campus[],
  campusSearchTerm: string,
  campusTagSearch: Tag[],
  zoomLevel: number | undefined,
  dashboardMapCenter: LatLngTuple | undefined,
}

const initialState: DashboardMapState = {
  selectedCampuses: [],
  campusSearchTerm: '',
  campusTagSearch: [],
  zoomLevel: undefined,
  dashboardMapCenter: undefined,
};

export const dashboardMapSlice = createSlice({
  name: 'dashboardMap',
  initialState,
  reducers: {
    setZoomLevel: (state, action: PayloadAction<number | undefined>) => {
      state.zoomLevel = action.payload;
    },
    setSelectedCampuses: (state, action: PayloadAction<Campus[]>) => {
      state.selectedCampuses = action.payload;
    },
    setDashboardMapCenter: (state, action: PayloadAction<LatLngTuple | undefined>) => {
      state.dashboardMapCenter = action.payload;
    },
    setCampusSearchTerm: (state, action: PayloadAction<string>) => {
      state.campusSearchTerm = action.payload;
    },
    setCampusTagSearch: (state, action: PayloadAction<Tag[]>) => {
      state.campusTagSearch = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher((action) => authActions.logout.match(action), (state) => {
      state.selectedCampuses = [];
      state.campusSearchTerm = '';
      state.zoomLevel = undefined;
      state.dashboardMapCenter = undefined;
    });
  },
});

export const dashboardMapSliceActions = dashboardMapSlice.actions;

export default dashboardMapSlice;