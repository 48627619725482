import { useCallback, useState } from 'react';
import { AlertColor } from '@mui/material';
import { ToastContext } from './ToastContext';

export interface ToastInterface {
  isOpen: boolean, 
  message: string | undefined,
  severity: AlertColor | undefined,
}

export interface UseToastHookReturn {
  toast: ToastInterface, 
  setSuccessToast: (message: string) => void,
  setInfoToast: (message: string) => void,
  setAlertToast: (message: string) => void,
  setErrorToast: (error: string) => void, 
  clearToast: () => void,
}

const useToast = (): UseToastHookReturn => {

  const [toast, setToast] = useState<ToastInterface>({
    isOpen: false,
    severity: undefined,
    message: '',
  });

  const setSuccessToast = useCallback((message: string) => {
    setToast({
      isOpen: true,
      severity: 'success' as AlertColor,
      message: `${message}.`,
    });
  }, [setToast]);

  const setInfoToast = useCallback((message: string) => {
    setToast({
      isOpen: true,
      severity: 'info' as AlertColor,
      message: `${message}.`,
    });
  }, [setToast]);

  const setAlertToast = useCallback((message: string) => {
    setToast({
      isOpen: true,
      severity: 'warning' as AlertColor,
      message: `${message}.`,
    });
  }, [setToast]);

  const setErrorToast = useCallback((error?: string) => {
    setToast({
      isOpen: true,
      severity: 'error' as AlertColor,
      message: `Error: ${error}.`,
    });
  }, [setToast]);

  const clearToast = useCallback(() => {
    setToast({
      isOpen: false,
      severity: undefined,
      message: '',
    });
  }, [setToast]);

  return { toast, setSuccessToast, setInfoToast, setAlertToast, setErrorToast, clearToast } as const;
};

// eslint-disable-next-line @typescript-eslint/ban-types
const ToastProvider = ({ children }: React.PropsWithChildren<{}>): JSX.Element => {

  const { toast, setSuccessToast, setInfoToast, setAlertToast, setErrorToast, clearToast } = useToast();

  return (
    <ToastContext.Provider value={{ 
      toast, setSuccessToast, setInfoToast, setAlertToast, setErrorToast, clearToast,
    }}>
        {children}
    </ToastContext.Provider>
  );

};

export default ToastProvider;