import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { Button } from '@mui/material';
import { NavItem } from './NavItemInterface';

const ButtonLink = (props: NavItem) => {
  const { title, to } = props;

  const CustomLink = React.useMemo(() =>
    React.forwardRef<HTMLAnchorElement, Omit<LinkProps, 'to'>>((linkProps, ref) => {
      return <Link ref={ref} to={to} {...linkProps} />;
    }),
  [to],
  );

  return (
    <Button color="inherit" component={CustomLink}>
        {title}
    </Button>
  );
};

export default ButtonLink;
