import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);
const appVersion =  process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : '1.0.0';
const copyrightDate = process.env.REACT_APP_COPYRIGHT ? process.env.REACT_APP_COPYRIGHT : '2022';

const Copyright = (): JSX.Element => (
  <>
    <Offset></Offset>
    <AppBar position="fixed" color="transparent" sx={{ top: 'auto', bottom: 0, height: '45px', width: '100%', boxShadow: 0, zIndex: 5000 }}>
      <Toolbar disableGutters={true} sx={{ paddingLeft: '12px' }}>
        <Typography variant="caption" color="inherit" sx={{ flex: 1, opacity: '50%' }}>
          {`VEMASS ${appVersion} Copyright ©${copyrightDate}`}
        </Typography>
      </Toolbar>
    </AppBar>
  </>
);

export default Copyright;
