import { useState } from 'react';
import axios from 'axios';
import { Menu, MenuItem, Tooltip } from '@mui/material';
import { LatLngExpression, LeafletMouseEvent } from 'leaflet';
import { Campus } from '../../TypeScript/AppTypes';
import { CampusMarker } from '../../Leaflet';
import { useDialogContext, useToastContext } from '../../Components';
import { useAppSelector } from '../../Redux/hooks';
import { getErrorMessage } from '../../Utils/errorHandling';
import { ve6025Link } from '../../Utils/constants';

interface DashboardMarkerProps {
  id: string, 
  campusName: string | null,
  coords: LatLngExpression, 
  borderColor: string, 
  color: string,
  isSelected: boolean, 
  campuses: Campus[],
  selectedCampuses: Campus[],
  handleCampusSelectionChange: (selection: Campus[]) => void,
  handleUpdateStateAndCap: (type: 'Campus Alert' | 'Cap Message') => void;
  handleCampusEdit: (id: string) => void,
}

const DashboardMarker = (props: DashboardMarkerProps): JSX.Element => {

  const { id, campusName, coords, borderColor, color, isSelected, 
    campuses, selectedCampuses, handleCampusSelectionChange, 
    handleUpdateStateAndCap, handleCampusEdit } = props;
  const { token, ve6025Username, ve6025Password } = useAppSelector((state) => state.auth); 
  const [contextMenu, setContextMenu] = useState<{
    campus: Campus,
    top: number,
    left: number,
  } | null>(null);
  const { openDialog } = useDialogContext();
  const { setErrorToast } = useToastContext();
  
  const handleRightClick = (e: LeafletMouseEvent) => {
    const campus = campuses.filter((c) => c.id === id)[0];
    setContextMenu(contextMenu === null ? 
      { 
        campus,
        left: e.originalEvent.clientX,
        top: e.originalEvent.clientY,
      } : 
      null);
  };

  const handleContextMenuClose = () => setContextMenu(null);
  
  const handleLeftClick = () => {
    if (isSelected === false){
      const newCampusSelected = campuses.filter((campus) => campus.id === id && !selectedCampuses.includes(campus)); 
      newCampusSelected && handleCampusSelectionChange([...selectedCampuses, ...newCampusSelected]);
    } else {
      handleCampusSelectionChange(selectedCampuses.filter((c) => c.id !== id));
    }
  };

  const handle6025Link = async (link: string) => {
    let username, attempt;
    if (contextMenu?.campus.ve6025_username && contextMenu?.campus.ve6025_password) {
      attempt = contextMenu.campus.ve6025_password;
      username = contextMenu.campus.ve6025_username;
    } else {
      attempt = ve6025Password;
      username = ve6025Username;
    }
    try {
      const { data } = await axios.post('/api/campus/ve6025', { attempt }, { headers: { Authorization: `Bearer ${token}` } });
      window.open(ve6025Link(link, username, data.result));
      handleContextMenuClose();
    } catch (e: unknown) {
      setErrorToast(getErrorMessage(e));
    }
  };
  
  const handleIP6KLink = (link: string) => {
    window.open(link);
    handleContextMenuClose();
  };

  return (
    <Tooltip title={campusName !== null ? campusName : 'Name unavailable'} arrow>
      <div> 
        <CampusMarker 
            key={id} 
            id={id} 
            campusName={campusName !== null ? campusName : 'Name unavailable'}
            coords={coords} 
            borderColor={borderColor}
            color={color}
            campuses={campuses}
            isSelected={isSelected}
            isEnlarged={contextMenu ? true : false}
            handleLeftClick={handleLeftClick}
            handleRightClick={handleRightClick}
            menu={        
              <Menu
                open={contextMenu !== null }
                onClose={handleContextMenuClose}
                className="menu"
                anchorReference="anchorPosition"
                anchorPosition={contextMenu !== null ? { top: contextMenu.top, left: contextMenu.left } : undefined}
              >
                <MenuItem 
                  key='Edit Campus' 
                  className="menuItem" 
                  divider={true}
                  onClick={() => {
                    if (contextMenu) {
                      handleCampusEdit(contextMenu.campus.id);
                      openDialog('Update');
                      handleContextMenuClose();
                    }
                  }}
                >
                  Edit Campus
                </MenuItem>
            {contextMenu && selectedCampuses.includes(contextMenu.campus) ? 
              // only show these menuItems if right-clicked campus is selected
              // send to all selected campuses
              [
                <MenuItem 
                  key='Send Alert' 
                  className="menuItem" 
                  divider={true} 
                  onClick={() => {
                    handleUpdateStateAndCap('Campus Alert');
                    handleContextMenuClose(); 
                  }}>
                  Send Alert
                </MenuItem>,
                <MenuItem 
                  key='Cap Message' 
                  className="menuItem" 
                  divider={true} 
                  onClick={() => {
                    handleUpdateStateAndCap('Cap Message');
                    handleContextMenuClose();
                  }}>
                  Cap Message
                </MenuItem>,
              ] : []}
              {contextMenu && contextMenu.campus.fqdn && ((ve6025Username && ve6025Password) || (contextMenu.campus.ve6025_username && contextMenu.campus.ve6025_password)) ? 
                <MenuItem 
                  key='VE6025 Link'
                  className="menuItem"
                  divider={true} 
                  onClick={() => { if (contextMenu.campus.fqdn) handle6025Link(contextMenu.campus.fqdn); }}
                >
                  VE6025 Link
                </MenuItem> : []}
              {contextMenu && contextMenu.campus.ip_six !== '' ?
                <MenuItem 
                  key='IP6000 Link'
                  className="menuItem" 
                  divider={true} 
                  onClick={() => handleIP6KLink(contextMenu.campus.ip_six)}
                >
                  IP6000 Link
                </MenuItem> : []}
              {contextMenu && ((contextMenu.campus.status !== null && parseInt(contextMenu.campus.status.id, 10) !== 1) || contextMenu.campus.alert !== null) ?
                <MenuItem 
                  key='Reset Status/Alert'
                  className="menuItem" 
                  divider={true} 
                  onClick={() => {
                    if (contextMenu) {
                      handleCampusEdit(contextMenu.campus.id);
                      openDialog('Reset Campus');
                      handleContextMenuClose();
                    }
                  }
                }
                >
                  Reset Status/Alert
                </MenuItem> : []}
              </Menu>}
        />
      </div>
    </Tooltip>
  );
};
export default DashboardMarker;
