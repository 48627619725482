import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification } from '../../TypeScript/AppTypes';
 
export interface StatusUpdateState {
  pending: string[],
  successful: string[],
  statusNotifs: Notification[],
}
 
const initialState: StatusUpdateState = {
  pending: [],
  successful: [],
  statusNotifs: [],
};
 
export const statusUpdateSlice = createSlice({
  name: 'statusUpdate',
  initialState,
  reducers: {
    addPending: (state, action: PayloadAction<string>) => {
      state.pending.push(action.payload);
    },
    addSuccess: (state, action: PayloadAction<string>) => {
      state.successful.push(action.payload);
    },
    setStatusNotifs: (state, action: PayloadAction<Notification[]>) => {
      state.statusNotifs = action.payload;
    },
    addStatusNotifs: (state, action: PayloadAction<Notification>) => {
      state.statusNotifs.push(action.payload);
    },
    removeStatusNotif: (state, action: PayloadAction<Notification>) => {
      state.statusNotifs = state.statusNotifs.filter((statusNotif) => {
        return statusNotif.id !== action.payload.id;
      });
    },
    clearStatusNotifs: (state) => {
      state.statusNotifs = [];
    },
    clearAll: (state) => {
      state.pending = [];
      state.successful = [];
    },
  },
});
 
export const statusUpdateActions = statusUpdateSlice.actions;
 
export default statusUpdateSlice;