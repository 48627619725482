import { useState } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Checkbox, Collapse, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, 
} from '@mui/material';
import styles from './DashboardTable.module.css';
import { getDistrictName } from '../../Utils/valueGetters';
import { Campus } from '../../TypeScript/AppTypes';
import { Edit, RestartAlt } from '@mui/icons-material';
import { useDialogContext } from '../../Components/Dialogs/DialogContext';
import { useAppSelector, useAppDispatch } from '../../Redux/hooks';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getErrorMessage } from '../../Utils/errorHandling';
import { useToastContext } from '../../Components';
import { ve6025Link } from '../../Utils/constants';
import { userPreferenceSliceActions } from '../../Redux/Slices/UserPreferences';

interface DashboardTableProps {
  selectedCampuses: Campus[],
  handleCampusSelectionChange: (selection: Campus[]) => void,
  matchedCampuses: Campus[],
  handleCampusEdit: (id: string) => void,
}

export const dashboardColumns = [
  { field: 'id', headerName: 'ID', width: 120  },
  { field: 'name', headerName: 'Campus Name', width: 275  },
  { field: 'address', headerName: 'Address', width: 100  },
  { field: 'city', headerName: 'City', width: 120  },
  { field: 'state', headerName: 'State', width: 75  },
  { field: 'postal', headerName: 'Zip', width: 80  },
  { field: 'telephone', headerName: 'Phone Number', width: 150  },
  { field: 'district', headerName: 'Campus District', valueGetter: getDistrictName, width: 275 },
];

const editHeading = { field: 'edit', headerName: 'Modify Campus', width: 120 };

const DashboardTable = (props: DashboardTableProps): JSX.Element => {

  const { token, roles, ve6025Username, ve6025Password } = useAppSelector((state) => state.auth);
  const { rowsPerPage } = useAppSelector((store) => store.userPreferences);
  const { selectedCampuses, handleCampusSelectionChange, matchedCampuses, handleCampusEdit } = props;
  const { setErrorToast } = useToastContext();
  const [page, setPage] = useState(0);
  const { openDialog } = useDialogContext();
  const selectedIds = selectedCampuses.map((campus) => campus.id);
  const [open, setOpen] = useState<number | string>(-1);
  const dispatch = useAppDispatch();

  const combinedCampuses = [...selectedCampuses, ...matchedCampuses.filter((campus) => {
    const selectedVal = selectedCampuses.find(c => c.id === campus.id);
    if (selectedVal) selectedCampuses[selectedCampuses.indexOf(selectedVal)] = campus;
    return !selectedIds.includes(campus.id);
  })];

  const nonAdminSecurity = roles.includes('security') && !roles.includes('admin');

  const handlePageChange = (e: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>  {
    dispatch(userPreferenceSliceActions.setRowsPerPage(parseInt(e.target.value, 10)));
    setPage(0);
  };

  const handle6025Link = async (campus: Campus) => {
    let username, attempt;
    if (campus?.ve6025_username && campus?.ve6025_password) {
      username = campus.ve6025_username;
      attempt = campus.ve6025_password;
    } else {
      username = ve6025Username;
      attempt = ve6025Password;
    }
    try {
      const { data } = await axios.post('/api/campus/ve6025', { attempt }, { headers: { Authorization: `Bearer ${token}` } });
      window.open(ve6025Link(campus.fqdn, username, data.result));
    } catch (e: unknown) {
      setErrorToast(getErrorMessage(e));
    }
  };

  return (<>
  <TableContainer>
    <Table className={styles.dashboardTable} size='small'>
      <TableHead>
        <TableRow>
          <TableCell ></TableCell>
          <TableCell ></TableCell>
          { nonAdminSecurity ? dashboardColumns
          .map((column: any) => {//eslint-disable-line
              return (
            <TableCell 
              variant='head' 
              className={styles.headerStyle} 
              key={column.headerName}>
                {column.headerName}
            </TableCell>);
            }) : [editHeading, ...dashboardColumns].map((column: any) => {//eslint-disable-line
            return (
            <TableCell 
              variant='head' 
              className={styles.headerStyle} 
              key={column.headerName}>
                {column.headerName}
            </TableCell>);
          })}

        </TableRow>
      </TableHead>
      <TableBody>
        {combinedCampuses.length ? combinedCampuses.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((c) => {
          const show6025 = (c.fqdn && ((c.ve6025_username && c.ve6025_password) || (ve6025Username && ve6025Password)));
          return ([
          <TableRow key={c.id} hover sx={{ '& > *': { borderBottom: 'unset !important' } }}>
            {show6025 || c.ip_six ? <TableCell>
              <IconButton
                aria-label='expand row'
                size='small'
                onClick={() => setOpen(open.toString() === c.id ? -1 : c.id)}>
                  {open.toString() === c.id
                    ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                </IconButton>
            </TableCell> : <TableCell></TableCell>}
            <TableCell>
              <Checkbox  
                aria-label='check'
                value={c.id} 
                checked={selectedCampuses.find(campus => campus.id === c.id) !== undefined}
                onChange={(e) => {
                  if (!selectedCampuses.find(campus => campus.id === c.id)){
                    handleCampusSelectionChange([...selectedCampuses, c]);
                  } else {
                    handleCampusSelectionChange(selectedCampuses.filter((selected) => selected.id !== c.id));
                  }
                }}/>
            </TableCell>
            {
              !nonAdminSecurity ? (
              <TableCell>
                <div className={styles.modifyButtons}>
                <Edit sx={{ cursor: 'pointer' }} onClick={e => {handleCampusEdit(c.id as string);openDialog('Update');}}/>
                {
                  ((c.status !== null && parseInt(c.status.id, rowsPerPage) !== 1) || c.alert) && 
                  <RestartAlt sx={{ cursor: 'pointer' }} onClick={e => {handleCampusEdit(c.id as string);openDialog('Reset Campus');}}
                  />
                }
                </div>
              </TableCell>
              ) : null
            }
            

            <TableCell>{c.id}</TableCell>
            <TableCell className={styles.nameCell}>{c.name}</TableCell>
            <TableCell className={styles.nameCell}>{c.address}</TableCell>
            <TableCell className={styles.cityCell}>{c.city}</TableCell>
            <TableCell>{c.state}</TableCell>
            <TableCell>{c.postal}</TableCell>
            <TableCell>{c.telephone}</TableCell>
            <TableCell>{c.district.name}</TableCell>
          </TableRow>,
          <TableRow key={c.name} sx={{ '& > *': { borderBottom: 'unset !important' } }}>
            {show6025 || c.ip_six ? <TableCell className={styles.collapse} colSpan={12}>
              <Collapse 
                in={open.toString() === c.id ? true : false} 
                timeout='auto' 
                unmountOnExit>
                <Box className={styles.collapsibleRow}> 
                  <Stack direction='row' className={styles.loginButtons} spacing={2}>
                    <Typography className={styles.loginCampus}>{c.name}:</Typography>
                    {show6025 ? 
                      <Button
                        variant='outlined'
                        className={styles.loginButtons}
                        onClick={() => handle6025Link(c)}
                        >
                          ve6025 Login
                        </Button> : <></>}
                    {c.ip_six ? 
                      <Button
                        variant='outlined'
                        className={styles.loginButtons}
                        onClick={() => window.open(c.ip_six)}
                      >
                        ip6000 Login
                      </Button> : <></>}
                  </Stack>
                </Box>
              </Collapse>
            </TableCell> : null}
          </TableRow>,
          ]);
        }) : <TableRow><TableCell className={styles.errorMsg}>No Matching Campuses</TableCell></TableRow>}
      </TableBody>
    </Table>
  </TableContainer>
  <TablePagination
    count={combinedCampuses.length}
    onPageChange={handlePageChange}
    page={page}
    rowsPerPage={rowsPerPage}
    onRowsPerPageChange={handleChangeRowsPerPage}
    rowsPerPageOptions={[10, 25, 50]}
    component='div'/>
  </>); 
};

export default DashboardTable;