import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification } from '../../TypeScript/AppTypes';
 
export interface InboxState {
  unreadNotifications: Notification[],
}
 
const initialState: InboxState = {
  unreadNotifications: [],
};
 
export const inboxSlice = createSlice({
  name: 'inbox',
  initialState,
  reducers: {
    emitRemoveSingleUnreadNoti: (state, action: PayloadAction<{ userId: number, notiId: number }>) => {
      state.unreadNotifications = state.unreadNotifications.filter((unreadNotif) => {
        return parseInt(unreadNotif.id, 10) !== action.payload.notiId;
      });
    }, 
    setUnreadNotifications: (state, action: PayloadAction<Notification[]>) => {
      state.unreadNotifications = action.payload;
    },
    clearUnreadNotifications: (state) => {
      state.unreadNotifications = [];
    },
  },
});
 
export const inboxActions = inboxSlice.actions;
 
export default inboxSlice;