import React from 'react';
import { useLocation } from 'react-router-dom';
import { useGetRolesQuery } from '../../Redux/api';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';
import { Table } from '../../Components';
import styles from './Roles.module.css';

const Roles = (): JSX.Element => {

  const { data, error, isLoading } = useGetRolesQuery();
  const location = useLocation();
  const headerLocation = (((location.pathname).slice(1, 2)).toUpperCase() + (location.pathname).slice(2));

  return (<>
    <h1 className={styles.headerName}>{headerLocation}</h1>
    <Tooltip title='View all present roles in your District Server' arrow>
      <InfoIcon />
    </Tooltip>
      <Table 
        isLoading={isLoading} 
        error={error}
        rowData={data} 
        columnNames={[
          { field: 'id', headerName: 'ID', flex: 1, hide: true },
          { field: 'name', headerName: 'Name', flex: 2 },
          { field: 'description', headerName: 'Description', flex: 3 },
        ]} 
      />
    </>);
};

export default Roles;
