/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DialogContent, DialogActions, Button, Checkbox, FormControlLabel } from '@mui/material';
import { CapMessage, CapMessageRequest } from '../../TypeScript/AppTypes';
import { useAddCapMessageMutation, useEditCapMessageMutation } from '../../Redux/api';
import { useDialogContext, TextInput, BaseDialog, useToastContext, ErrorFocus, DropdownPicker } from '../../Components';
import { getErrorMessage } from '../../Utils/errorHandling';
import { trimRequest } from '../../Utils/trimRequest';
import { useEffect, useState } from 'react';
import { dynamicValueList } from '../../Utils/dynamicCapVariables';

export const blankCapMessageInput: Partial<CapMessageRequest> = {
  label: '',
  headline: '',
  instruction: '',
  description: '',
  expires: 0,
};

export const CapMessageFormDialog = (props: {
  open: boolean,
  cap?: CapMessage
}): JSX.Element => {

  const { open, cap } = props;
  const [addCapMessage] = useAddCapMessageMutation();
  const [editCapMessage] = useEditCapMessageMutation();
  const { closeDialog } = useDialogContext();
  const { setSuccessToast, setErrorToast } = useToastContext();
  const [dynamicCap, setDynamicCap] = useState(cap && cap.dynamic ? cap.dynamic : false);
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (cap?.description) {
      const nameRe = /{NAME}/g;
      const addressRe = /{ADDR}/g;
      let capDesc = cap.description;
      capDesc = capDesc.replace(nameRe, 'CAMPUS_NAME');
      capDesc = capDesc.replace(addressRe, 'CAMPUS_ADDRESS');
      setDescription(capDesc);
    }
  }, [cap]);

  const [errors, setErrors] = useState<{
    label: boolean,
    headline: boolean,
    instruction: boolean,
    description: boolean,
  }>({
    label: false,
    headline: false,
    instruction: false,
    description: false,
  });

  const validateCapMessageRequest = (input: CapMessageRequest) => {
    let isLabelInvalid = false;
    let isHeadlineInvalid = false;
    let isInstructionInvalid = false;
    let isDescriptionInvalid = false;

    if (!input.label?.length) isLabelInvalid = true;
    if (!input.headline?.length) isHeadlineInvalid = true;
    if (!input.instruction?.length) isInstructionInvalid = true;
    if (!input.description?.length) isDescriptionInvalid = true;

    setErrors({
      label: isLabelInvalid,
      headline: isHeadlineInvalid,
      instruction: isInstructionInvalid,
      description: isDescriptionInvalid,
    });

    if (isLabelInvalid || isHeadlineInvalid || isInstructionInvalid || isDescriptionInvalid) {
      return false;
    } return true;
  };

  return (
    <BaseDialog
      open={open}
      title={cap ? 'Update Cap Message' : 'Create New Cap Message'}
    >
      <form
        noValidate
        onSubmit={async (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const fieldValues = Object.fromEntries(formData.entries());
          // Get data from form
          const body: CapMessageRequest = trimRequest(fieldValues) as unknown as CapMessageRequest;
          const isValidRequest = validateCapMessageRequest(body);
          if (!body.expires || body.expires < 0) body.expires = 0;
          body.dynamic = dynamicCap;
          const nameRe = /CAMPUS_NAME/g;
          const addressRe = /CAMPUS_ADDRESS/g;
          body.description = body.description!.replace(nameRe, '{NAME}');
          body.description = body.description!.replace(addressRe, '{ADDR}');

          if (isValidRequest) {
            try {
              if (cap) {
                await editCapMessage({ id: cap.id, ...body }).unwrap();
                setSuccessToast('CAP message successfully updated');
              } else {
                await addCapMessage(body).unwrap();
                setSuccessToast('CAP message successfully created');
              }
              closeDialog();
            } catch (err: unknown) {
              setErrorToast(getErrorMessage(err));
            }
          }
        }}
      >
        <DialogContent>
          <FormControlLabel
            control={<Checkbox checked={dynamicCap} onChange={() => setDynamicCap(!dynamicCap)} />}
            name='dynamic'
            label='Dynamic Message'
          />
          <TextInput
            id="label" name="label" label="Label"
            defaultValue={cap ? cap.label : blankCapMessageInput.label}
            required
            error={errors.label}
            helperText="Label required"
          />
          <TextInput
            id="headline" name="headline" label="Headline"
            defaultValue={cap ? cap.headline : blankCapMessageInput.headline}
            required
            error={errors.headline}
            helperText="Headline required"
          />
          <TextInput
            id="instruction" name="instruction" label="Instruction"
            defaultValue={cap ? cap.instruction : blankCapMessageInput.instruction}
            required
            error={errors.instruction}
            helperText="Instructions required"
          />
          <TextInput
            id="description" name="description" label="Description"
            value={description}
            defaultValue={undefined}
            required
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            error={errors.description}
            helperText="Description required"
            inputProps={dynamicCap ? { endAdornment: <DropdownPicker
              label="Add Dynamic Value"
              options={dynamicValueList.map((val) => val.showValue)}
              onChange={(e) => {
                let newPlaceholder = e.target.value;
                if (!description.endsWith(' ') && description.length) newPlaceholder = ' ' + newPlaceholder;
                setDescription(description + newPlaceholder);
              }}
              /> } : undefined}
          />
          <TextInput
            id="phone_number" name="phone_number" label="Phone Number"
            defaultValue={cap ? cap.phone_number : blankCapMessageInput.phone_number}
            subtext='Phone number for use with mobile activation'
          />
          <TextInput
            id="expires" name="expires" label="Expires" type="number"
            defaultValue={cap ? cap.expires : blankCapMessageInput.expires}
            subtext='CAP message duration in seconds'
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>
            Cancel
          </Button>
          <Button type="submit">
            Submit
          </Button>
        </DialogActions>
        <ErrorFocus errors={errors} />
      </form>
    </BaseDialog>
  );
};
