import { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { useDialogContext } from './DialogContext';
import styles from './Dialog.module.css';
import { useAppSelector, useAppDispatch } from '../../Redux/hooks';
import { statusUpdateActions } from '../../Redux/Slices/StatusUpdateSlice';
import inboxSlice, { inboxActions } from '../../Redux/Slices/InboxSlice';
import { Notification } from '../../TypeScript/AppTypes';

export interface StatusChangeDialogProps {
  open: boolean;
}

export const StatusChangeDialog = (props: StatusChangeDialogProps): JSX.Element => {

  const { open } = props;
  const { closeDialog } = useDialogContext();
  const { statusNotifs } = useAppSelector((state) => state.statusUpdate);
  const { id } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [ updateShown, setUpdateShown ] = useState<Notification | null>(null);

  useEffect(() => {
    if (statusNotifs) {
      setUpdateShown(statusNotifs[0]);
    }
  }, [statusNotifs]);

  const handleClose = (event: object, reason: string):void => {
    if (reason && reason === 'backdropClick') return;
  };

  const handleStatusNotifs = (statusNotif: Notification) => {
    if (statusNotifs.length === 1) {
      closeDialog();
    }
    if (id) dispatch(inboxActions.emitRemoveSingleUnreadNoti({ userId: parseInt(id, 10), notiId: parseInt(statusNotif.id, 10) }));
    dispatch(statusUpdateActions.removeStatusNotif(statusNotif));
  };

  return (
    updateShown ? (
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={'lg'}
        fullWidth
      >
        <DialogTitle className={styles.dialogTitle}>
          { updateShown.content && updateShown.content.includes('Status Update:') ? 'Incoming Status Update' : 'Incoming Maintenance Update'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {updateShown.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => handleStatusNotifs(updateShown)}>
            {statusNotifs.length > 1 ? 'Acknowledge and View Next' : 'Acknowledge'}
          </Button>
          {
            statusNotifs.length > 1 ? <Button variant='outlined' onClick={() => dispatch(inboxSlice.actions.clearUnreadNotifications()) }>
            Acknowledge All
          </Button> : <></>
          }
        </DialogActions>
      </Dialog>
    ) : <></>
  );
};

export default StatusChangeDialog;