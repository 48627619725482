import { useState } from 'react';
import { Autocomplete, TextField } from '@mui/material/';

// pass defaultValue to use as uncontrolled,
// pass value and onChange function to use as controlled
export interface DropdownInterface {
  id: string,
  label: string,
  name?: string
  required?: boolean,
  error?: boolean,
  helperText?: string,
  value?: string | string[] | null,
  defaultValue?: string | string[] | null,
  options: string[],
  onChange?: (e: React.SyntheticEvent<Element, Event>, 
    newValue: string | string[] | (string | string[])[] | null) => void,
  isMultiSelect?: boolean,
  subtext?: string,
  notFull?: boolean,
}

const Dropdown = ( props: DropdownInterface): JSX.Element => {

  const { id, name, label, required, error, helperText,
    value, defaultValue, options, onChange, isMultiSelect, subtext, notFull,
  } = props; 

  const [open, setOpen] = useState(false);

  return (
    <Autocomplete
      id={id}
      value={value}
      defaultValue={defaultValue}
      options={options}
      multiple={isMultiSelect}
      onChange={onChange}
      isOptionEqualToValue={(option, currentValue) => option === currentValue}
      filterSelectedOptions
      fullWidth={!notFull}
      sx={notFull ? { width: 400 } : {}}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      renderInput={(params) => (
        <TextField
        {...params}
          name={name ? name : id}
          label={label}
          margin="dense"
          required={required}
          error={error}
          helperText={error ? helperText : (subtext ? subtext : undefined)}
          onClick={(e) => { 
            e.stopPropagation();
          }}
        />
      )}
    />
  );
};

export default Dropdown;
