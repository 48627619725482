import { createSlice } from '@reduxjs/toolkit';
import { api } from '../api';

export interface LoginState {
  id: string | null,
  username: string | null,
  ve6025Username: string | null,
  ve6025Password: string | null,
  token: string | null,
  roles: string[],
}
  
const initialState: LoginState = {
  id: null,
  username: null,
  ve6025Username: null,
  ve6025Password: null,
  token: null,
  roles: [],
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.id = null;
      state.username = null;
      state.ve6025Username = null;
      state.ve6025Password = null;
      state.token = null;
      state.roles = [];
    },
    deleteCredentials: (state) => {
      state.id = null;
      state.username = null;
      state.ve6025Username = null;
      state.ve6025Password = null;
      state.token = null;
      state.roles = [];
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.login.matchFulfilled, (state, action) => {
      state.id = action.payload.id;
      state.username = action.payload.username;
      state.ve6025Username = action.payload.ve6025_username || null;
      state.ve6025Password = action.payload.ve6025_password || null;
      state.roles = action.payload.roles.map((role) => role.name);
      state.token = action.payload.token;
    });
    // if logged-in user is edited, update auth
    builder.addMatcher(api.endpoints.editUser.matchFulfilled, (state, action) => {
      if (action.payload.id === state.id) {
        state.username = action.payload.username;
        state.ve6025Username = action.payload.ve6025_username || null;
        state.ve6025Password = action.payload.ve6025_password || null;
        state.roles = action.payload.roles.map((role) => role.name);
      }
    });
  },
});

export const authActions = authSlice.actions;
  
export default authSlice.reducer;