/*eslint-disable react-hooks/exhaustive-deps*/
import { TableContainer, TableHead, TableRow, TableCell, Table, TableBody, Checkbox, Paper, DialogContent, 
  Typography, Switch, Stack, Button, DialogActions, IconButton, InputBase, Divider, Menu, MenuItem, ThemeProvider } from '@mui/material';
import { LatLngTuple } from 'leaflet';
import { useEffect, useState } from 'react';
import { BaseDialog, Dropdown, ErrorFocus, TextInput, useDialogContext, useToastContext } from '../../Components';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { EventDialogMap } from './EventDialogMap';
import { useGetCampusStatusesQuery, useGetUsersQuery, useGetCampusAlertsQuery, useGetCapMessagesQuery,
  useAddEventMutation, useEditEventMutation } from '../../Redux/api';
import { Campus, CampusAlert, CampusEvent, CampusEventRequest, CampusStatus, User } from '../../TypeScript/AppTypes';
import styles from './EventFormDialog.module.css';
import { trimRequest } from '../../Utils/trimRequest';
import { getErrorMessage } from '../../Utils/errorHandling';
import { theme } from '../../TypeScript/BreakpointTypes';

export const BlankEventRequest : Partial<CampusEventRequest> = {
  label: '',
  description: '',
  radius: 0,
  incomingStatus: null,
  outgoingAlert: null,
  users: [],
  cap: null,
};

interface EventFormDialogProps {
  open: boolean,
  campuses: Campus[],
  defaultCenterCoords: LatLngTuple,
  defaultZoom: number,
  event?: CampusEvent
}

export const EventFormDialog = (props: EventFormDialogProps) => {
  const { open, campuses, defaultCenterCoords, defaultZoom, event } = props;
  const { closeDialog } = useDialogContext();
  const { data: statuses } = useGetCampusStatusesQuery();
  const { data: campusAlerts } = useGetCampusAlertsQuery();
  const { data: users } = useGetUsersQuery();
  const { data: caps } = useGetCapMessagesQuery();
  const [addEvent] = useAddEventMutation();
  const [editEvent] = useEditEventMutation();
  const { setSuccessToast, setErrorToast } = useToastContext();
  
  const [incomingCampuses, setIncomingCampuses] = useState<Campus[]>([]);
  const [outgoingCampuses, setOutgoingCampuses] = useState<Campus[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [geoEnabled, setGeoEnabled] = useState<boolean>(false);
  const [tableCampuses, setTableCampuses] = useState<Campus[]>(campuses);
  const [tableFilter, setTableFilter] = useState('All');
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [filteredCampuses, setFilteredCampuses] = useState<Campus[]>(campuses);
  const [newEventStatus, setNewEventStatus] = useState<CampusStatus>();
  const [newEventAlert, setNewEventAlert] = useState<CampusAlert>();
  const [exampleRadius, setExampleRadius] = useState<number>(0);
  const [exampleCampus, setExampleCampus] = useState<Campus>();
  const [selectedUsers, setSelectedUsers] = useState<User[]>(event ? event.users as User[] : []);
  const [errors, setErrors] = useState<{ label: boolean }>({ label: false });

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, []);

  useEffect(() => {
    if (event){
      event.incomingCampuses && setIncomingCampuses(event.incomingCampuses);
      event.outgoingCampuses && setOutgoingCampuses(event.outgoingCampuses);
      event.outgoingAlert && setNewEventAlert(event.outgoingAlert as CampusAlert);
      event.incomingStatus && setNewEventStatus(event.incomingStatus as CampusStatus);
      event.radius && setExampleRadius(event.radius);
    }
  }, [event]);

  useEffect(() => {
    if (tableFilter === 'Outgoing Campuses'){
      setTableCampuses(outgoingCampuses);
      const filteredOutgoingCampuses = Array.from(new Set([...outgoingCampuses]));
      setFilteredCampuses(filteredOutgoingCampuses.filter(c => c.name?.toLowerCase().includes(searchText.toLowerCase())));
    } else if (tableFilter === 'Incoming Campuses'){
      setTableCampuses(incomingCampuses);
      const filteredIncomingCampuses = Array.from(new Set([...incomingCampuses]));
      setFilteredCampuses(filteredIncomingCampuses.filter(c => c.name?.toLowerCase().includes(searchText.toLowerCase())));
    } else if (tableFilter === 'Show Selected'){
      const filteredCampusArr = Array.from(new Set([...incomingCampuses, ...outgoingCampuses]));
      setTableCampuses(filteredCampusArr);
      setFilteredCampuses(filteredCampusArr.filter(c => c.name?.toLowerCase().includes(searchText.toLowerCase())));
    } else {
      setTableCampuses(campuses);
      setFilteredCampuses(campuses.filter(c => c.name?.toLowerCase().includes(searchText.toLowerCase())));
    }
  }, [tableFilter, incomingCampuses, outgoingCampuses]); 
  
  const handleSearchFilter = (textSearched: string) => {
    setSearchText(textSearched);
    setFilteredCampuses(tableCampuses.filter(c => c.name?.toLowerCase().includes(textSearched.toLowerCase())));
  };
  
  const handleFilterMenuClose = () => {
    setMenuAnchor(null);
    setMenuOpen(false);
  };

  const handleGeoEnableRendering = () => {
    if (geoEnabled && exampleRadius === 0){
      return <Typography className={styles.exampleHelperText}>Radius Required To Show Example</Typography>;
    } else if (geoEnabled && exampleRadius !== 0 ){
      return (
        <Dropdown
          id="exampleCampus"
          name="exampleCampus"
          label="Choose Example Campus"
          options={campuses.map(c => c.name ? c.name : '').filter(n => n !== '')}
          defaultValue={campuses[0].name}
          onChange={(e, newValue) => setExampleCampus(campuses.filter(c => c.name === newValue)[0])}
        />);
    }
  };
  
  const validateEventRequest = (input: CampusEvent) => {
    let isNameInvalid = false;
    if (!input.label?.length) isNameInvalid = true;
    setErrors({ label: isNameInvalid });
    if (isNameInvalid) return false;
    return true;
  };
  return (
    <BaseDialog
      open={open}
      title={event ? 'Update Event' : 'Create New Event'}
      maxWidth='lg'
      fullWidth={true}
    >
      <form 
            noValidate
            onSubmit={async (e: React.FormEvent<HTMLFormElement>) => {
              e.preventDefault();
              const formData = new FormData(e.currentTarget);
              const fieldValues = Object.fromEntries(formData.entries());
    
              // Get data from form, then add values from controlled multi-select field (tags) 
              // and match ids with value of uncontrolled dropdowns (status and district)
              const body: CampusEventRequest = trimRequest(fieldValues) as unknown as CampusEventRequest;
              const selectedAlert = campusAlerts?.filter(a => a.label === body.outgoingAlert?.toString())[0] || undefined;
              const selectedStatus = statuses?.filter(s => s.label === body.incomingStatus?.toString())[0] || undefined;
              const selectedCap = caps?.filter(cap => cap.label === body.cap?.toString())[0] || undefined;
              body.incomingCampuses = incomingCampuses.length > 0 ? incomingCampuses.map(c => parseInt(c.id)) : [];
              body.outgoingCampuses = outgoingCampuses.length > 0 ? outgoingCampuses.map(oc => parseInt(oc.id)) : [];
              body.users = selectedUsers.length > 0 ? selectedUsers.map(t => parseInt(t.id)) : [];
              selectedStatus ? body.incomingStatus = parseInt(selectedStatus.id) : delete body.incomingStatus;
              selectedAlert ?  body.outgoingAlert = parseInt(selectedAlert.id) : delete body.outgoingAlert;
              selectedCap ? body.cap = selectedCap.id : delete body.cap;
              if (body.radius && body.radius < 0) body.radius = 0;

              const isValidRequest = validateEventRequest(fieldValues as unknown as CampusEvent);
    
              if (isValidRequest) {
                try {
                  if (event) {
                    await editEvent({ ...body, id: event.id as string }).unwrap();
                    setSuccessToast('Campus Event successfully updated');
                  } else {
                    await addEvent(body).unwrap();
                    setSuccessToast('Campus Event successfully created');
                  }
                  closeDialog();
                } catch (err: unknown) {
                  setErrorToast(getErrorMessage(err));
                }
              }
            }
            }>
          <DialogContent>
            <ThemeProvider theme={theme}>
              <Stack direction={{ md: 'row', xs: 'column' }} spacing={3}>
                <Stack direction='column' width={{ md:'45vw', xs: '100%' }}>
                  <EventDialogMap 
                    campuses={campuses || []} 
                    defaultCenterCoords={defaultCenterCoords as LatLngTuple} 
                    defaultZoom={defaultZoom}
                    outgoingCampuses={outgoingCampuses}
                    incomingCampuses={incomingCampuses}
                    exampleRadius={exampleRadius}
                    showExample={geoEnabled}
                    exampleCampus={exampleCampus as Campus}
                    setOutgoingCampuses={setOutgoingCampuses}
                    setIncomingCampuses={setIncomingCampuses}
                    newEventStatus={newEventStatus as CampusStatus}
                    newEventAlert={newEventAlert as CampusAlert}
                  />
                  {(geoEnabled && exampleRadius !== 0) ? <>
                    <Typography className={styles.mapHelperText}>* <span className={styles.exampleColor}></span>Radius based off {exampleCampus ? exampleCampus.name : campuses[0].name}</Typography> <br/>
                    </>
                    : <></>}
                  <Stack
                    className={styles.geoEnable}
                    direction="row"
                    spacing={2}
                    alignItems="center"
                  >
                    <Stack direction='row' spacing={2}>
                      <Typography noWrap>Toggle Radius Preview</Typography>
                      <Switch
                        size="small"
                        onChange={() =>
                          geoEnabled === false
                            ? setGeoEnabled(true)
                            : setGeoEnabled(false)
                        }
                      />
                    </Stack>
                    {handleGeoEnableRendering()}
                  </Stack>
                </Stack>
                <Stack direction='column' width={{ md: '55vw', xs: '100%' }} height={{ md:'71vh', xs: '100%' }} className={styles.formStack}>
                  <Paper
                    sx={{
                      p: '2px 4px',
                      display: 'flex',
                      alignItems: 'center',
                      width: 400,
                    }}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search Campus Table"
                      inputProps={{ 'aria-label': 'search campuses' }}
                      onChange={(e) => handleSearchFilter(e.target.value)}
                    />
                    <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                      <SearchIcon />
                    </IconButton>
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <IconButton
                      type="button"
                      sx={{ p: '10px' }}
                      aria-label="search"
                      onClick={(e) => {
                        setMenuOpen(true);
                        setMenuAnchor(e.currentTarget);
                      }}
                    >
                      <FilterAltOutlinedIcon />
                    </IconButton>
                    <Menu
                      id="filterMenu"
                      open={menuOpen}
                      onClose={handleFilterMenuClose}
                      anchorEl={menuAnchor}
                    >
                      <MenuItem
                        data-testid='all'
                        onClick={(e) => {
                          setTableFilter(e.currentTarget.innerText);
                          handleFilterMenuClose();
                        }}
                      >
                        All
                      </MenuItem>
                      <MenuItem data-testid='incoming' onClick={(e) => {
                        setTableFilter(e.currentTarget.innerText);
                        handleFilterMenuClose();
                      }}>
                          Incoming Campuses
                      </MenuItem>
                      <MenuItem data-testid='outgoing' onClick={(e) => {
                        setTableFilter(e.currentTarget.innerText);
                        handleFilterMenuClose();
                      }}>
                          Outgoing Campuses
                      </MenuItem>
                      <MenuItem data-testid='selected' onClick={(e) => {
                        setTableFilter(e.currentTarget.innerText);
                        handleFilterMenuClose();
                      }}>
                          Show Selected
                      </MenuItem>
                    </Menu>
                  </Paper>
                  <Paper className={styles.tableWrapper}>
                    <TableContainer className={styles.tableContainer}>
                      <Table className={styles.table} size="small" stickyHeader>
                        <TableHead className={styles.header} >
                          <TableRow>
                            <TableCell className={styles.headerName}>Campus Name</TableCell>
                            <TableCell className={styles.headerName}align="center">Incoming Campuses</TableCell>
                            <TableCell className={styles.headerName}align="center">Outgoing Campuses</TableCell>
                          </TableRow>
                          <TableRow className={styles.selectAllRow} >
                            <TableCell className={styles.selectAll}>All</TableCell>
                            <TableCell align="center" className={styles.selectAllCells}>
                              <Checkbox 
                                checked={incomingCampuses.length === campuses.length}
                                onChange={() => {
                                  if (incomingCampuses.length !== campuses.length){
                                    setIncomingCampuses(campuses);
                                  } else { setIncomingCampuses([]);}
                                }}/>
                            </TableCell>
                            <TableCell align="center" className={styles.selectAllCells}>
                              <Checkbox 
                                  checked={outgoingCampuses.length === campuses.length}
                                  onChange={() => {
                                    if (outgoingCampuses.length !== campuses.length){
                                      setOutgoingCampuses(campuses);
                                    } else { setOutgoingCampuses([]);}
                                  }}/>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            filteredCampuses.map((c) => {
                              return (
                                <TableRow key={c.id} hover>
                                  <TableCell>{c.name}</TableCell>
                                  <TableCell align="center">
                                    <Checkbox
                                      aria-label="check"
                                      value={c.id}
                                      checked={incomingCampuses.find(campus => campus.id === c.id) !== undefined}
                                      onChange={(e) => {
                                        !incomingCampuses.find(campus => campus.id === c.id) ? 
                                          setIncomingCampuses([...incomingCampuses, c]) : 
                                          setIncomingCampuses(incomingCampuses.filter((incoming) => incoming.id !== c.id));
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <Checkbox
                                      aria-label="check"
                                      value={c.id}
                                      checked={outgoingCampuses.find(campus => campus.id === c.id) !== undefined}
                                      onChange={(e) => {
                                        !outgoingCampuses.find(campus => campus.id === c.id) ? 
                                          setOutgoingCampuses([...outgoingCampuses, c]) : 
                                          setOutgoingCampuses(outgoingCampuses.filter((outgoing) => outgoing.id !== c.id));
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                  <TextInput
                    id="label"
                    name="label"
                    label="Label"
                    required
                    defaultValue={event ? event.label : BlankEventRequest.label}
                    error={errors.label} helperText="* Label required"
                    subtext="Name of event"
                  />
                  <TextInput
                    id="description"
                    name="description"
                    label="Description"
                    defaultValue={event ? event.description : BlankEventRequest.description}
                    subtext="Describes event"
                  />
                  <TextInput
                    id="phone_number"
                    name="phone_number"
                    label="Phone Number"
                    defaultValue={event ? event.phone_number : BlankEventRequest.phone_number}
                    subtext="Phone number for use with mobile activation"
                  />
                  <TextInput
                    id='radius'
                    type='number'
                    name='radius'
                    label='Radius'
                    defaultValue={event ? event.radius : '0'}
                    onChange={(e) =>  setExampleRadius(parseFloat(e.target.value) || 0)}
                    subtext='Radius in miles for outgoing geographic alerts'
                    />
                  <Dropdown
                    id="incomingStatus"
                    name="incomingStatus"
                    label="Incoming Status"
                    options={
                      statuses && statuses.length
                        ? (statuses.map(s => s.label ? s.label : '').filter(l => l !== '')) : []}
                    defaultValue={event && event.incomingStatus ? event.incomingStatus.label : undefined}
                    onChange={(e, newValue) => setNewEventStatus(statuses?.filter(s => s.label === newValue)[0])}
                    subtext="Incoming status to match event"
                  />
                  <Dropdown
                    id="outgoingAlert"
                    name="outgoingAlert"
                    label="Outgoing Alert"
                    options={campusAlerts && campusAlerts.length
                      ? (campusAlerts.map(a => a.label ? a.label : '').filter(l => l !== '')) : []}
                    defaultValue={event && event.outgoingAlert ? event.outgoingAlert.label : undefined}
                    onChange={(e, newValue) => setNewEventAlert(campusAlerts?.filter(a => a.label === newValue)[0])}
                    subtext="Alert that will send when event is triggered"
                  />
                  <Dropdown
                    id="users"
                    name="users"
                    label="Users"
                    isMultiSelect={true}
                    options={users && users.length ? users.map((u) => u.username) : []}
                    defaultValue={event ? event.users.map(u => u.username) : []}
                    subtext="Users that will recieve notifications of event"
                    onChange={(e, newValue) => {
                      const newUserArray: User[] = users?.filter(u => newValue?.includes(u.username)) as User[];
                      setSelectedUsers(newUserArray);
                    }}
                  />
                  <Dropdown
                    id="cap"
                    name="cap"
                    label="CAP Messages"
                    options={caps && caps.length ? caps.map(c => c.label ? c.label : '').filter(l => l !== '') : []}
                    defaultValue={event && event.cap ? event.cap.label : null}
                    subtext="CAP that will send on triggered event"
                  />
                </Stack>
              </Stack>
            </ThemeProvider>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Cancel</Button>
            <Button type="submit">Submit</Button>
          </DialogActions>
          <ErrorFocus errors={errors} />
        </form>
    </BaseDialog>
  );
};
