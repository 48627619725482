import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActiveCapMessage } from '../../TypeScript/AppTypes';
 
export interface ActiveCapState {
  successMessage: string,
  activeCaps: ActiveCapMessage[],
}
 
const initialState: ActiveCapState = {
  successMessage: '',
  activeCaps: [],
};
 
export const activeCapSlice = createSlice({
  name: 'activeCap',
  initialState,
  reducers: {
    setSuccessMessage: (state, action: PayloadAction<string>) => {
      state.successMessage = action.payload;
    },
    setActiveCaps: (state, action: PayloadAction<ActiveCapMessage[]>) => {
      state.activeCaps = action.payload;
    },
  },
});
 
export const activeCapActions = activeCapSlice.actions;
 
export default activeCapSlice;