// vendored: https://raw.githubusercontent.com/alex3165/react-leaflet-draw/master/src/EditControl.js

import Draw from 'leaflet-draw'; // eslint-disable-line
import React, { useRef, useState } from 'react';
import { useLeafletContext } from '@react-leaflet/core';
import leaflet, { Control } from 'leaflet';

const options = {
  draw: { polygon: true, rectangle: true, polyline: false, 
    circle: true, circlemarker: false, marker: false,  
  },
  position: 'topright',
};

function EditControl(props) {
  const context = useLeafletContext();
  const drawRef = useRef();
  const { onCreated, onDeleted } = props;
  const [hasRendered, setHasRendered] = useState(false);
  React.useEffect(() => {
    
    const { map, layerContainer } = context;

    const onDrawCreate = (e) => {
      const container = context.layerContainer || context.map;
      container.addLayer(e.layer);
      onCreated(e);
    };
  
    const onDrawDelete = (e) => {
      onDeleted(e);
    };
    
    map.on(leaflet.Draw.Event.DELETED, onDrawDelete);
    map.on(leaflet.Draw.Event.CREATED, onDrawCreate);
    drawRef.current = new Control.Draw({
      ...options,
      edit: {
        featureGroup: layerContainer,
        edit: false,
      },
    });
    if (!hasRendered) map.addControl(drawRef.current);
    setHasRendered(true);
    return () => {
      map.off(leaflet.Draw.Event.CREATED, onDrawCreate);
      map.off(leaflet.Draw.Event.DELETED, onDrawDelete);
    };
  }, [context, onCreated, onDeleted]);

  return null;
}

/* PropTypes (kept for reference)
  *EditControl.propTypes = {
  *  ...Object.keys(eventHandlers).reduce((acc, val) => {
  *    acc[val] = PropTypes.func;
  *    return acc;
  *  }, {}),
  *  onCreated: PropTypes.func,
  *  onMounted: PropTypes.func,
  *  draw: PropTypes.shape({
  *    polyline: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  *    polygon: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  *    rectangle: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  *    circle: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  *    marker: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  *  }),
  *  edit: PropTypes.shape({
  *    edit: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  *    remove: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  *    poly: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  *    allowIntersection: PropTypes.bool,
  *  }),
  *  position: PropTypes.oneOf([
  *    'topright',
  *    'topleft',
  *    'bottomright',
  *    'bottomleft',
  *  ]),
  *  leaflet: PropTypes.shape({
  *    map: PropTypes.instanceOf(Map),
  *    layerContainer: PropTypes.shape({
  *      addLayer: PropTypes.func.isRequired,
  *      removeLayer: PropTypes.func.isRequired,
  *    }),
  *  }),
  *};
*/

export default EditControl;
