import { Box } from '@mui/material';

interface DeviceTabPanelProps {
  children?: React.ReactNode,
  index: number,
  value: number,
}

const DeviceTabPanel = (props: DeviceTabPanelProps) => {
  const { children, value, index } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      >
        {value === index && (
          <Box sx={{ p:3 }}>
            {children}
          </Box>
        )}
      </div>
  );
};
export default DeviceTabPanel;