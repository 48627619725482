import React from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import { ListItem, ListItemText } from '@mui/material';
import { ListItemType } from './NavItemInterface';
import styles from './ListItemStyle.module.css';

const ListItemLink = (props: ListItemType) => {
  const { title, to } = props;
  const location = useLocation();

  const CustomLink = React.useMemo(() => 
    React.forwardRef<HTMLAnchorElement, Omit<LinkProps, 'to'>>((linkProps, ref) => {
      return <Link ref={ref} to={to} {...linkProps} />;
    }),
  [to],
  );

  return (
    <li key={to} className={ location.pathname === to ? styles.activeLink : '' } >
      <ListItem button component={CustomLink}>
        <ListItemText primary={title} />
      </ListItem>
    </li>
  );
};

export default ListItemLink;
