import { createSlice, PayloadAction } from '@reduxjs/toolkit';
 
export interface SocketConnectionState {
  connectionId: string | null;
  connectedUserId: string | null;
  connectedUserToken: string | null;
  error: string,
  alert: string,
  isCsvUploader: boolean,
  uploadStatus: string,
}
 
const initialState: SocketConnectionState = {
  connectionId: null,
  connectedUserId: null,
  connectedUserToken: null,
  error: '',
  alert: '',
  isCsvUploader: false,
  uploadStatus: '',
};

export const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    confirmConnection: (state, action: PayloadAction<string | null>) => {
      state.connectionId = action.payload;
    },
    confirmLogin: (state, action: PayloadAction<{ id: string, token: string }>) => {
      state.connectedUserId = action.payload.id;
      state.connectedUserToken = action.payload.token;
    },
    confirmLogout: (state, action: PayloadAction<string>) => {
      state.connectedUserId = null;
      state.connectedUserToken = null;
    },
    setSocketError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setSocketAlert: (state, action: PayloadAction<string>) => {
      state.alert = action.payload;
    },
    emitSendCampusAlert: (state, action: PayloadAction<{ campusId: number, campusAlertId: number }>) => {
      return state; // side effects in middleware
    },
    emitSendCapMsg: (state, action: PayloadAction<{ campusIds: string[], capId: number }>) => {
      return state; // side effects in middleware
    },
    emitTriggerEvent: (state, action: PayloadAction< { eventId: number }>) => {
      return state;
    },
    emitFetchAllActiveCaps: (state) => {
      return state;
    },
    emitCancelCapMsg: (state, action: PayloadAction<number>) => {
      return state;
    },
    emitRemoveDeviceInfo: (state, action: PayloadAction<{ id: number, campusId: string }>) => {
      return state;
    },
    setIsCsvUploader: (state, action: PayloadAction<boolean>) => {
      state.isCsvUploader = action.payload;
    },
    setUploadStatus: (state, action: PayloadAction<string>) => {
      state.uploadStatus = action.payload;
    },
  },
});
 
export const socketActions = socketSlice.actions;
 
export default socketSlice;