// vendored from: https://github.com/substack/point-in-polygon/blob/master/nested.js

export function pointInPolygon(point: number[], polygon: [number, number][]) {
  const x = point[0], y = point[1];
  let inside = false;
  const start = 0;
  const end = polygon.length;
  const len = end - start;
  for (let i = 0, j = len - 1; i < len; j = i++) {
    const xi = polygon[i + start][0], yi = polygon[i + start][1];
    const xj = polygon[j + start][0], yj = polygon[j + start][1];
    const intersect = ((yi > y) !== (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
    if (intersect) inside = !inside;
  }
  return inside;
}