import { GridValueGetterParams } from '@mui/x-data-grid';
import { Roles, User } from '../TypeScript/AppTypes';
import { getDateString } from './getDateString';

export const getDistrictName = (params: GridValueGetterParams): string => {
  if (!params.value){
    return 'District name unavailable';
  } else {
    return params.value.name;
  }
};

export const getCampusName = (params: GridValueGetterParams ): string => {
  if (!params.value){
    return 'Campus name unavailable';
  } else {
    return params.value.name;
  }
};

export const getRoleName = (params: GridValueGetterParams): string => {
  if (!params.value){
    return '';
  } else {
    if (params.value && params.value.length > 0) {
      return params.value.reduce(
        (stringOfRoleNames: string, current: Roles, index: number): string => {
          if (index === params.value.length - 1) 
            // no trailing comma if last element in array
            return stringOfRoleNames.concat(`${current.name}`);
          else return stringOfRoleNames.concat(`${current.name}, `);
        }, '');
    }
    return '';
  }
};

export const getCampusArrayLength = (params: GridValueGetterParams): number => {
  if (!params.value){
    return 0;
  } else {
    return params.value.length;
  }
};

export const getUsers = (params: GridValueGetterParams): string => {
  if (!params.value){
    return '';
  } else {
    if (params.value && params.value.length > 0) {
      return params.value.reduce(
        (stringOfUserNames: string, current: User, index: number): string => {
          if (index === params.value.length - 1) 
            // no trailing comma if last element in array
            return stringOfUserNames.concat(`${current.username}`);
          else return stringOfUserNames.concat(`${current.username}, `);
        }, '');
    }
    return '';
  }
};

export const getCapLabel = (params: GridValueGetterParams):  string => {
  if (!params.value){
    return '';
  } else {
    return params.value.label;
  }
};

export const getStatusLabel = (params: GridValueGetterParams): string => {
  if (!params.value){
    return '';
  } else {
    return params.value.label;
  }
};

export const getAlertLabel = (params: GridValueGetterParams): string => {
  if (!params.value){
    return '';
  } else {
    return params.value.label;
  }
};

export const getNotificationDate = (params: GridValueGetterParams): string => {
  if (!params.value){
    return '';
  } else {
    return getDateString(params.value);
  }
};