export const dynamicValueList = [
  { showValue: 'CAMPUS_NAME', sendValue: '{NAME}' },
  { showValue: 'CAMPUS_ADDRESS', sendValue: '{ADDR}' },
];

export const dynamicMaintenanceValueList = [
  { showValue: 'REPORTER_TYPE', sendValue: '{REPORTER_TYPE}' },
  { showValue: 'REPORTER_NAME', sendValue: '{REPORTER_NAME}' },
  { showValue: 'ENDPOINT_STATUS', sendValue: '{ENDPOINT_STATUS}' },
  { showValue: 'ENDPOINT_NAME', sendValue: '{ENDPOINT_NAME}' },
];