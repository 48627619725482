import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';
import { DataGrid, GridPreProcessEditCellProps } from '@mui/x-data-grid';
import { useGetSettingsQuery, useEditSettingMutation } from '../../Redux/api';
import { useToastContext, renderCellExpand, ResetDashboardDialog, useDialogContext } from '../../Components';
import { getErrorMessage } from '../../Utils/errorHandling';
import { AUTO_LOGOUT, DEFAULT_ZOOM, DISTRICT_IDS, NETWORK_TIMEOUT, REPORTER_TIMEOUT, ZIP_CODE } from '../../Utils/constants';
import styles from '../../Components/Table/Table.module.css';
import { useAppSelector, useAppDispatch } from '../../Redux/hooks';
import { userPreferenceSliceActions } from '../../Redux/Slices/UserPreferences';

const Settings = (): JSX.Element => {
  const { data, error, isLoading } = useGetSettingsQuery();
  const [editSetting] = useEditSettingMutation();
  const { dialogType, openDialog } = useDialogContext();
  const { setSuccessToast, setErrorToast } = useToastContext();
  const [invalidEditMessage, setInvalidEditMessage] = useState('');
  const [mapSettingType, setMapSettingType] = useState<string | undefined>(undefined);
  const location = useLocation();
  const headerLocation = (((location.pathname).slice(1, 2)).toUpperCase() + (location.pathname).slice(2));
  const { rowsPerPage } = useAppSelector((store) => store.userPreferences);
  const dispatch = useAppDispatch();

  const handleChangeRowsPerPage = (e: number) => {
    dispatch(userPreferenceSliceActions.setRowsPerPage(e));
  };

  // validation
  const isCellUpdateValid = (k: string, v: string): boolean => {
    const onlyNumericValues = /^[0-9]+$/;
    const onlyNumericValuesCommas = /^[0-9,]+$/;

    if (k === ZIP_CODE) {
      if (onlyNumericValues.test(v) && v.length === 5) {
        setInvalidEditMessage('');
        return true;
      } else {
        setInvalidEditMessage('ZIP Code must be 5 characters long and contain only numbers');
        return false;
      }
    } else if (k === DEFAULT_ZOOM) {
      if (onlyNumericValues.test(v) && parseInt(v, 10) > 0 && parseInt(v, 10) < 23) {
        setInvalidEditMessage('');
        return true;
      } else {
        setInvalidEditMessage('Default zoom must be a number from 1 to 22');
        return false;
      }
    } else if (k === DISTRICT_IDS) {
      // allow empty string to show all campuses in any NCES district
      if (onlyNumericValuesCommas.test(v) || v === '') {
        setInvalidEditMessage('');
        return true;
      } else {
        setInvalidEditMessage('Comma-separated numeric values only');
        return false;
      }
    } else if (k === AUTO_LOGOUT) {
      // allow empty string to show all campuses in any NCES district
      if ((onlyNumericValues.test(v) && parseInt(v, 10) >= 5)  || v === '') {
        setInvalidEditMessage('');
        return true;
      } else {
        setInvalidEditMessage('Auto-logout must exceed 5 minutes or be left blank');
        return false;
      }
    } else {
      if (onlyNumericValues.test(v)) {
        setInvalidEditMessage('');
        return true;
      } else {
        setInvalidEditMessage('Numeric values only');
        return false;
      }
    }
  };
  
  return (<>
  <h1 className={styles.headerName}>{headerLocation}</h1>
  <Tooltip title='Manage your District Server settings' arrow>
    <InfoIcon />
  </Tooltip>
  {error ? (
    <>Something Went Wrong</>
  ) : isLoading ? (
      <>Loading...</>
  ) : data ? (
  <>
    <div className={styles.uniformTable}>
      <DataGrid
        experimentalFeatures={{ newEditingApi: true }}
        error={error}
        rows={data}
        rowsPerPageOptions={[10, 25, 50]}
        pageSize={rowsPerPage}
        onPageSizeChange={(newPageSize) => (handleChangeRowsPerPage(newPageSize))}
        sortModel={[{ field: 'id', sort: 'asc' }]}
        columns={[
          { field: 'id', headerName: 'ID', flex: 1, hide: true },
          { field: 'key', headerName: 'Key', flex: 2, sortable: false },
          { field: 'value', 
            headerName: 'Value', 
            flex: 3, 
            editable: true,
            sortable: false,
            cellClassName: (params) => {
              switch (params.row.key) {
                case ZIP_CODE: 
                  return 'zipcodeCell';
                case DEFAULT_ZOOM:
                  return 'defaultZoomCell';
                case NETWORK_TIMEOUT:
                  return 'netTimeoutCell';
                case DISTRICT_IDS:
                  return 'districtIdCell';
                case REPORTER_TIMEOUT:
                  return 'reportTimeoutCell';
                case AUTO_LOGOUT: 
                  return 'autoLogoutCell';
                default:
                  return '';
              }
            },
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
              const setting = data.filter(s => s.id === params.id)[0];
              const value = params.props.value.trim().replace(' ', '');
              const isValid = isCellUpdateValid(setting.key, value);
              return { value, error: !isValid };
            },
          },
          { field: 'description', 
            headerName: 'Description', 
            flex: 3, renderCell: 
            renderCellExpand, 
            sortable: false,
          },
        ]}
        isCellEditable={(params) => 
          params.row.key === ZIP_CODE ||
          params.row.key === DEFAULT_ZOOM || 
          params.row.key === NETWORK_TIMEOUT || 
          params.row.key === DISTRICT_IDS ||
          params.row.key === REPORTER_TIMEOUT ||
          params.row.key === AUTO_LOGOUT
        }
        onCellEditStop={() => invalidEditMessage ? setErrorToast(invalidEditMessage) : null}
        processRowUpdate={async (newRow, oldRow) => {
          const { id, key, value } = newRow;
          const newValue = value.trim().replace(' ', '');
          if (newValue !== oldRow.value) {
            try {
              await editSetting({ id, key, value: newValue }).unwrap();
              setSuccessToast(`${key} setting updated`);
              if (key === ZIP_CODE || key === DEFAULT_ZOOM) {
                setMapSettingType(key);
                openDialog('Reset Dashboard');
              }
              return newRow;
            } catch (err: unknown){
              setErrorToast(getErrorMessage(err));
              return oldRow;
            }
          }
          return oldRow;
        }}
      />
    </div>
    <ResetDashboardDialog
      open={mapSettingType === ZIP_CODE && dialogType === 'Reset Dashboard'}
      settingKey={mapSettingType}
      settingLabel={'Zip Code'}
    />
    <ResetDashboardDialog
      open={mapSettingType === DEFAULT_ZOOM && dialogType === 'Reset Dashboard'}
      settingKey={mapSettingType}
      settingLabel={'Default Zoom'}
    />
  </>
  ) : null}
    </>);
};

export default Settings;
