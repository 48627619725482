import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { GridActionsCellItem, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useDeleteUserMutation, useGetUsersQuery } from '../../Redux/api';
import { CrudTable, DeleteDialog, useDialogContext } from '../../Components';
import { UserFormDialog } from './UserFormDialog';
import { getRoleName } from '../../Utils/valueGetters';
import { User } from '../../TypeScript/AppTypes';
import { useAppSelector } from '../../Redux/hooks';
import styles from './Users.module.css';

const Users = (): JSX.Element => {

  const { data: users, error, isLoading } = useGetUsersQuery();

  const [deleteUser] = useDeleteUserMutation();
  const { id: userId, roles } = useAppSelector(state => state.auth);
  const { dialogType, openDialog } = useDialogContext();
  const nonAdminSecurity = roles.includes('security') && !roles.includes('admin');
  const location = useLocation();
  const headerLocation = (((location.pathname).slice(1, 2)).toUpperCase() + (location.pathname).slice(2));

  const tableColumns: {
    field: string, headerName: string, width?: number, type?: string,
    getActions?: (x: GridRowParams) => JSX.Element[], minWidth?: number,
    valueGetter?: (x: GridValueGetterParams) => string, hide?: boolean, flex?: number,
  }[] = [
    { field: 'username', headerName: 'User', flex: 1, minWidth: 125 },
    { field: 'email', headerName: 'Email', flex: 1, minWidth: 125 },
    {
      field: 'active',
      headerName: 'Active',
      type: 'boolean',
      width: 75,
      minWidth: 50,
    },
    {
      field: 'roles',
      headerName: 'Roles',
      valueGetter: getRoleName, flex: 3,
    },
  ];

  const editColumn = {
    field: 'actions',
    headerName: 'Actions',
    type: 'actions',
    width: 80,
    getActions: (params: GridRowParams) => params.row.id === userId ? [ // Prevent user from self-deletion
      <GridActionsCellItem
        data-cy="edit"
        icon={<Edit />}
        onClick={(e) => {
          handleUserSelection(params.id as string);
          openDialog('Update');
        }}
        label="Edit" />,
    ] : [
      <GridActionsCellItem
        data-cy="edit"
        icon={<Edit />}
        onClick={(e) => {
          handleUserSelection(params.id as string);
          openDialog('Update');
        }}
        label="Edit" />,
      <GridActionsCellItem
        data-cy="delete"
        icon={<Delete />}
        onClick={(e) => {
          handleUserSelection(params.id as string);
          openDialog('Delete');
        }}
        label="Delete" />,
    ],
  };

  const [user, setUser] = useState<User | undefined>(undefined);

  const handleUserSelection = useCallback((id?: string) => {
    if (id && users) {
      const selectedUser = users.filter(u => u.id === id)[0];
      setUser(selectedUser);
    } else {
      setUser(undefined);
    }
  }, [users]);
  
  return (users ? <>
    <h1 className={styles.headerName}>{headerLocation}</h1>
    <Tooltip title='Manage users' arrow>
      <InfoIcon />
    </Tooltip>
      <CrudTable
      dataLabel='User'
      handleRecordSelection={handleUserSelection}
      rowData={users}
      isLoading={isLoading}
      error={error}
      columnNames={nonAdminSecurity ? tableColumns : [editColumn, ...tableColumns]}
      restrictedRole={nonAdminSecurity}
      createOrEditDialog={
        <UserFormDialog
          open={dialogType === 'Create' || dialogType === 'Update'}
          user={user || undefined}
        /> 
      }
      deleteDialog={user && user !== null ?
        <DeleteDialog
          open={dialogType === 'Delete'}
          dataLabel={'User'}
          handleDelete={async () => { 
            await deleteUser(user.id).unwrap();
          }}
        /> :
        <></>
      }
    /></> : <></>);
};

export default Users;
