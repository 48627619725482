import { useState } from 'react';
import { Toolbar, Divider, Drawer, List, Box } from '@mui/material';
import { useAppSelector } from '../../Redux/hooks';
import ListItemLink from './ListItemLink';

const licenseType = process.env.REACT_APP_DISTRICT_LICENSE_TYPE ? process.env.REACT_APP_DISTRICT_LICENSE_TYPE : 'relay' ;

export const navSidebarItems = [
  { title: 'Dashboard', to: '/dashboard' },
  { title: 'Maintenance', to: '/maintenance' },
  { title: 'Notifications', to: '/notifications' },
  { title: 'Campuses', to: '/campuses' },
  { title: 'Events', to: '/events' }, 
  { title: 'Campus Alerts', to: '/alerts' },
  { title: 'Campus Statuses', to: '/statuses' },
  { title: 'CAP Messages', to: '/messages' },
  { title: 'Users', to: '/users' },
  { title: 'Roles', to: '/roles' },
  { title: 'Tags', to: '/tags' },
  { title: 'Settings', to: '/settings' },
];

export const relayVersionNavItems = [
  { title: 'Campuses', to: '/campuses' },
  { title: 'Events', to: '/events' }, 
  { title: 'Campus Alerts', to: '/alerts' },
  { title: 'CAP Messages', to: '/messages' },
];

const NavigationSide = (): JSX.Element => {

  const { roles } = useAppSelector((state) => state.auth);
  const [ active, setActive ] = useState('/dashboard');
  
  const renderSidebarItems = () => {
    if (roles.length === 1 && roles.includes('maintenance')) {
      if (active !== '/maintenance') setActive('/maintenance');
      return (
        <ListItemLink
          key={'/maintenance'}
          title={'Maintenance'}
          to={'/maintenance'}
          active={active}
          setActive={setActive}
        />
      );
    } else if (licenseType === 'base'){
      const baseSideBarItems = navSidebarItems.filter((navItem: { title: string }) => navItem.title !== 'Maintenance')
        .map((n: { title: string, to: string }) => (
            <ListItemLink
              key={n.to}
              title={n.title}
              to={n.to}
              active={active}
              setActive={setActive}/>
        ));
      return baseSideBarItems;
    } else if (licenseType === 'relay'){
      const relaySideBarItems = relayVersionNavItems.map((navItem: { title: string, to: string }) => (
          <ListItemLink
            key={navItem.to}
            title={navItem.title}
            to={navItem.to}
            active={active}
            setActive={setActive}
            />
      ));
      return relaySideBarItems;
    } else {
      if (!roles.includes('maintenance')) {
        const sidebarItems = navSidebarItems.filter((navItem: { title: string }) => navItem.title !== 'Maintenance')
          .map((n: { title: string, to: string }) => (
          <ListItemLink
          key={n.to}
          title={n.title}
          to={n.to}
          active={active}
            setActive={setActive}
          />
          ));
        return sidebarItems;
      } else {
        const relaySideBarItems = navSidebarItems.map((navItem: { title: string, to: string }) => (
          <ListItemLink
            key={navItem.to}
            title={navItem.title}
            to={navItem.to}
            active={active}
            setActive={setActive}
            />
        ));
        return relaySideBarItems;
      }
    }
  };

  return (
    <Box component="nav">
      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          width: 200,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 200,
            boxSizing: 'border-box',
          },
        }}
      >
        <Toolbar />
        <Divider />
        <List>
          {renderSidebarItems()}
        </List>
      </Drawer>
    </Box>
  );
};

export default NavigationSide;