import { useState } from 'react';
import { Box, Button, DialogActions, DialogContent, DialogContentText, TextField } from '@mui/material';
import { HexColorPicker } from 'react-colorful';
import { CampusAlert, CampusAlertRequest } from '../../TypeScript/AppTypes';
import { useDialogContext, TextInput, BaseDialog, useToastContext } from '../../Components';
import { useAddCampusAlertMutation, useEditCampusAlertMutation } from '../../Redux/api';
import { getErrorMessage } from '../../Utils/errorHandling';
import { trimRequest } from '../../Utils/trimRequest';
import styles from './CampusAlert.module.css';

const hexCharLength = 7;
const colorRegex = /^#([A-Fa-f0-9]{6})$/;

export const blankCampusAlertInput: Partial<CampusAlert> = {
  label: '',
  color: '#aabbcc',
  borderColor: '#aabbcc',
  textColor: '#aabbcc',
  description: '',
  phone_number: '',
};

export const CampusAlertFormDialog = (props: {
  open: boolean,
  alert?: CampusAlert,
}): JSX.Element => {

  const { open, alert } = props;

  const [addCampusAlert] = useAddCampusAlertMutation();
  const [editCampusAlert] = useEditCampusAlertMutation();
  
  const [color, setColor] = 
    useState<string | undefined>((alert && alert.color !== null) ? alert.color : '#aabbcc');
  const [borderColor, setBorderColor] =   
    useState<string | undefined>(alert ? alert.borderColor : blankCampusAlertInput.borderColor);
  const [textColor, setTextColor] =
    useState<string | undefined>(alert && alert.textColor !== null ? alert.textColor : '#aabbcc');

  const { closeDialog } = useDialogContext();
  const { setSuccessToast, setErrorToast } = useToastContext();

  const [errors, setErrors] = useState<{
    label: boolean,
    color: boolean,
    borderColor: boolean,
    textColor: boolean,
  }>({
    label: false,
    color: false,
    borderColor: false,
    textColor: false,
  });

  const handleColorChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setColor(e.target.value);
  };

  const handleBorderColorChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setBorderColor(e.target.value);
  };

  const handleTextColorChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTextColor(e.target.value);
  };

  const insertNumberSignColor = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!e.target.value.match((/^#/))) setColor('#' + color);
  };

  const insertNumberSignBorderColor = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!e.target.value.match((/^#/))) setBorderColor('#' + borderColor);
  };

  const insertNumberSignTextColor = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!e.target.value.match((/^#/))) setTextColor('#' + textColor);
  };

  const validateCampusAlertRequest = (input: CampusAlertRequest) => {
    let isLabelInvalid = false;
    let isColorInvalid = false; 
    let isBorderColorInvalid = false;
    let isTextColorInvalid = false;

    if (!input.label?.length) isLabelInvalid = true;
    if (color?.length !== hexCharLength) isColorInvalid = true;
    if (!color?.match(colorRegex)) isColorInvalid = true;
    if (borderColor?.length !== hexCharLength) isBorderColorInvalid = true;
    if (!borderColor?.match(colorRegex)) isBorderColorInvalid = true;
    if (textColor?.length !== hexCharLength) isTextColorInvalid = true;
    if (!textColor?.match(colorRegex)) isTextColorInvalid = true;

    setErrors({
      label: isLabelInvalid,
      color: isColorInvalid,
      borderColor: isBorderColorInvalid,
      textColor: isTextColorInvalid,
    });

    if (isLabelInvalid || isColorInvalid || isBorderColorInvalid || isTextColorInvalid) {
      return false;
    } return true;
  };
  
  return (
      <BaseDialog
        open={open}
        title={alert ? 'Update Campus Alert' : 'Create New Campus Alert'}
        fullWidth={true}
        maxWidth='md'
      >
        <form 
          noValidate 
          onSubmit={async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const fieldValues = Object.fromEntries(formData.entries());

            // Get data from form, then add values from controlled fields (color and borderColor)
            const body: CampusAlertRequest = trimRequest(fieldValues) as unknown as CampusAlertRequest;
            body.color = color;
            body.borderColor = borderColor;
            body.textColor = textColor;
            const isValidRequest = validateCampusAlertRequest(body);

            if (isValidRequest) {
              try {
                if (alert)  {
                  await editCampusAlert({ ...body, id: alert.id }).unwrap();
                  setSuccessToast('Alert successfully updated');
                } else {
                  await addCampusAlert(body).unwrap();
                  setSuccessToast('Alert successfully created');
                } 
                closeDialog();
              } catch (err: unknown) {
                setErrorToast(getErrorMessage(err));
              }
            }
          }}
        >
      <DialogContent>
        <TextInput
          id="label" name="label" label="Label"
          error={errors.label}
          required
          defaultValue={alert ? alert.label : blankCampusAlertInput.label}
          helperText='Label Required'
          />
        <TextInput 
          id="description" name="description" label="Description" 
          defaultValue={alert ? alert.description : blankCampusAlertInput.description}
        />
        <TextInput 
          id="phone_number" name="phone_number" label="Phone Number" 
          defaultValue={alert ? alert.phone_number : blankCampusAlertInput.phone_number}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <DialogContentText className={errors.color ? styles.errorTitle : ''}>
                Color
            </DialogContentText>
            <HexColorPicker 
              className={errors.color ? styles.errorBorder : ''}
              aria-label="color" 
              color={color} 
              onChange={value => setColor(value)} 
            />
            <TextField
              id="color-input" name="color" label="Color"
              value={color} onChange={(value) => handleColorChange(value)} sx={{ marginTop: '10px' }}
              inputProps={{ onBlur: (value) => { insertNumberSignColor(value); } }}
            />
            {errors.color ? <p className={styles.error}>Color Required. <br /> Enter a color code of #123456</p> : null}
          </Box>
        <Box>
          <DialogContentText className={errors.borderColor ? styles.errorTitle : ''}>
            Border Color
          </DialogContentText>
          <HexColorPicker 
            className={errors.borderColor ? styles.errorBorder : ''}
            aria-label="borderColor" 
            color={borderColor} 
            onChange={value => setBorderColor(value)} 
          />
          <TextField
            id="borderColor-input" name="borderColor" label="Border Color"
            value={borderColor} onChange={(value) => handleBorderColorChange(value)} sx={{ marginTop: '10px' }}
            inputProps={{ onBlur: (value) => { insertNumberSignBorderColor(value); } }}
          />
          {errors.borderColor ? <p className={styles.error}>Border Color Required. <br /> Enter a color code of #123456</p> : null}
        </Box>
        <Box>
          <DialogContentText
            className={errors.borderColor ? styles.errorTitle : ''}>
                Text Color
            </DialogContentText>
          <HexColorPicker
            className={errors.borderColor ? styles.errorBorder : ''}
            aria-label="textColor"
            color={textColor}
            onChange={value => setTextColor(value)}
          />
          <TextField
            id="textColor-input" name="textColor" label="Text Color"
            value={textColor} onChange={(value) => handleTextColorChange(value)} sx={{ marginTop: '10px' }}
            inputProps={{ onBlur: (value) => { insertNumberSignTextColor(value); } }}
          />
          {errors.textColor ? <p className={styles.error}>Text Color Required. <br /> Enter a color code of #123456</p> : null}
          </Box>
        </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>
            Cancel
          </Button>
          <Button type="submit">
            Submit
          </Button>
        </DialogActions>
      </form>
    </BaseDialog>
  );
};
