import React, { useState } from 'react';
import { DialogContext } from './DialogContext';

export interface StatusDialogInterface {
  isOpen: boolean,
  content: string,
  dialogType: 'Status'
}

export interface UseDialogHookReturn {
  // Empty string === "closed" state
  dialogType: 'Create' | 'Update' | 'Delete' | 'Sender' | 'Csv' | 'ActiveCap' | 
  'Notification' | 'Status' | 'Reset Campus' | 'Reset Dashboard' | 'Trigger' | 'Idle' | 'Maintenance' | 'Device Details' | '',
  openDialog: (d: UseDialogHookReturn['dialogType']) => void, 
  closeDialog: () => void,
}

const useDialog = (): UseDialogHookReturn => {

  const [dialogType, setDialogType] = 
    useState<UseDialogHookReturn['dialogType']>('');

  const openDialog = (
    d: UseDialogHookReturn['dialogType'],
  ) => { 
    setDialogType(d);
  };

  const closeDialog = () => {
    setDialogType('');
  };

  return { 
    dialogType, 
    openDialog, 
    closeDialog, 
  } as const;
};

// eslint-disable-next-line @typescript-eslint/ban-types
const DialogProvider = ({ children }: React.PropsWithChildren<{}>): JSX.Element => {

  const { dialogType, openDialog, closeDialog } = useDialog();

  return (
    <DialogContext.Provider value={{ 
      dialogType, openDialog, closeDialog,
    }}>
        {children}
    </DialogContext.Provider>
  );

};

export default DialogProvider;