import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material/';

export interface DropdownPickerInterface {
  value?: string[],
  label: string,
  options: string[],
  onChange?: (e: SelectChangeEvent<unknown>) => void,
}

const DropdownPicker = ( props: DropdownPickerInterface): JSX.Element => {

  const { value, label, options, onChange } = props; 

  return (
    <FormControl sx={{ minWidth: '200px' }}>
      <Select
        value={value || ''}
        renderValue={(val) => <em>{label}</em>}
        displayEmpty
        onChange={onChange}
      >
        {
          options.map((option) => (
            <MenuItem key={option} value={option}>{option}</MenuItem>
          ))
        }
      </Select>
    </FormControl> 
  );
};

export default DropdownPicker;
