import { Button, DialogActions, DialogContent } from '@mui/material';
import { useDialogContext } from '..';
import BaseDialog from './BaseDialog';

interface IdlePromptDialogProps {
  open: boolean,
  reset: () => void,
}

const IdlePromptDialog = (props: IdlePromptDialogProps): JSX.Element => {

  const { open, reset } = props;
  const { closeDialog } = useDialogContext();

  return (
    <BaseDialog
        open={open}
        title={'Inactive'}
        onClose={() => reset()}
      >
        <>
          <DialogContent>
              You will be logged out in 30 seconds.
          </DialogContent>
          <DialogActions>
            <Button 
              type="submit"
              onClick={() => { 
                reset();
                closeDialog();
              }}
            >
              Resume
          </Button>
        </DialogActions>
      </>
    </BaseDialog>
  );
};

export default IdlePromptDialog;