import { Button, DialogActions, DialogContent, DialogContentText, FormControl } from '@mui/material';
import { BaseDialog } from '../../Components';
import { Campus, CapMessage, AppDataLabel, CampusAlert } from '../../TypeScript/AppTypes';
import styles from './Dialog.module.css';
import { Dropdown } from '..';
import { useDialogContext } from '..';


export interface SenderDialogProps {
  open: boolean,
  dataLabel: AppDataLabel,
  selectedCampuses: Campus[],
  options: (CampusAlert | CapMessage)[],
  sendUpdate: (id: number) => void;
}

const SenderDialog = (props: SenderDialogProps): JSX.Element => {
 
  const { open, dataLabel, sendUpdate, options, selectedCampuses } = props;
  const { closeDialog } = useDialogContext();

  return (
    <>
      <BaseDialog 
        open={open} 
        title={`Send New ${dataLabel}`}
        fullWidth
        maxWidth={'sm'}
      >
        <form noValidate 
          onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            // get "sender" value from form input, see below
            const fieldValues = Object.fromEntries(formData.entries()); 
            sendUpdate(options.filter(o => o.label === fieldValues.sender)[0].id as unknown as number);
            closeDialog();
          }}>
          <DialogContent>
            <DialogContentText className={styles.headerText}>
                Selected Campuses:
            </DialogContentText>
            {selectedCampuses.map((campus) => {
              return (
                <DialogContentText 
                key={campus.id} 
                className={styles.selectedCampusesText}>
                  {campus.name} 
              </DialogContentText>);
            })}
            {<FormControl fullWidth>
              {options.length === 0 ? 
                <>Please create a {dataLabel === 'Cap Message' ? `non-dynamic ${dataLabel}` : dataLabel}.</> :
                <Dropdown
                  id={dataLabel} 
                  name="sender" // used in retrieving form value on submit. See above
                  label={dataLabel}
                  options={options.map(o => o.label ? o.label : '').filter(l => l !== '')} 
                  defaultValue={options[0].label}
              />}
            </FormControl>}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>
              Cancel
            </Button>
            <Button type="submit">
              Send Update
            </Button>
          </DialogActions>
        </form>
      </BaseDialog>
    </>);
};

export default SenderDialog;