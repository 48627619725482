import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Edit, Delete } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip, Button } from '@mui/material';
import { GridActionsCellItem, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';
import { dashboardMapSliceActions } from '../../Redux/Slices/DashboardMapSlice';
import { useAppDispatch, useAppSelector } from '../../Redux/hooks';
import { useAddCampusCsvMutation, useAddDistrictsCsvMutation, useDeleteCampusMutation, 
  useGetCampusesQuery, useGetActivationLogsQuery, useGetNotificationLogsQuery } from '../../Redux/api';
import { 
  CrudTable, DeleteDialog, CsvDialog, useDialogContext,
} from '../../Components';
import { getDistrictName } from '../../Utils/valueGetters';
import { CampusFormDialog } from './CampusFormDialog';
import { Campus, LogResponse } from '../../TypeScript/AppTypes';
import styles from './CampusStyle.module.css';
import download from 'downloadjs';

const Campuses = ():JSX.Element => {
  const { data: campuses, error, isLoading } = useGetCampusesQuery();
  const { data: activationLogRes } = useGetActivationLogsQuery();
  const { data: notificationLogsRes } = useGetNotificationLogsQuery();
  const { selectedCampuses } = useAppSelector((store) => store.dashboardMap);
  const { roles } = useAppSelector((state) => state.auth);
  const [deleteCampus] = useDeleteCampusMutation();
  const [addCampusCsv] = useAddCampusCsvMutation();
  const [addDistrictsCsv] = useAddDistrictsCsvMutation();
  const { dialogType, openDialog } = useDialogContext();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const headerLocation = (((location.pathname).slice(1, 2)).toUpperCase() + (location.pathname).slice(2));

  const [uploadType, setUploadType] = useState<'District' | 'Campus' | undefined>(undefined);
  const [campus, setCampus] = useState<Campus | null>(null);
  const nonAdminSecurity = roles.includes('security') && !roles.includes('admin');

  const handleCampusSelection = useCallback((id?: string) => {
    if (id && campuses) {
      const selectedCampus = campuses.filter(c => c.id === id)[0];
      setCampus(selectedCampus);
    } else {
      setCampus(null);
    }
  }, [campuses]);

  const campusColumns: {
    field: string, headerName: string, width?: number, type?: string,
    getActions?: (x: GridRowParams) => JSX.Element[], minWidth?: number,
    valueGetter?: (x: GridValueGetterParams) => string, hide?: boolean, flex?: number,
  }[] = [
    { field: 'id', headerName: 'ID', width: 50, minWidth: 10, hide: true },
    { field: 'name', headerName: 'Campus Name', width: 250, minWidth: 200 },
    { field: 'address', headerName: 'Address', width: 220, minWidth: 200 },
    { field: 'city', headerName: 'City', width: 120, minWidth: 100 },
    { field: 'state', headerName: 'State', width: 75, minWidth: 50 },
    { field: 'postal', headerName: 'Zip', width: 80, minWidth: 50 },
    { field: 'telephone', headerName: 'Phone Number', width: 150, minWidth: 100 },
    { field: 'district', headerName: 'Campus District', 
      valueGetter: getDistrictName, flex: 2, minWidth: 150 },
  ];

  const editColumn = {
    field: 'actions',
    headerName: 'Actions',
    type: 'actions',
    width: 80,
    getActions: (params: GridRowParams) => [
      <GridActionsCellItem
        data-cy="edit"
        icon={<Edit />}
        onClick={(e) => {
          handleCampusSelection(params.id as string);
          openDialog('Update');
        }}
        label="Edit" />,
      <GridActionsCellItem
        data-cy="delete"
        icon={<Delete />}
        onClick={(e) => {
          handleCampusSelection(params.id as string);
          openDialog('Delete');
        }}
        label="Delete" />,
    ],
  };

  const downloadActivationLogs = (data: LogResponse | undefined) => {
    const logData = data ? JSON.parse(JSON.stringify(data.logData)) : '';
    const blob = new Blob([logData], { type:'octet-stream' });
    download(blob, 'activationLogs.txt', 'octet-stream');
  };
  
  const downloadNotificationLogs = (data: LogResponse | undefined) => {
    const logData = data ? JSON.parse(JSON.stringify(data.logData)) : '';
    const blob = new Blob([logData], { type:'octet-stream' });
    download(blob, 'notificationLogs.txt', 'octet-stream');
  };

  return (campuses ? <>
    <h1 className={styles.headerName}>{headerLocation}</h1>
    <Tooltip title='Manage campuses' arrow>
      <InfoIcon />
    </Tooltip>
    <CrudTable
        dataLabel='Campus'
        handleRecordSelection={handleCampusSelection}
        rowData={campuses}
        error={error}
        isLoading={isLoading}
        columnNames={nonAdminSecurity ? campusColumns : [editColumn, ...campusColumns]}
        createOrEditDialog={         
          <CampusFormDialog
            open={dialogType === 'Create' || dialogType === 'Update'}
            campus={campus || undefined} /> 
        }
        deleteDialog={campus && campus !== null ?
          <DeleteDialog
            open={dialogType === 'Delete'}
            dataLabel='Campus'
            handleDelete={async () => { 
              await deleteCampus(campus.id).unwrap();
              dispatch(dashboardMapSliceActions.setSelectedCampuses(
                selectedCampuses.filter(c => c.id !== campus.id),
              ));
            }}
          /> :
          <></>
        }
        additionalDialogs={<>
          <CsvDialog
            open={uploadType === 'District' && dialogType === 'Csv'}
            dataLabel={'District'}
            uploadCsvData={addDistrictsCsv}
          />
          <CsvDialog
            open={uploadType === 'Campus' && dialogType === 'Csv'}
            dataLabel={'Campus'}
            uploadCsvData={addCampusCsv}
          />
        </>}
        additionalButtons={<>
          <Button
            type="button"
            aria-label="Upload Districts"
            variant="outlined" 
            onClick={() => {
              setUploadType('District');
              openDialog('Csv');
            }}
          >
            Upload Districts
          </Button>
          <Button
          type="button"
          aria-label="Upload Campuses"
          variant="outlined" 
          onClick={() => {
            setUploadType('Campus');
            openDialog('Csv');
          }}
          >
            Upload Campuses
          </Button>
          <Button
          type="button"
          aria-label="Download Database Button"
          variant="outlined" 
          onClick={() =>
            downloadActivationLogs(activationLogRes)
        }
          >
            Activation Logs
          </Button>
          <Button
          type="button"
          aria-label="Download Database Button"
          variant="outlined" 
          onClick={() =>
            downloadNotificationLogs(notificationLogsRes)
        }
          >
            Notification Logs
          </Button>
        </>
        }
        restrictedRole={nonAdminSecurity}
      /></> : <></>);
};

export default Campuses;
