import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../../Redux/api';
import { TextField, Typography, Button, Alert } from '@mui/material';
import Circles from '../../Assets/circles.png';
import styles from './Login.module.css';
import { useAppSelector } from '../../Redux/hooks';
import { getErrorMessage } from '../../Utils/errorHandling';
import { Copyright } from '../../Components';
import UpdatePwdFormDialog from './UpdatePwdFormDialog';


const licenseType = process.env.REACT_APP_DISTRICT_LICENSE_TYPE ? process.env.REACT_APP_DISTRICT_LICENSE_TYPE : 'relay';

const Login = (): JSX.Element => {
  const [login] = useLoginMutation();
  const navigate = useNavigate();
  const { token, id, roles } = useAppSelector((state) => state.auth);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [emailInputValue, setEmailInputValue] = React.useState('');
  const [passwordInputValue, setPasswordInputValue] = React.useState('');
  const [errStatusCode, setErrStatusCode] = React.useState<number>(0);
    
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleLogin = async (e:  React.MouseEvent<HTMLButtonElement, MouseEvent> | any) => { 
    if (e !== undefined) e.preventDefault(); // eslint-disable-line
    setErrorMessage('');
    let trimmedEmail = emailInputValue.replace(/^[ \t]+/, '');
    trimmedEmail = trimmedEmail.replace(/[ \t]+$/, '');
    const payload = { email: trimmedEmail, password: passwordInputValue };
    try {
      await login(payload).unwrap();
      navigate('/dashboard', { replace: true });
    } catch (err: unknown | any) {//eslint-disable-line
      console.log(err);
      setErrStatusCode(err.status);
      setErrorMessage(`There was an error logging in. ${getErrorMessage(err)}.`);
    }
  };

  const onEmailInput = (e: React.ChangeEvent<HTMLInputElement>): boolean => {
    e.preventDefault();
    setEmailInputValue(e.target.value);
    return true;
  };
  const onPasswordInput = (e: React.ChangeEvent<HTMLInputElement>): boolean => {
    e.preventDefault();
    const pass = e.target.value.replace(/\s/g, '');
    setPasswordInputValue(pass);
    return true;
  };
  
  if (token === null || id === null){
    return (<>
    <div className={styles.login}>
      <div className={styles.loginMain}>
        <div className={styles.left}></div>
        <div className={styles.outWrap}>
          <div className={styles.loginForm}>
              <Typography 
                  component="h1" 
                  variant="h5">
                LOGIN
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '40px',
                    width: '400px',
                  },
                }}
                name="email"
                autoComplete="email"
                autoFocus
                placeholder="Email"
                onKeyDown={(e) => e.key === 'Enter' ? handleLogin(e) : null}
                value={emailInputValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): boolean => onEmailInput(e)}
                />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '40px',
                    width: '400px',
                  },
                }}
                type="password"
                id="password"
                autoComplete="current-password"
                placeholder="Password"
                onKeyDown={(e) => e.key === 'Enter' ? handleLogin(e) : null}
                value={passwordInputValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): boolean => onPasswordInput(e)}
                />
              <Button
                type="submit"
                fullWidth
                className={styles.button}
                disableRipple={true}
                autoFocus={true}
                variant="contained"
                onClick={handleLogin}
                onKeyDown={(e) => e.key === 'Enter' ? handleLogin(e) : null}
                sx={{ 
                  borderRadius: '40px',
                  width: '400px',
                  height: '56px',
                  backgroundColor: '#9DC3F5',
                  mt: 3, mb: 2,
                }}
                >
                Login
              </Button>
              {errStatusCode && errorMessage !== '' ?
              <Alert 
              variant="filled" 
              severity="error"
              sx={{
                position: 'absolute',
                width: '500px',
                top: '-100px',
                left: '-34px',
              }}
              >
                {errorMessage}
              </Alert>
                : null
              }
          </div>
        </div>
      </div>
      <img src={Circles} className={styles.loginCircles} alt="circles on login page"/>
      <Copyright />
    </div>
    {errStatusCode === 426 && 
      <UpdatePwdFormDialog 
        open={errStatusCode ? true : false} 
        curPwd={passwordInputValue} 
        setErrorCode={setErrStatusCode} 
        email={emailInputValue}
        handleLogin={handleLogin}
        onPasswordInput={onPasswordInput}
        />}
  </>);
  }
  if (licenseType === 'full' && (roles.length === 1 && roles.includes('maintenance'))){
    return <Navigate to='/maintenance'/>; 
  } else if (licenseType === 'relay'){
    return <Navigate to='/campuses'/>;
  } else {
    return <Navigate to='/dashboard'/>;
  }
};
export default Login;