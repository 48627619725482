import { LatLngExpression } from 'leaflet';
import { useEffect, useRef } from 'react';
import { CircleMarker } from 'react-leaflet';
import { Campus } from '../../TypeScript/AppTypes';

interface EventDialogMarkerProps {
  id: string, 
  coords: LatLngExpression, 
  isSelected: boolean, 
  borderColor: string, 
  color: string,
  campuses: Campus[],
  selectedCampuses: Campus[],
  handleCampusSelectionChange: (selection: Campus[]) => void,
}

export const EventDialogMarker = (props: EventDialogMarkerProps) => {
  const { id, coords, isSelected, borderColor, color, campuses, selectedCampuses,
    handleCampusSelectionChange } = props;
  const circleRef = useRef<any>(null); //eslint-disable-line

  useEffect(() => {
    circleRef.current.setStyle({ color: borderColor }); // borderColor => color
    circleRef.current.setStyle({ fillColor: color }); // color => fillColor
  }, [color, borderColor]);

  const markerClick = () => {
    if (isSelected === false){
      const newCampusSelected = campuses.filter((campus) => campus.id === id && !selectedCampuses.includes(campus)); 
      newCampusSelected && handleCampusSelectionChange([...selectedCampuses, ...newCampusSelected]);
    } else {
      handleCampusSelectionChange(selectedCampuses.filter((c) => c.id !== id));
    }
  };
    
  return (
    <CircleMarker
      key={id}
      pathOptions={isSelected  ?  { weight: 4 } : { weight: 2 }}
      color={borderColor}
      fillColor={color}
      center={coords}
      radius={7}
      fillOpacity={2}
      interactive={true}
      ref={(ref) => {
        circleRef.current = ref;
      }}
      bubblingMouseEvents={false}
      eventHandlers={{
        click: () => {markerClick();}, 
      }}
      />
  );
};