/*eslint-disable react-hooks/exhaustive-deps*/
import { useCallback, useEffect, useRef, useState } from 'react';
import { LatLngBounds, LatLngExpression, LatLngTuple,
  Point, PointExpression, FeatureGroup as LeafletFeatureGroup } from 'leaflet';
import { FeatureGroup } from 'react-leaflet';
import { Button, Stack } from '@mui/material';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { pointInPolygon, EditControl } from '.';
import { Campus, MapState } from '../TypeScript/AppTypes';
import Map from './Map';
import styles from './CampusMap.module.css';
import 'leaflet/dist/leaflet.css';


interface CampusMapProps {
  mapType: 'dashboard' | 'event',
  defaultCenterCoords: LatLngTuple,
  defaultZoom: number,
  campuses: Campus[],
  selectedCampuses: Campus[],
  handleCampusSelectionChange: (selection: Campus[]) => void,
  mapState: MapState,
  handleMapStateChange: (newMapState: MapState) => void,
  handleMapLayerChange: (newLayer: LeafletFeatureGroup | null) => void,
  rightButtons: JSX.Element,
  leftButtons: JSX.Element,
  clearAllBtnDisabled: boolean,
  markers: JSX.Element[],
  additionalShapes?: JSX.Element | null,
  fullsize: boolean
}

const CampusMap = (props: CampusMapProps): JSX.Element => {

  const { mapType, defaultCenterCoords, defaultZoom, 
    campuses, selectedCampuses, handleCampusSelectionChange,
    mapState, handleMapStateChange, handleMapLayerChange,
    leftButtons, rightButtons, clearAllBtnDisabled, markers, additionalShapes, fullsize } = props;
  const { bounds, polyPnts, centerPnt, radius } = mapState;
  const featureGroupRef = useRef<LeafletFeatureGroup>(null); 
  const currentLayers = featureGroupRef.current?.getLayers();
  const [shapeSelected, setShapeSelected] = useState<Campus[]>([]);

  useEffect(() => {
    if (currentLayers && currentLayers.length > 1){
      featureGroupRef.current?.removeLayer(currentLayers[0]);
    }
  }, [currentLayers]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onCreate = useCallback((e: any) => {
    if (e.layerType === 'rectangle'){
      const rectBounds = e.layer.getBounds() as LatLngBounds;      
      handleMapStateChange({ bounds: rectBounds });
    }
  
    if (e.layerType === 'polygon'){
      const polyPoints = e.layer._latlngs[0].map(Object.values) as [number, number][];
      handleMapStateChange({ polyPnts: polyPoints });
    }
  
    if (e.layerType === 'circle'){
      const center = e.layer.getLatLng() as Point;
      const rad = e.layer.getRadius() as number;
      handleMapStateChange({ centerPnt: center, radius: rad });
    }
    handleMapLayerChange(featureGroupRef.current);
  }, []);

  const onDelete = useCallback(() => {
    if (featureGroupRef.current) {
      const deletedLayer = featureGroupRef.current.getLayers();
      if (deletedLayer.length < 1) {
        const shapeIds = shapeSelected.map((campus) => campus.id);
        handleCampusSelectionChange(selectedCampuses.filter(c => !shapeIds.includes(c.id)));
        handleMapStateChange({ bounds: null, polyPnts: [], centerPnt: null, radius: 0 });
      }
    }
  }, [shapeSelected, selectedCampuses]);
  
  useEffect(() => {
    if (bounds){
      const rectSelected: Campus[] = [];
      for (let i = 0; i < campuses.length; i += 1) {
        const geom = campuses[i].geom;
        if (geom) {
          const { coordinates } = geom;
          if (bounds.contains([coordinates[1], coordinates[0]] as LatLngExpression)) {
            rectSelected.push(campuses[i]);
          }
        }
      }
      setShapeSelected(rectSelected);
      const newCampusesSelected = rectSelected.filter((campus) => !selectedCampuses.includes(campus));
      newCampusesSelected && handleCampusSelectionChange([...selectedCampuses, ...newCampusesSelected] as unknown as Campus[]);    
    }    
  }, [bounds, campuses]);

  const clearMap = () => {
    handleCampusSelectionChange([]); 
    featureGroupRef.current?.clearLayers();
    handleMapStateChange({ bounds: null, polyPnts: [], centerPnt: null, radius: 0 });
  };

  useEffect(() => {
    if (polyPnts && polyPnts.length > 0){
      const polySelected: Campus[] = [];
      for (let i = 0; i < campuses.length; i += 1){
        const geom = campuses[i].geom;
        if (geom) {
          const { coordinates } = geom;        
          if (pointInPolygon([coordinates[1], coordinates[0]], polyPnts)){
            polySelected.push(campuses[i]);
          }
        }
      }
      setShapeSelected(polySelected);
      const newCampusesSelected = polySelected.filter((campus) => !selectedCampuses.includes(campus)); 
      newCampusesSelected && handleCampusSelectionChange([...selectedCampuses, ...newCampusesSelected] as unknown as Campus[]);    
    }
  }, [campuses, polyPnts]);

  useEffect(() => { 
    if (centerPnt && radius){
      const circleSelected: Campus[] = [];
      for (let i = 0; i < campuses.length; i += 1){
        const geom = campuses[i].geom;
        if (geom) {
          const { coordinates } = geom;
          if (campuses[i] && centerPnt.distanceTo([coordinates[1], coordinates[0]] as PointExpression) < radius){
            circleSelected.push(campuses[i]);
          }
        }
      }
      setShapeSelected(circleSelected);
      const newCampusesSelected = circleSelected.filter((campus) => !selectedCampuses.includes(campus));
      newCampusesSelected && handleCampusSelectionChange([...selectedCampuses, ...newCampusesSelected] as unknown as Campus[]);    
    }
  }, [centerPnt, radius, campuses]);

  return (
      <Map
        mapCenter={defaultCenterCoords}
        mapZoom={defaultZoom }
        fullsize={fullsize}
        persistMap={mapType === 'dashboard'}
      >
        <>
          <FeatureGroup ref={featureGroupRef}>
            <EditControl
              onCreated={onCreate}
              onDeleted={onDelete}
            />
          </FeatureGroup>
            {markers}
            {additionalShapes}
          <Stack direction={mapType === 'dashboard' ? 'row' : 'column'} spacing={1} className={styles.clearSelection}>
            { mapType === 'event' &&  leftButtons }
            <Button 
            className={clearAllBtnDisabled ? styles.clearSelectedCampusesDisabled : styles.clearSelectedCampuses}
            variant='contained' 
            disabled={clearAllBtnDisabled}
            onClick={clearMap}
            >
            <HighlightOffOutlinedIcon className={styles.buttonIcon}/>
            Clear Map
            </Button>
            { mapType === 'dashboard' &&  leftButtons }
          </Stack>
          <Stack direction={mapType === 'dashboard' ? 'row' : 'column'} spacing={1} className={styles.buttonGroup}>
            {rightButtons}
          </Stack>
        </>
      </Map>
  );
};

export default CampusMap;
