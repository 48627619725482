import { DataGrid, GridColumns, GridToolbar } from '@mui/x-data-grid';
import { SerializedError } from '@reduxjs/toolkit';
import { userPreferenceSliceActions } from '../../Redux/Slices/UserPreferences';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import styles from './Table.module.css';
import { useAppDispatch, useAppSelector } from '../../Redux/hooks';

export interface TableInterface {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rowData: { [key: string]: any }[] | undefined,
  columnNames: GridColumns,
  error: FetchBaseQueryError | SerializedError | undefined,
  isLoading: boolean,
  buttonField?: JSX.Element,
}

const Table = (props: TableInterface): JSX.Element => {
  const { rowData, columnNames, error, isLoading, buttonField } = props;
  const { rowsPerPage } = useAppSelector((store) => store.userPreferences);
  const dispatch = useAppDispatch();

  const handleChangeRowsPerPage = (e: number) => {
    dispatch(userPreferenceSliceActions.setRowsPerPage(e));
  };

  return (<>
    {error ? (
    <>Something Went Wrong</>
    ) : isLoading ? (
    <>Loading...</>
    ) : rowData ? (
    <>
      <div className={styles.uniformTable}>
      {buttonField ? (<>{buttonField}</>) : <></>}
        <DataGrid
          rows={rowData}
          columns={columnNames}
          pageSize={rowsPerPage}
          onPageSizeChange={(newPageSize) => (handleChangeRowsPerPage(newPageSize))}
          rowsPerPageOptions={[10, 25, 50]}
          checkboxSelection
          components={{ Toolbar: GridToolbar }}
          disableExtendRowFullWidth
        />
      </div>
    </>
    ) : null}
  </>);
};
export default Table;
