export type ErrorWithNestedErrorObject = {
  error: Record<string, unknown>;
};

export type ErrorWithDataString = {
  data: string;
  status: number;
};

export type ErrorWithDataObject = {
  data: { 
    message: string;
  };
  status: number;
};

type ErrorWithMessage = {
  message: string
};


function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string'
  );
}

function isErrorWithDataObject(error: unknown): error is ErrorWithDataObject {
  return (
    typeof error === 'object' &&
      error !== null &&
      'data' in error &&
      typeof (error as Record<string, unknown>).data === 'object'
  );
}

function isErrorWithDataString(error: unknown): error is ErrorWithDataString {
  return (
    typeof error === 'object' &&
      error !== null &&
      'data' in error &&
      typeof (error as Record<string, unknown>).data === 'string'
  );
}

  
function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isErrorWithMessage(maybeError)) return maybeError;
  if (isErrorWithDataObject(maybeError)) return maybeError.data;
  if (isErrorWithDataString(maybeError)) return { message: maybeError.data };

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
}

export function getErrorMessage(error: unknown): string {
  return toErrorWithMessage(error).message;
}
