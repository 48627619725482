import { LatLngTuple } from 'leaflet';
import { TileLayer, MapContainer } from 'react-leaflet';
import { Box } from '@mui/material';
import { useMapEvents } from 'react-leaflet';
import { dashboardMapSliceActions } from '../Redux/Slices/DashboardMapSlice';
import { useAppDispatch } from '../Redux/hooks';
import 'leaflet/dist/leaflet.css';

const mapToken = process.env.REACT_APP_MAPBOX_TOKEN;
const mapboxUrl = `
  https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${mapToken}`;
const mapboxAttribution = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';

interface MapProps {
  mapCenter: LatLngTuple,
  mapZoom: number,
  fullsize: boolean
  children: JSX.Element,
  persistMap?: boolean,
}

const MapEvents = ({ persistMap }: { persistMap?: boolean }): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const map = useMapEvents({
    moveend: () => {
      const mapCenter = map.getCenter();
      if (persistMap) dispatch(dashboardMapSliceActions.setDashboardMapCenter([mapCenter.lat, mapCenter.lng]));
    },
    zoomend: () => {
      if (persistMap) dispatch(dashboardMapSliceActions.setZoomLevel(map.getZoom()));
    },
  });
  return null;
};

const Map = (props: MapProps): JSX.Element => {
  const { mapCenter, mapZoom, fullsize, children, persistMap } = props;
  
  return (
    <Box sx={{ display: 'block' }}>
      <MapContainer
        zoom={mapZoom}
        center={mapCenter}
        scrollWheelZoom
        style={{ height: fullsize ? '60vh' : '65vh', minWidth: '460px' }}
        zoomControl
        attributionControl={false}
      >
        <TileLayer
          attribution={mapboxAttribution}
          url={mapboxUrl}
          no-wrap
          maxZoom={22}
          minZoom={1}
        />
        {children}
        <MapEvents persistMap={persistMap} />
      </MapContainer>
    </Box>
  );
};

export default Map;
